import { Card, Col, Image, Row, Skeleton, Tooltip, Typography } from "antd";

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ManageDatasetSDK from "sdk/ManageDatasetSDK";
import { Images } from "assets/images/Images";
import "./quicksight.less";
import Icon from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import Loader from "Common/Components/Loader/Loader";

const QSDashboard = () => {
  const history = useHistory();
  const { groups } = useSelector((state) => state.loginReducer);
  const accountID = groups.length > 0 ? groups[0] : null;

  const { Title, Text } = Typography;
  const [manageDatasetsData, setManageDatasetsData] = useState([]);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [acct, setacct] = useState({});
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "success",
  });

  const { QuicksightDashboardSvg } = Images?.QuicksightDashboard;

  const fetchData = async () => {
    setIsSkeletonLoading(true);
    const { dataSet, data } = await ManageDatasetSDK.getAllDataset(
      accountID
    ).catch((e) =>
      setApiResultModal(() => ({
        message: e?.message || "Something went wrong",
        isApiResultModelOpen: true,
        type: "failure",
      }))
    );

    const uniqueDataSets = [
      ...new Set(
        dataSet
          .filter(({ status }) => status === "CONNECTED")
          .map(({ data_set }) => data_set)
      ),
    ];

    setacct(data);
    setManageDatasetsData(uniqueDataSets);
    setIsSkeletonLoading(false);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  const Dashboard = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#7b40ba"
    >
      <path
        stroke="none"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 1.66667C12.6463 1.66667 12.3072 1.80715 12.0571 2.0572C11.8071 2.30725 11.6666 2.64638 11.6666 3V6.66667H7.99996C7.64634 6.66667 7.3072 6.80714 7.05715 7.05719C6.8071 7.30724 6.66663 7.64638 6.66663 8V12.5H2.99996C2.64634 12.5 2.3072 12.6405 2.05715 12.8905C1.8071 13.1406 1.66663 13.4797 1.66663 13.8333V17C1.66663 17.3536 1.8071 17.6928 2.05715 17.9428C2.3072 18.1929 2.64634 18.3333 2.99996 18.3333H17C17.3536 18.3333 17.6927 18.1929 17.9428 17.9428C18.1928 17.6928 18.3333 17.3536 18.3333 17V3C18.3333 2.64638 18.1928 2.30725 17.9428 2.0572C17.6927 1.80715 17.3536 1.66667 17 1.66667H13ZM8.33329 8.33334H11.6666V16.6667H8.33329V8.33334ZM16.6666 16.6667H13.3333V3.33334H16.6666V16.6667ZM6.66663 14.1667V16.6667H3.33329V14.1667H6.66663Z"
        fill="#7b40ba"
      />
    </svg>
  );

  const DashboardIcon = () => <Icon component={Dashboard} />;

  const handleQSDashboardUrl = (item) => {
    const url = `/qs-dashboard/${_.toLower(item)}`;
    history.push(url);
  };

  console.log("DATA :: ", acct);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Title level={3} className="data-provider-heading m-0">
            {acct?.company || "School"}'s Dashboard{" "}
            {isLoading || isSkeletonLoading ? <Loader /> : null}
          </Title>
        </Col>

        <Col span={24}>
          <Row
            align="middle"
            justify="space-between"
            className="px-4 py-3 quicksight-dashboard-container m-0"
          >
            <Col span={12}>
              <Row justify="start">
                <Title level={5} className="m-0">
                  Explore a world of insights -<br /> all your dashboards in one
                  place
                </Title>
              </Row>
            </Col>
            <Col span={8}>
              <Row justify="end">
                <Image preview={false} src={QuicksightDashboardSvg} />
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          {isSkeletonLoading ? (
            <Skeleton active={true} />
          ) : (
            <Row className="qs-dashboard-card-container" gutter={[24, 24]}>
              {manageDatasetsData?.map((item) => {
                return (
                  <Col span={12}>
                    {item?.toLowerCase()?.includes("admissions") ? (
                      <Card
                        className="qs-dashboard-card"
                        onClick={() => handleQSDashboardUrl(item)}
                      >
                        <Row className="gap-1">
                          <DashboardIcon />
                          <Text className="qs-text-color">
                            {_.startCase(_.toLower(item?.replace(/_/g, " ")))}
                          </Text>
                        </Row>
                        <Row>
                          <Text>
                            High level &nbsp;
                            {_.startCase(_.toLower(item)).replace(
                              "Management System",
                              "Dashboard"
                            )}
                            &nbsp; dashboard that was created for the board of
                            trustees
                          </Text>
                        </Row>
                      </Card>
                    ) : (
                      <Tooltip
                        title="Cooming Soon"
                        placement="topLeft"
                        color="#F5DB5D"
                      >
                        <Card className="disable-qs-dashboard-card">
                          <Row className="gap-1">
                            <DashboardIcon />
                            <Text className="qs-text-color">
                              {_.startCase(_.toLower(item?.replace(/_/g, " ")))}
                            </Text>
                          </Row>
                          <Row>
                            <Text>
                              High level&nbsp;
                              {_.startCase(_.toLower(item)).replace(
                                "Management System",
                                "Dashboard"
                              )}
                              &nbsp; dashboard that was created for the board of
                              trustees
                            </Text>
                          </Row>
                        </Card>
                      </Tooltip>
                    )}
                  </Col>
                );
              })}
            </Row>
          )}
        </Col>
      </Row>
      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
};

export default QSDashboard;
