//** React Imports */
import React, { useState, useEffect } from "react";
//** Ant Design Imports */
import { Col, Row, Form, Input, Button, Alert } from "antd";
//** Third Party Imports */
import { useTranslation } from "react-i18next";
//** Constant Imports */
import {
  buttonText,
  emailPattern,
  formError,
  formLabel,
  MiscellaneousText,
} from "utility/constants";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** Redux Imports */
import { connect, useDispatch } from "react-redux";
//** Redux Action Imports */
import { login } from "store/actions/loginAction";
//** Layout Imports */
import Footer from "Common/Layouts/Footer.js";
import Background from "Common/Layouts/AuthColOne.js";
import LoginModuleLogo from "Common/Components/LoginModuleLogo/LoginModuleLogo";

/**
 * @function base component
 * @param {*} props
 */
const Login = (props) => {
  /** Destructing Hooks */
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //** Destructing Constants */
  const { isEmailInvalid, isEmailEmpty, isPasswordEmpty } = formError;
  const { Email_Label, emailText, Password_Label } = formLabel;
  const { Forget_Password_Text, Login_Text, Sign_Up_Text } = buttonText;
  const { Do_not_Account_Text } = MiscellaneousText;

  //** Initial Form Data of Login Form */
  const initialValues = {
    userName: "",
    password: "",
    loading: false,
    feedback: props.history.location.state
      ? props.history.location.state.feedback
      : "",
    error: "",
  };

  const [state, setState] = useState(initialValues);

  const { email, loading, error, feedback } = state;

  /**
   * Handles sign in of user
   */
  const handleSubmit = async (e) => {
    setState((pS) => ({ ...pS, loading: true, error: "" }));

    await props
      .loginData(e.userName, e.password)
      .then((response) => {
        Auth.currentAuthenticatedUser().catch((err) => console.log(err));
        if (response?.challengeName === "NEW_PASSWORD_REQUIRED") {
          props.history.push({
            pathname: `/create-password`,
            state: {
              userAttributes: response?.challengeParam?.userAttributes,
              oldPassword: e.password,
            },
          });

          setState((pS) => ({ ...pS, loading: false }));

          // history.push("/");
        }
      })

      .catch((error) => {
        if (error?.message === "User is not confirmed.") {
          props?.history.push({
            pathname: `/confirm_email`,
            state: { feedback: "", userName: e.userName },
          });
        }
        console.log("error signing in:", error);
        setState((pS) => ({
          ...pS,
          error: error.message,
          loading: false,
          userName: "",
          password: "",
        }));
      });
  };

  useEffect(() => {
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount < 2) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
    } else {
      sessionStorage.removeItem("reloadCount");
    }
  }, []);

  return (
    <>
      <Row justify="center">
        <Background />

        <Col span={12} md={12} lg={12} xs={24}>
          <Row gutter={24} justify="center" className="login-module-col-2">
            <Col span={12} md={24} lg={18} xl={12} xs={24} sm={24} xxl={12}>
              <LoginModuleLogo />
              {feedback && (
                <Alert
                  className="mb-10"
                  type="success"
                  showIcon={false}
                  message={feedback}
                  banner
                />
              )}
              {error !== "" && (
                <Alert
                  className="mb-10"
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                />
              )}
              <Form
                layout="vertical"
                name="normal_login"
                className="login-form"
                requiredMark={false}
                onFinish={handleSubmit}
              >
                <Form.Item
                  label={t(Email_Label)}
                  initialValue={email}
                  name="userName"
                  className="font-bold"
                  rules={[
                    { required: true, message: t(isEmailEmpty) },
                    {
                      pattern: emailPattern,
                      message: t(isEmailInvalid),
                    },
                  ]}
                >
                  <Input
                    id="userName"
                    key="userName"
                    name="userName"
                    placeholder={emailText}
                    className="border-light-color login-module-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  className="font-bold"
                  rules={[
                    {
                      required: true,
                      message: t(isPasswordEmpty),
                    },
                  ]}
                >
                  <Input.Password
                    id="password"
                    key="password"
                    name="password"
                    placeholder={t(Password_Label)}
                    className="border-light-color login-module-input"
                  />
                </Form.Item>

                <Row align="end">
                  <Button
                    onClick={() => props.history.push("/forgotpassword")}
                    type="link"
                    className="fs-16 color-black cursor-pointer pb-30"
                  >
                    {t(Forget_Password_Text)}
                  </Button>
                </Row>

                <Form.Item>
                  <Button
                    size="large"
                    htmlType="submit"
                    block
                    type="primary w-100"
                    tabIndex={5}
                    loading={loading}
                  >
                    {t(Login_Text)}
                  </Button>
                  {/* Dev Comment <Divider style={{color: '#727272'}}>or</Divider> 
                  <Button
                      size="large"
                      htmlType="submit"
                      block
                      tabIndex={5}
                      style={{
                        background: "#FFFFFF",
                        color: "#303030",
                        borderRadius: "8px",
                        height: "44px",
                      }}
                    >
                      <img src={GoogleIcon} style={{ padding: 7 }} />
                      <span>Sign in with google</span>
                    </Button> */}
                </Form.Item>
                <span className="fs-16 d-flex justify-content-center cursor-pointer pb-30 ">
                  {t(Do_not_Account_Text)}
                  <span
                    className="font-bold primary-color ps-1 "
                    onClick={() => props.history.push("/signup")}
                  >
                    {t(Sign_Up_Text).toUpperCase()}
                  </span>
                </span>
              </Form>
            </Col>
            <Footer />
          </Row>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    prop: state.prop,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginData: (userName, password) => dispatch(login(userName, password)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
