//** React imports */
import React from "react";
//** Component imports */
import ComingSoon from "Common/ComingSoon";

const Research = () => {
  return (
    <div className="flex-center h-100 w-100">
      <ComingSoon />
    </div>
  );
};

export default Research;
