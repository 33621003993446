import {
  createUserNotificationPreference,
  updateUserNotificationPreference,
  updateUserNotification,
} from "graphql/mutations";
import {
  listNotificationTypes,
  listUserNotifications,
  NotificationTypeByUser,
} from "graphql/queries";
// import { updateNotificationTypes } from "graphql/mutations";
import { GraphqlOperationExecutor } from "utility/commonMethods";

const listNotificationsType = async () => {
  const queryData = {
    query: listNotificationTypes,
  };

  return await GraphqlOperationExecutor(queryData);
};

const createUserNotificationPreferenceForUser = async (input) => {
  const queryData = {
    query: createUserNotificationPreference,
    body: {
      input,
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const updateUserNotificationPreferenceForUser = async (input) => {
  const queryData = {
    query: updateUserNotificationPreference,
    body: {
      input,
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const getPreferedNotifications = async (input) => {
  const queryData = {
    query: NotificationTypeByUser,
    input,
  };

  return await GraphqlOperationExecutor(queryData);
};

const getAllUserNotifications = async (filter) => {
  const queryData = {
    query: listUserNotifications,
    body: {
      filter,
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const markNotificationAsRead = async (input) => {
  const queryData = {
    query: updateUserNotification,
    body: { input },
  };

  return await GraphqlOperationExecutor(queryData);
};

const NotificationSDK = {
  listNotificationsType,
  getPreferedNotifications,
  createUserNotificationPreferenceForUser,
  updateUserNotificationPreferenceForUser,
  getAllUserNotifications,
  markNotificationAsRead,
};

export default NotificationSDK;
