//** React import */
import React from "react";
//** Comming soon image import */
import ComingSoonImage from "assets/images/coming_soon.svg";

/**
 * ComingSoon component
 * @function ComingSoon
 * @return {<ComingSoon Image/>}
 */
const ComingSoon = () => (
  <img src={ComingSoonImage} className="img-fluid" alt="" />
);

export default ComingSoon;
