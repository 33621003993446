//** React Imports */
import React, { useState, useEffect } from "react";
//** Ant Imports */
import { Button, Card, Col, Typography, Row, Space, Alert } from "antd";
//** React Router Imports */
import { useHistory } from "react-router-dom";
//** Redux Imports */
import { logout } from "store/actions/loginAction";
import { useDispatch } from "react-redux";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** Images Import */
import { Images } from "assets/images/Images";
import { useTranslation } from "react-i18next";
import { buttonText, messageText, MiscellaneousText } from "utility/constants";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";

export default function RequestPending(props) {
  //** Destructing Hooks */
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //** Destructing Images */
  const { ReqPending } = Images?.AccountSetup;

  //** Destructing constants */
  const { Fail_to_logout_Message, Request_Submitted_Message } = messageText;
  const {
    Pending_Approval_Text,
    Request_Send_Text,
    Help_Connect_Text,
    Connect_Mail_Text,
  } = MiscellaneousText;
  const { Thank_You_Btn } = buttonText;

  //** States for handling data */
  const [loading, setLoading] = useState();
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  /**
   * @function logOut
   * Handles logout event
   */
  const logOut = async () => {
    setLoading(true);
    Auth.signOut({
      global: true,
    })
      .then((data) => {
        dispatch(logout());
        history.push("/signin");
      })
      .catch((err) => {
        console.log(err);
        // message.error(t(Fail_to_logout_Message));
        setApiResultModal(() => ({
          message: t(Fail_to_logout_Message),
          isApiResultModelOpen: true,
          type: "failure",
        }));
        setLoading(false);
      });
  };

  /**
   * @useEffect for handling data
   */
  useEffect(() => {
    if (props.accountid) {
      logOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Card className="center_box" bordered={false}>
        <Row justify="center">
          <Col span={24}>
            <Space
              direction="vertical"
              align="center"
              className="w-100 h-100"
              justify="middle"
              size={5}
            >
              <Alert
                showIcon
                type="success"
                message={t(Request_Submitted_Message)}
              />
              <Row justify="center">
                <Col xs={24}>
                  <div className="request-image-container">
                    <img
                      src={ReqPending}
                      alt="request access"
                      className="request-image"
                    />
                  </div>
                </Col>
              </Row>
              <Typography.Title level={5} className="pending-approval-text">
                {t(Pending_Approval_Text)}
              </Typography.Title>
              <Typography.Paragraph className="request-send-text">
                {t(Request_Send_Text)}
                <br />
                <strong className="connect-mail-text">
                  {t(Help_Connect_Text)}{" "}
                  <a
                    href="mailto:connect@schoolbi.com"
                    className="primary-color"
                  >
                    {t(Connect_Mail_Text)}{" "}
                  </a>
                </strong>
              </Typography.Paragraph>
            </Space>
          </Col>
          <Button
            loading={loading}
            type="primary"
            size="large"
            onClick={logOut}
          >
            {t(Thank_You_Btn)}
          </Button>
        </Row>
      </Card>

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
}
