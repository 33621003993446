import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Skeleton, message, Row, Col } from "antd";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import _ from "lodash";

function QuicksightDashboard() {
  const { dashboardName } = useParams();
  const { groups } = useSelector((state) => state.loginReducer);
  const accountID = groups.length > 0 ? groups[0] : null;

  const [quicksightLoader, setquicksightLoader] = useState(false);

  const getURL = async () => {
    setquicksightLoader(true);

    const data = await API.post("schoolBIREST", "/getEmbedUrl", {
      body: {
        school_id: accountID,
        dashboard_name: dashboardName,
      },
    });

    embed(data?.url);
  };

  const embed = async (url) => {
    document.getElementById("quick-sight").innerHTML = "";
    const embeddingContext = await QuickSightEmbedding.createEmbeddingContext({
      onChange: (changeEvent, metadata) => {},
    });

    const options = {
      url: url,
      container: document.getElementById("quick-sight"),
      scrolling: "yes",
      height: "100%",
      width: "100%",
      locale: "en-US",
      footerPaddingEnabled: true,
      loadCallback: dashboardloadCallback,
      errorCallback: dashboardErrorCallback,
    };

    await embeddingContext.embedDashboard(options);
    setquicksightLoader(false);
  };

  function dashboardloadCallback(e) {
    setquicksightLoader(false);
  }

  function dashboardErrorCallback(e) {
    setquicksightLoader(false);
  }

  let executiveSkeleton = useMemo(
    () => (
      <>
        <Skeleton active={true} />
      </>
    ),
    []
  );

  let skeletons = {
    executiveSkeleton,
  };

  useEffect(() => {
    getURL();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: 30,
          fontSize: 24,
          fontWeight: "bold",
        }}
      >
        <div className="coaching-sub-header">
          {_.startCase(_.toLower(dashboardName)).replace(
            "Management System",
            "Dashboard"
          )}
        </div>
      </div>
      <div
        style={{
          background: "#fff",
          borderRadius: 7,
          height: "100vh",
          minHeight: "100vh",
          marginTop: 18,
          position: "relative",
        }}
      >
        {quicksightLoader && (
          <div
            style={{ padding: 20, position: "absolute", top: 0, width: "100%" }}
          >
            <div className="dashboard-loader">
              {skeletons["executiveSkeleton"]}
            </div>
          </div>
        )}
        <div className="wrapperBox">
          <div className="iframeBox" id="quick-sight" />
        </div>
      </div>
    </div>
  );
}

export default QuicksightDashboard;
