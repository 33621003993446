// Description: Billing SDK for all billing related API calls
//** Aws Amplify Imports */
import { API } from "aws-amplify";
//** Utility Imports */
import { apiExecuter } from "utility/commonMethods";

/**
 * Get a link token for Plaid
 * @function getPlaidLinkToken
 * @param {UUID} AccountId of the user
 * @return {LinkTokenfromPlaid}
 */
const getPlaidLinkToken = async (id) =>
  apiExecuter(async () => {
    const url = `/billing/plaid/link?accountId=${id}`;
    return await API.get("schoolBIREST", url);
  });

/**
 * Get Stripe checkout session for an organization account
 * @function getCheckoutSession
 * @param {*} accountId
 * @returns
 */
const getPortalLink = async (accountId) => {
  return apiExecuter(async () => {
    const url = `/billing/portal/org?accountId=${accountId}`;
    return await API.get("schoolBIREST", url);
  });
};

/**
 * Get Stripe checkout session for an organization account
 * @param {String} planName
 * @param {UUID} planStripeId
 * @param {UUID} topUpStripeId
 * @param {UUID} planId
 * @param {UUID} accountId
 * @param {String} paymentType // card or ach
 * @param {JSON} paymentData // Account details for ACH { plaidToken, plaidAccountId}
 * @param {Number} topQuantity // Number of top ups connector
 * @return {CheckoutSession}
 */
const getOrgCheckoutSession = async ({
  planStripeId,
  topUpStripeId,
  planId,
  accountId,
  paymentType,
  paymentData,
  topQuantity,
}) => {
  const url = "/billing/checkout/org";

  const requestBody = {
    planPriceId: planStripeId,
    planId: planId,
    accountId: accountId,
    payment_type: paymentType,
    topQuantity: topQuantity,
  };

  if (topQuantity > 0) requestBody["topUpPriceId"] = topUpStripeId;

  if (paymentType === "ach") {
    requestBody["plaid_public_token"] = paymentData["plaidToken"];
    requestBody["plaid_account_id"] = paymentData["plaidAccountId"];
  }

  return apiExecuter(
    async () => await API.post("schoolBIREST", url, { body: requestBody })
  );
};

/**
 * @param {UUID} accountId
 * @param {UUID} planId
 * @returns
 */
const assignPlan = async (accountId, planId, dataSources) =>
  apiExecuter(async () => {
    const url = "/admin/assignplan";
    const body = {
      planId: planId,
      id: accountId,
      dataSources,
    };
    return await API.post("schoolBIREST", url, { body: body });
  });
/**
 * @param {UUID} accountId
 * @param {UUID} planId
 * @returns
 */
const cancelPlan = async (accountId, planId) =>
  apiExecuter(async () => {
    const url = "/admin/cancelplan";
    const body = {
      planId: planId,
      id: accountId,
    };
    return await API.post("schoolBIREST", url, { body: body });
  });

const BillingSDK = {
  getPlaidLinkToken,
  getPortalLink,
  getOrgCheckoutSession,
  assignPlan,
  cancelPlan,
};

export default BillingSDK;
