//** React imports */
import React from "react";
//** Constant imports */
import { LayoutText } from "utility/constants";
//** Third Party imports */
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

/**
 * Footer component
 * @function Footer
 * @return {<Footer Content/>}
 */
const Footer = () => {
  //** Destructing Footer Text  */
  const { FooterText } = LayoutText;
  //** Destructing Translator Hook */
  const { t } = useTranslation();
  return (
    <Typography.Text className="footer footer-container">
      {t(FooterText)}
    </Typography.Text>
  );
};
export default Footer;
