//LoginAction
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const SET_PERMISSION = "SET_PERMISSION";
export const SET_ACCOUNT_REQUESTS = "SET_ACCOUNT_REQUESTS";
export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS";
export const LOGOUT = "LOGOUT";
export const UPDATE_COMPANY_ACCOUNT = "UPDATE_COMPANY_ACCOUNT";
export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
export const PROFILE_DATA = "PROFILE_DATA";
export const SET_THEME = "SET_THEME";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_GIVENNAME = "SET_GIVENNAME";
export const SET_FAMILYNAME = "SET_FAMILYNAME";
export const SET_USERDATA = "SET_USERDATA";

// SETTING ACTIONS

export const SET_INTEGRATIONS = "SET_INTEGRATIONS";

export const CONNECTOR_COUNTER = "CONNECTOR_COUNTER";
export const SET_CURRENT_RENDER = "SET_CURRENT_RENDER";
export const SELECTED_CONNECTOR = "SELECTED_CONNECTOR";
export const SET_ALL_SUBSOURCES = "SET_ALL_SUBSOURCES";
export const SET_ISFROMSTRIPE = "SET_ISFROMSTRIPE";
export const SET_CURRENT_RENDER_KEY = "SET_CURRENT_RENDER_KEY";
export const SET_CURRENT_CONFIGURE_RENDER_KEY =
  "SET_CURRENT_CONFIGURE_RENDER_KEY";

// Manage files actions
export const SET_MANAGE_FILES_VISIBILITY = "SET_MANAGE_FILES_VISIBILITY";
export const SET_ADVANCED_LIST_VISIBILITY = "SET_ADVANCED_LIST_VISIBILITY";
export const SET_UNAUTHORIZED_VISIBILITY = "SET_UNAUTHORIZED_VISIBILITY";

export const SET_IS_SCOPE_NORMALUSER = "SET_IS_SCOPE_NORMALUSER";
