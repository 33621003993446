import { createRules, updateRules } from "graphql/mutations";
import { listDaslValuess, listRuless } from "graphql/queries";
import { getEnv, GraphqlOperationExecutor } from "utility/commonMethods";
import axios from "axios";

const createRuleEngine = (input) => {
  const queryData = {
    query: createRules,
    body: {
      input: input,
    },
  };
  return GraphqlOperationExecutor(queryData);
};

const updateRuleEngine = (input) => {
  const queryData = {
    query: updateRules,
    body: {
      input: input,
    },
  };
  return GraphqlOperationExecutor(queryData);
};

const getRuleEngine = (input) => {
  const queryData = {
    query: listRuless,
    body: {
      filter: input,
    },
  };
  return GraphqlOperationExecutor(queryData);
};

const getPreconfigvalues = async ({
  school_id,
  column_name,
  dataset,
  datasource,
}) => {
  const env = getEnv();

  const apiKey = {
    stagging:
      "nrKjkIS7FYO4rFOSOxERHeB8anj4UCaVaQMP1lzy0wrNE-wsOzPT3I7OYALgIisP",
    stage: "nrKjkIS7FYO4rFOSOxERHeB8anj4UCaVaQMP1lzy0wrNE-wsOzPT3I7OYALgIisP",
    prod: "UHk3seFKaCwGXFBpx4bvLaLAlhBKmlnISjtwFqfMf3M-vq2yXl3Abhg3DbXZwyXW",
    production:
      "UHk3seFKaCwGXFBpx4bvLaLAlhBKmlnISjtwFqfMf3M-vq2yXl3Abhg3DbXZwyXW",
  };

  const baseUrl = `https://api-${env}.schoolbi.com/v3/data/data/getuniquevalues?school_id=${school_id}&column_name=${column_name}&datasource=${datasource}&dataset=${dataset}&apiKey=${apiKey?.[env]}`;

  const { data } = await axios.get(baseUrl);

  return data;
};

const getDaslValues = async (input) => {
  const queryData = {
    query: listDaslValuess,
    body: {
      filter: input,
    },
  };
  return GraphqlOperationExecutor(queryData);
};

const RulesEngineSDK = {
  createRuleEngine,
  getRuleEngine,
  getPreconfigvalues,
  getDaslValues,
  updateRuleEngine,
};

export default RulesEngineSDK;
