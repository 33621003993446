//** React Imports */
import React from "react";
//** Black Logo Imports */
import logo from "assets/images/SchoolBI_Black_logo.svg";
//** Ant Imports */
import { Image } from "antd";
import { useHistory } from "react-router-dom";
/**
 * @function component for Login Module Logo
 * @return {BlackLogo}
 */
const LoginModuleLogo = () => {
  const history = useHistory();
  return (
    <div
      className="login-module-logo cursor-pointer"
      onClick={() => {
        history.push("/signin");
      }}
    >
      <Image preview={false} src={logo} alt="SchoolBI Logo" />
    </div>
  );
};

export default LoginModuleLogo;
