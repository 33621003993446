import { UpCircleOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  Menu,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { Images } from "assets/images/Images";
import classNames from "classnames";
import SkeletonTable from "Common/Skeleton/SkeletonTable";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import AdvacnedListSDK from "sdk/AdvancedListSDK";
import ManageDatapackageSDK from "sdk/ManageDatapackageSDK";
import { buttonText, advancedListText } from "utility/constants";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

const DataPackages = () => {
  const { t } = useTranslation();

  const { Title, Text } = Typography;
  const { Icons, AdvancedList } = Images;
  const { FileIcon } = Icons;
  const { NoAdvancedListSelectSvg } = AdvancedList;

  const { groups } = useSelector((state) => state.loginReducer);
  const accountID = groups.length > 0 ? groups[0] : null;

  const [isLoading, setIsLoading] = useState(false);
  const [dataPackageDataSource, setDataPackageDataSource] = useState([]);

  const {
    Save,
    Cancel,
    Connect_Btn,
    Starting_Btn,
    Add_New,
    Done_Btn,
    Deleting_Text,
  } = buttonText;

  const {
    Manage_Advanced_List,
    View_Data_List,
    Advanced_List_Model_List,
    File_Id_List,
    Enter_Here_List,
    In_Progress_Text,
    Failed_Text,
  } = advancedListText;

  const dataPackageColumn = useMemo(
    () => [
      // {
      //   title: "File Name/Id. Col",
      //   dataIndex: "list_name",
      //   key: "list_name",
      //   width: "25%",
      //   // render: (_, { list_name, list_id, is_initial_sync, name }, index) => {
      //   //   return index === preConfigureIndex ? (
      //   //     <div className="preconfigure-title">{list_name}</div>
      //   //   ) : (
      //   //     <>
      //   //       <div
      //   //         className={classNames("dataset-name-container", {
      //   //           "dataset-name-container-connected":
      //   //             is_initial_sync === "CONNECTED",
      //   //           "pe-none":
      //   //             is_initial_sync !== "CONNECTED" ||
      //   //             index >= preConfigureIndex,
      //   //           "grey-color": index > preConfigureIndex,
      //   //         })}
      //   //         onClick={() => handlePreview({ list_name, list_id })}
      //   //       >
      //   //         {!list_name ? name : `${list_name} - ${list_id}`}
      //   //       </div>
      //   //     </>
      //   //   );
      //   // },
      //   // onCell: (_, index) => onCellHelper(index, "list_name"),
      // },
      {
        title: "Data Package Name",
        dataIndex: "package_name",
        key: "dataset",
        width: "15%",
        // onCell: (_, index) => onCellHelper(index),
      },
      // {
      //   title: "Data Source",
      //   dataIndex: "datasource",
      //   key: "datasource",
      //   width: "25%",
      //   // render: (item) => _.startCase(_.toLower(item)),
      //   // onCell: (_, index) => onCellHelper(index),
      // },
      // {
      //   title: "Recurring Status",
      //   dataIndex: "recurring_sync_status",
      //   key: "recurring_sync_status",
      //   width: "15%",
      //   // render: (item, { last_sync_date }) => {
      //   //   return item ? (
      //   //     <>
      //   //       <div className="d-flex align-center">
      //   //         <UpCircleOutlined className="circle-icon green-circle" />
      //   //         <Text>{"Success"}</Text>
      //   //       </div>
      //   //     </>
      //   //   ) : last_sync_date ? (
      //   //     <>
      //   //       <div className="d-flex align-center">
      //   //         <UpCircleOutlined className="circle-icon red-circle" />
      //   //         <Text>{"Failure"}</Text>
      //   //       </div>
      //   //     </>
      //   //   ) : null;
      //   // },
      //   // onCell: (_, index) => onCellHelper(index),
      // },
      {
        title: "Last Sync Date",
        dataIndex: "last_sync_date",
        key: "lastRanDate",
        width: "23%",
        render: (item) => (item ? <Moment format="lll" date={item} /> : null),
        // onCell: (_, index) => onCellHelper(index),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "20%",
        render: (_, item, index) => {
          return _ === "CONNECTED" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon green-circle" />
              <Text
              // className={classNames({
              //   "grey-color": index > preConfigureIndex,
              // })}
              >
                {t(Connect_Btn)}
              </Text>
            </div>
          ) : _ === "DISCONNECTED" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon grey-circle" />
              <Text>{t(Starting_Btn)}</Text>
            </div>
          ) : _ === "INPROGRESS" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon grey-circle" />
              <Text>{t(In_Progress_Text)}</Text>
            </div>
          ) : _ === "DELETING" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon red-circle" />
              <Text>{t(Deleting_Text)}</Text>
            </div>
          ) : (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon red-circle" />
              <Text>{t(Failed_Text)}</Text>
            </div>
          );
        },
        // onCell: (_, index) => onCellHelper(index, "status"),
      },
      // {
      //   title: "Action",
      //   dataIndex: "action",
      //   key: "action",
      //   width: "5%",
      //   render: (_, item, index) => (
      //     <>
      //       {
      //         <div className="d-flex justify-content-center cursor-pointer">
      //           <Dropdown
      //             overlay={
      //               <Menu>
      //                 {/* <Menu.Item
      //                   key="invite"
      //                   onClick={() => setRefreshIntervalModal(true)}
      //                   // onClick={() =>
      //                   //   handleDisconnectDatasource(
      //                   //     data_source,
      //                   //     status === "CONNECTED" ? "DISCONNECTED" : "CONNECTED"
      //                   //   )
      //                   // }
      //                 >
      //                   Set Recurring Sync
      //                 </Menu.Item> */}
      //                 <Menu.Item
      //                   key="delete"
      //                   disabled={
      //                     item?.is_initial_sync === "DELETING" ||
      //                     item?.is_initial_sync === "DISCONNECTED" ||
      //                     item?.is_initial_sync === "INPROGRESS"
      //                   }
      //                 >
      //                   <Popconfirm
      //                     className="switchDialog"
      //                     style={{
      //                       zIndex: 9999,
      //                     }}
      //                     placement="bottomRight"
      //                     title={
      //                       <Typography.Title level={5} className="m-0">
      //                         Are you sure you want to delete this advanced
      //                         list?
      //                       </Typography.Title>
      //                     }
      //                     onConfirm={() => handleDeleteAdvancedList(item)}
      //                     okText={
      //                       <span className="admin-btn">{t(yesText)}</span>
      //                     }
      //                     cancelText="No"
      //                     icon={""}
      //                     okType="primary"
      //                     okButtonProps={{
      //                       size: "large",
      //                     }}
      //                     cancelButtonProps={{
      //                       size: "large",
      //                       className: "home-no-btn",
      //                       loading: isLoading,
      //                     }}
      //                   >
      //                     Delete
      //                   </Popconfirm>
      //                 </Menu.Item>
      //               </Menu>
      //             }
      //           >
      //             <MoreOutlined className="more-outline-style primary-color" />
      //           </Dropdown>
      //         </div>
      //       }
      //     </>
      //   ),
      //   // onCell: (_, index) => onCellHelper(index, "action"),
      // },
    ],
    //eslint-disable-next-line
    []
  );

  const fetchdata = async () => {
    const body = {
      accountId: accountID,
      package_name: "VERACROSS_DATA_PACKAGES",
    };
    setIsLoading(true);
    const data = await ManageDatapackageSDK.getDatapackage(body).finally(() =>
      setIsLoading(false)
    );

    setDataPackageDataSource(data);
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Title level={3} className="data-provider-heading">
            {/* {t(Manage_Advanced_List)}  */}
            Manage Data Package
          </Title>
          <Row className="d-flex gap-3" align="middle" justify="space-between">
            <div className="d-flex align-items-center gap-3">
              <Image preview={false} src={FileIcon} alt="..." />
              <Title level={4} className="data-source-title m-0">
                {/* {t(View_Data_List)} */}
                View the data of the data packages as below
              </Title>
              {/* {isLoading ? <Loader /> : null} */}
            </div>

            {/* <Button
              type="primary"
              // onClick={() => setAddNewModal(true)}
              // disabled={isDisableAdvancedList}
            >
              {/* {t(Add_New)}
              Add New
            </Button> */}
          </Row>
        </Col>

        <Col span={24}>
          <Table
            columns={dataPackageColumn}
            dataSource={dataPackageDataSource}
            className="managedatasets-table-container advanced-table-container"
            pagination={false}
            locale={{
              emptyText: isLoading ? (
                <SkeletonTable />
              ) : (
                <Row
                  justify="center"
                  align="middle"
                  className="flex-col"
                  gutter={[12, 12]}
                >
                  {/* <Col span={24}> */}
                  <Image
                    preview={false}
                    src={NoAdvancedListSelectSvg}
                    alt="..."
                  />
                  <Title level={3}>No Files</Title>
                  <Text>
                    You will be able to see data packages after configuration of
                    data package from Veracross portal.
                  </Text>
                  {/* <Button type="primary"> Add New </Button> */}
                  {/* </Col> */}
                </Row>
              ),
            }}
            // rowClassName={(record) => dynamicRowClassName(record)}
            scroll={{
              x: 1000,
            }}
            showHeader={dataPackageDataSource?.length > 0 ? true : false}
          />
        </Col>
      </Row>
    </>
  );
};

export default DataPackages;
