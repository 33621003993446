//** React Imports */
import React from "react";
//** Logo Imports */
import logo from "assets/images/SchoolBI_Black_logo.svg";
//** React Router Imports */
import { useHistory } from "react-router-dom";

/**
 * @functional based component for logo
 * @param {*} props
 * @return {Logo}
 */
const Logo = (props) => {
  //** Destructing Hooks */
  const history = useHistory();
  return (
    <div className="company-logo w-100 text-center">
      <img
        src={logo}
        height="100px"
        alt="SchoolBI Pricing Logo"
        className="cursor-pointer"
        onClick={() => {
          if (Object.keys(props).length > 0) {
            props.handleChangKey("home");
          }
          if (history.location.pathname !== "/setup") {
            history.push("/");
          }
        }}
      />
    </div>
  );
};

export default Logo;
