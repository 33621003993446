import { listDataPackageDetails } from "graphql/queries";
import { GraphqlOperationExecutor } from "utility/commonMethods";

const getDatapackage = async ({ accountId, package_name }) => {
  const queryData = {
    query: listDataPackageDetails,
    body: {
      limit: 999,
      filter: {
        school_id: { eq: accountId },
        // package_name: {
        //   eq: package_name,
        // },
      },
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const ManageDatapackageSDK = {
  getDatapackage,
};

export default ManageDatapackageSDK;
