//** React Imports */
import React from "react";
//** Ant Imports */
import { Row, Col, Typography } from "antd";
//** Constants Imports */
import { billingPageText } from "utility/constants";
//** Third Party Imports */
import { useTranslation } from "react-i18next";

/**
 * Heading and Subheading of the billing page
 */
const SchoolHeading = () => {
  //** Destructing Hooks */
  const { t } = useTranslation();
  //** Destructing Constants */
  const { Billing_Heading_Text, Billing_Subheading_Text } = billingPageText;
  return (
    <Row justify="center">
      <Col span={24} className="text-center">
        <Typography.Title className="schoolbi-plan-heading">
          {t(Billing_Heading_Text)}
        </Typography.Title>
        <Typography.Text className="schoolbi-plan-subheading">
          {t(Billing_Subheading_Text)}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default SchoolHeading;
