//** React Imports */
import React, { useEffect } from "react";
//** Redux Imports */
import { connect } from "react-redux";
//** React Router Imports */
import { Redirect, Route } from "react-router-dom";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** Layout Imports */
import MainLayout from "containers/MainLayout";
//** Redux Actions Imports */
import { logout } from "store/actions/loginAction";
//** AWS Configuration Imports */
import config from "aws-exports";

/**
 * All Public Routes Higher Order Components
 * @function MainLayoutRoute
 * @param {Object} props
 */
const MainLayoutRoute = (props) => {
  const { token, groups, render, logOut, accountDetails, ...rest } = props;

  const handleSignOut = async () => {
    Auth.signOut({
      global: true,
    })
      .then(() => {
        props.logOut();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const userClientId = config.aws_user_pools_web_client_id;
    const lastAuthUserKey = `CognitoIdentityServiceProvider.${userClientId}.LastAuthUser`;
    const lastAuthUser = localStorage.getItem(lastAuthUserKey);
    if (!lastAuthUser) {
      handleSignOut();
    }
    //eslint-disable-next-line
  }, []);

  const getCondition = () => {
    if (window.location.host.includes("stag")) {
      return groups?.length === 1 && groups?.includes("stage_users");
    } else {
      return groups?.length === 0;
    }
  };

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          {!token && <Redirect from={matchProps.path} to={"/signin"} />}
          {token && getCondition() && (
            <Redirect from={matchProps.path} to="/setup" />
          )}
          {token && <MainLayout>{render(matchProps)}</MainLayout>}
        </>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logOut: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayoutRoute);
