import { Image, Row, Typography } from "antd";
import { Images } from "assets/images/Images";
import React from "react";
import { useTranslation } from "react-i18next";
import { ManageFiles_Constants } from "utility/constants";
import RefreshIntervalSupport from "./RefreshIntervalSupport";

const UploadedRefeshInterval = ({
  repeatIntervalForm,
  setRepeatIntervalForm,
  handleTableConfiguration,
}) => {
  const { UploadedRefreshIntervalSvg } = Images?.ManageFiles;
  const { Title, Text } = Typography;
  const { t } = useTranslation();

  const {
    UploadedRefreshIntervalText,
    Skip_For_Now_Text_Part1,
    Skip_For_Now_Text_Part2,
  } = ManageFiles_Constants;

  return (
    <Row align="middle" className="flex-col position-relative gap-3">
      <Text
        className="skip-for-now-text"
        onClick={() => handleTableConfiguration("skip")}
      >
        <div className="primary-color">{t(Skip_For_Now_Text_Part1)}</div>{" "}
        {t(Skip_For_Now_Text_Part2)}
      </Text>
      <Image
        src={UploadedRefreshIntervalSvg}
        preview={false}
        className="refresh-interval-image"
        height={150}
      />
      <div>
        <Title level={5} className="m-0 text-center">
          {t(UploadedRefreshIntervalText)}
        </Title>
      </div>
      <RefreshIntervalSupport
        repeatIntervalForm={repeatIntervalForm}
        setRepeatIntervalForm={setRepeatIntervalForm}
        type="add"
      />
    </Row>
  );
};

export default UploadedRefeshInterval;
