import Icon from "@ant-design/icons";
const CaretUpFunc = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="28"
      height="28"
      rx="14"
      transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
      fill="#FFC90A"
    />
    <path
      d="M8.23485 16.6332C8.1604 16.5589 8.10134 16.4707 8.06104 16.3736C8.02074 16.2765 8 16.1723 8 16.0672C8 15.9621 8.02074 15.8579 8.06104 15.7608C8.10134 15.6637 8.1604 15.5755 8.23485 15.5012L13.5491 10.187C13.6082 10.1277 13.6785 10.0807 13.7559 10.0486C13.8332 10.0165 13.9162 10 13.9999 10C14.0837 10 14.1666 10.0165 14.244 10.0486C14.3213 10.0807 14.3916 10.1277 14.4508 10.187L19.765 15.5012C20.0783 15.8146 20.0783 16.3198 19.765 16.6332C19.4516 16.9465 18.9464 16.9465 18.6331 16.6332L13.9967 12.0032L9.36036 16.6395C9.0534 16.9465 8.5418 16.9465 8.23485 16.6332Z"
      fill="white"
    />
  </svg>
);

const CaretUp = (props) => (
  <Icon component={CaretUpFunc} {...props} rotate={90} />
);

export default CaretUp;
