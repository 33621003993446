import React, { useState, useEffect } from "react";
import { Column, G2 } from "@ant-design/plots";

const BlackbaudRecurringChart = ({ data }) => {
  G2.registerInteraction("element-link", {
    start: [
      {
        trigger: "interval:mouseenter",
        action: "element-link-by-color:link",
      },
    ],
    end: [
      {
        trigger: "interval:mouseleave",
        action: "element-link-by-color:unlink",
      },
    ],
  });
  const initialConfig = {
    data: [],
    xField: "type",
    yField: "value",
    height: 250,
    isGroup: true,
    isStack: true,
    seriesField: "legend",
    groupField: "type",
    minColumnWidth: 20,
    color: ({ legend }) => {
      if (legend === "Failure") {
        return "#FFF3CA";
      }

      return "#7EC397";
    },
    legend: true,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      tickCount: 2, // Display only 0 and 1 on the y-axis
    },
    interactions: [
      {
        type: "element-highlight-by-color",
      },
      {
        type: "element-link",
      },
    ],
  };

  const [config, setConfig] = useState(initialConfig);

  useEffect(() => {
    setConfig((pS) => {
      return { ...pS, data: data };
    });
  }, [data]);

  return <Column {...config} />;
};

export default BlackbaudRecurringChart;
