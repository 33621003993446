//** React Imports */
import React, { useState } from "react";
//** Ant Component Imports */
import { Tabs, Typography, Grid, Divider } from "antd";
//** React Router Imports */
import { useHistory } from "react-router-dom";
//** Constant Imports */
import { MiscellaneousText, profileText } from "utility/constants";
//** Third Party Imports */
import { useTranslation } from "react-i18next";
//** Custom Component Basic Details Imports */
import BasicDetails from "./BasicDetails";
import LogoutModal from "Common/Components/LogoutModal/LogoutModal";

export default function ProfileSettings(props) {
  /**
   * @useState
   * State for handling modal and active key
   */
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [activeKey, setActiveKey] = useState(true);

  //** Ant Components Destructing  */
  const { TabPane } = Tabs;
  const { useBreakpoint } = Grid;

  //** Destructing Hooks */
  const screens = useBreakpoint();
  const history = useHistory();
  const { t } = useTranslation();

  //** Destructing Constants */
  const { User_Profile_Text, Basic_Details_Text } = profileText;
  const { Logout_Text } = MiscellaneousText;

  /**
   * @function handleChange
   * @param {activeKey} currentActiveKey
   */
  const handleChange = (currentActiveKey) => {
    if (currentActiveKey === "2") {
      setLogoutModalOpen(true);
      setActiveKey(false);
    } else {
      setLogoutModalOpen(false);
      setActiveKey(true);
    }
  };

  /**
   * @function onTabClick
   * Handles tab click function
   */
  const onTabClick = (key) => {
    handleChange(key);
  };

  return (
    <>
      <div className="main-bg profile-bg">
        <Typography.Title level={4} className="header-font">
          {t(User_Profile_Text)}
        </Typography.Title>

        <Divider className="my-2 divider" />

        <Tabs
          tabPosition={screens.xs === true ? "top" : "left"}
          className="companySettingTab "
          onChange={handleChange}
          onTabClick={onTabClick}
          activeKey={activeKey ? "1" : "2"}
          defaultActiveKey="1"
        >
          <TabPane tab={t(Basic_Details_Text)} key="1">
            <BasicDetails />
          </TabPane>
          <TabPane
            tab={t(Logout_Text)}
            key="2"
            onClick={() => setLogoutModalOpen(true)}
          >
            <LogoutModal
              logoutModalOpen={logoutModalOpen}
              setLogoutModalOpen={setLogoutModalOpen}
              setActiveKey={setActiveKey}
              {...props}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
