//** React Imports  */
import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Row, Col, Typography, Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { buttonText } from "utility/constants";
import CustomModal from "../CustomAntD/Modal";

const SuccessfulModal = ({
  isShowSuccessfulModal,
  setIsShowSuccessfulModal,
  modalTitle,
  modalText,
  successImage,
  width,
  okBtnClassNames,
  okBtnClickHandler,
  okBtnText,
  closable,
  imageWidth,
  cancelBtnText,
}) => {
  const { t } = useTranslation();
  const { OK_Btn } = buttonText;
  const handleCloseModal = () => {
    setIsShowSuccessfulModal(false);
  };

  return (
    <CustomModal
      visible={isShowSuccessfulModal}
      closable={closable !== undefined ? closable : true}
      centered
      onCancel={handleCloseModal}
      footer={null}
      width={width || 450}
    >
      <Row justify="center" className="w-100">
        <Col className="flex-col align-center gap-3">
          {successImage ? (
            <img
              src={successImage}
              alt="..."
              style={imageWidth ? { width: imageWidth } : null}
            />
          ) : (
            <CheckCircleOutlined className="modal-check-mark-icon" />
          )}

          <div className="text-center flex-col gap-2">
            {modalTitle && (
              <Typography.Title level={2} className="mb-0">
                {modalTitle}
              </Typography.Title>
            )}
            <Typography.Text className="fs-18 font-bold">
              {modalText}
            </Typography.Text>
          </div>


          <div className="flex-row gap-3">
            {cancelBtnText ? (
              <Button onClick={() => handleCloseModal()} className={"px-3 "}>
                {cancelBtnText}
              </Button>
            ) : null}

            <Button
              type="primary"
              onClick={() => {
                if (okBtnClickHandler) {
                  okBtnClickHandler();
                }
                handleCloseModal();
              }}
              className={"px-3 " + okBtnClassNames}
            >
              {okBtnText || t(OK_Btn)}
            </Button>
          </div>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default SuccessfulModal;
