//** React Imports */
import React from "react";
//** Redux Imports */
import { connect } from "react-redux";
//** Router Imports */
import { Redirect, Route } from "react-router-dom";
//** Layout Imports */
import FullPageLayout from "containers/FullPageLayout";

/**
 * All Public Routes Higher Order Components
 * @function PublicRoutes
 * @param {Object} props
 */
const PublicRoutes = (props) => {
  //** Destructing Props */
  const { token, groups, render, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          {token && groups.length > 0 && (
            <Redirect from={matchProps.path} to="/" />
          )}
          {token && groups.length === 0 && (
            <Redirect from={matchProps.path} to="/setup" />
          )}
          {!token && <FullPageLayout>{render(matchProps)}</FullPageLayout>}
        </>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
    errors: state.errors,
  };
};
export default connect(mapStateToProps)(PublicRoutes);
