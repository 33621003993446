import { Button, Col, Row, Table } from "antd";
import React from "react";

const AdvancesListTable = () => {
  return (
    <Row gutter={[12, 12]} className="advancedlist-container">
      <Col span={24} className="p-0">
        <Row justify="end">
          <Button type="primary">Add New</Button>
        </Row>
      </Col>
      <Col span={24} className="p-0">
        <Table
          columns={[
            {
              title: "Data Set",
              dataIndex: "dataset",
              key: "dataset",
              width: "15%",
            },
          ]}
          dataSource={[]}
          //   className="managedatasets-table-container advanced-table-container"
          pagination={false}
          //locale={{
          // emptyText: isLoading ? <SkeletonTable /> : <Empty />,
          //}}
          // rowClassName={(record) => dynamicRowClassName(record)}
          scroll={{
            x: 1000,
          }}
        />
      </Col>
    </Row>
  );
};

export default AdvancesListTable;
