import { Typography } from "antd";
import React from "react";
import classNames from "classnames";

const Title = (props) => {
  return (
    <Typography.Title
      {...props}
      className={classNames("m-0", props?.className)}
    />
  );
};

export default Title;
