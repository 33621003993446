import React from "react";
import Icon from "@ant-design/icons";

const ArrowRight = (props) => {
  const ArrowRightComponent = () => (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="14"
        y1="6"
        x2="6"
        y2="6"
        stroke="#F7B927"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <line
        x1="0.999999"
        y1="6"
        x2="2"
        y2="6"
        stroke="#F7B927"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 10.5L16 6L12 1.5"
        stroke="#F7B927"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  return <Icon component={ArrowRightComponent} {...props} rotate={90} />;
};

export default ArrowRight;
