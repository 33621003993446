import React from "react";
import { Card, Space, Skeleton } from "antd";

export const SkeletonDataSource = () => {
  return (
    <>
      <div className="data-header-skeleton">
        <Skeleton.Input active size="large" className="skeleton-title" />
      </div>
      <Card
        style={{
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          border: 0,
          boxShadow: "0px 3px 13px rgba(0, 0, 0, 0.14)",
          background: "#ffffff",
        }}
        className="pricing-bullet-points"
      >
        <Space
          style={{ width: "100%" }}
          direction="vertical"
          className="d-block clearfix data-card-title"
        >
          <Skeleton.Input active size="large" />
          <Skeleton.Input active size="large" />
          <Skeleton.Input active size="large" />
        </Space>
        <div style={{ paddingTop: 25 }} className="data-card-skeleton">
          <Space style={{ width: "100%" }} direction="vertical" className="row">
            <div className="w-25 float-left">
              <Skeleton.Input active size="large" className="w-100" />
            </div>
            <div className="w-25 float-left">
              <Skeleton.Input active size="large" className="w-100" />
            </div>
            <div className="w-25 float-left">
              <Skeleton.Input active size="large" className="w-100" />
            </div>
            <div className="w-25 float-left">
              <Skeleton.Input active size="large" className="w-100" />
            </div>
          </Space>
        </div>
      </Card>
    </>
  );
};
