import React from "react";
import { Card, Space, Skeleton } from "antd";

export const SkeletonHelp = () => {
  return (
    <>
      <Card
        style={{
          borderRadius: "10px",
          background: "#e7eef5",
          marginBottom: "0",
        }}
        className="skeleteon-help-header"
      >
        <Space
          style={{ width: "100%" }}
          direction="vertical"
          className="d-block clearfix"
        >
          <Skeleton.Input active size="large" />
          <Skeleton.Input active size="large" />
        </Space>
      </Card>

      <Skeleton.Input active size="large" className="skeleton-help" key={0} />
      <Skeleton.Input active size="large" className="skeleton-help" key={1} />
      <Skeleton.Input active size="large" className="skeleton-help" key={2} />
      <Skeleton.Input active size="large" className="skeleton-help" key={3} />
    </>
  );
};
