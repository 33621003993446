export const listEnumValues = `query ListEnumValues($enum: String!) {
    enum: __type(name: $enum) {
      enumValues {
        name
      }
    }
  }`;

export const getAccountCustom = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      company
      company_loweredcased
      company_description
      url
      disabled
      users
      email
      createdAt
      updatedAt
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      metadata
      owners
      editors
      viewers
      piiInformation
      datasource
      request_googlesheet {
        items {
          id
          school_id
          googlesheet_link
          googlesheet_name
          googlesheet_id
          worksheet_name
          worksheet_id
          unique_field
          is_obtained
          interval_type
          custom_interval
          is_cancelled
          custom_type
          created_by
          last_success_sync_date
          last_failure_sync_date
          refresh_interval_status
          custom_value
          table_name
          createdAt
          updatedAt
        }
        nextToken
      }
      dataSet {
        items {
          id
          school_id
          data_set
          data_source
          purchased_date
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        id
        name
        planDetails
        Targets
        bulletPoints
        listed
        order
        createdAt
        updatedAt
      }
    }
  }
`;

export const getUserCustom = /* GraphQL */ `
query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    disabled
    users
    email
    createdAt
    updatedAt
    accountStatus
  }
}
`;

export const updateAccountPIICustom = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      piiInformation
    }
  }
`;

export const updateAccountEmailCustom = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      email
    }
  }
`;

export const updateDataSetupCustom = /* GraphQL */ `
  mutation UpdateDataSetup(
    $input: UpdateDataSetupInput!
    $condition: ModeldataSetupConditionInput
  ) {
    updateDataSetup(input: $input, condition: $condition) {
      field_key
      name_by_school
    }
  }
`;

export const listDataSetupsCustom = /* GraphQL */ `
  query ListDataSetups(
    $filter: ModeldataSetupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataSetups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        field_key
        friendly_name
        name_by_school
      }
      nextToken
    }
  }
`;

export const dataSetupByWorksheetId = /* GraphQL */ `
  query DataSetupByWorksheetId(
    $worksheet_id: String
    $sortDirection: ModelSortDirection
    $filter: ModeldataSetupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DataSetupByWorksheetId(
      worksheet_id: $worksheet_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        dimension_or_metrics
        field_key
        friendly_name
        field_description
        field_type_GDS
        field_type_PBI
        data_source
        data_set
        api_endpoint
        name_by_school
        worksheet_name
        googlesheet_name
        worksheet_id
        googlesheet_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAccountsCustom = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        company_loweredcased
        company_description
        url
        disabled
        piiInformation
        isSchoolAdmin
        email
        users
        createdAt
        updatedAt
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        plan {
          id
          name
          planDetails
          Targets
          bulletPoints
          listed
          order
          createdAt
          updatedAt
        }
        planID
        metadata
        owners
        editors
        viewers
        datasource
        dataprovider
        __typename
      }
      nextToken
      __typename
    }
  }
`;
