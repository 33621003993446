import React, { useState, useEffect } from "react";
import { Gauge } from "@ant-design/plots";

const QuotaLimit = ({ data }) => {
  const initialConfigData = {
    percent: 0,
    range: {
      color: "#FFC90A",
    },
    indicator: {
      pointer: {
        style: {
          stroke: "#D0D0D0",
        },
      },
      pin: {
        style: {
          stroke: "#D0D0D0",
        },
      },
    },
    axis: {
      label: {
        formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    height: 250,
    tooltip: {
      fields: ["x", "y"],
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
  };

  const [config, setConfig] = useState(initialConfigData);

  useEffect(() => {
    setConfig((pS) => {
      pS.percent = data?.percentage / 100 || 0;
      return { ...pS };
    });
  }, [data]);

  return <Gauge {...config} />;
};

export default QuotaLimit;
