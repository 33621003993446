import { Tabs, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Pages } from "Pages/Pages";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentConfigRenderKey } from "store/actions/settingAction";

const ManageNewDataModule = () => {
  const {
    Manage_Files_Module,
    Data_Management_Module,
    Rule_Engine_Module,
    Data_Package_Module,
  } = Pages;

  const { TabPane } = Tabs;
  const { DataPackage } = Data_Package_Module;

  const { Configure_Module } = Data_Management_Module;

  const { Advanced_List, DataSet } = Configure_Module;

  const { RuleEngine } = Rule_Engine_Module;

  const dispatch = useDispatch();

  const { currentConfigureRenderKey } = useSelector(
    (state) => state.settingReducer
  );

  const tabPaneData = [
    {
      key: "rule",
      tab: (
        <>
          <Tooltip
            placement="bottomLeft"
            color="#333333"
            title="You can set rules and policies from here."
          >
            Rule
          </Tooltip>
        </>
      ),
      component: <RuleEngine />,
    },
    {
      key: "custom-files",
      tab: (
        <>
          <Tooltip
            placement="bottomLeft"
            color="#333333"
            title="You can upload and manage Google Sheets here."
          >
            Custom Files
          </Tooltip>
        </>
      ),
      component: <Manage_Files_Module />,
    },
    {
      key: "advanced-lists",
      tab: (
        <>
          <Tooltip
            placement="bottomLeft"
            color="#333333"
            title="This grants access to Advanced lists and the 'Add New' option."
          >
            Advanced Lists
          </Tooltip>
        </>
      ),
      component: <Advanced_List />,
    },
    {
      key: "data-packages",
      tab: (
        <>
          <Tooltip
            placement="bottomLeft"
            color="#333333"
            title="This grants access to Data Packages and the 'Add New' option."
          >
            Data Packages
          </Tooltip>
        </>
      ),
      component: <DataPackage />,
    },
    {
      key: "data-sets",
      tab: (
        <>
          <Tooltip
            placement="bottomLeft"
            color="#333333"
            title="Data sets can be managed here."
          >
            Data Sets
          </Tooltip>
        </>
      ),
      component: <DataSet />,
    },
  ];

    const handleRedirect = (value) =>
      dispatch(setCurrentConfigRenderKey(value));


  return (
    <>
      <Tabs
        defaultActiveKey={"rule"}
        tabPosition="top"
        className="school-details organization w-100 "
        activeKey={currentConfigureRenderKey}
        onChange={(e) => handleRedirect(e)}
      >
        {tabPaneData.map((tabPaneItem) => (
          <TabPane tab={tabPaneItem?.tab} key={tabPaneItem?.key}>
            {tabPaneItem?.component}
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default ManageNewDataModule;
