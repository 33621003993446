//** React import */
import React, { useEffect, useState } from "react";
//** Ant Design import */
import {
  Dropdown,
  Menu,
  Form,
  Select,
  Button,
  Col,
  Row,
  Input,
  Table,
  Typography,
  Drawer,
  Divider,
  Empty,
} from "antd";
//** Ant Design Icon import */
import { MoreOutlined } from "@ant-design/icons";
import Moment from "react-moment";
// ** SDK imports */
import sdk from "sdk/Accounts";
// ** Redux imports */
import { useSelector } from "react-redux";
// ** Skeleton imports */
import SkeletonTable from "Common/Skeleton/SkeletonTable";
//** Constant imports */
import {
  formLabel,
  MiscellaneousText,
  messageText,
  tableHeader,
  PopConfirmText,
  buttonText,
  adminPanel,
  setupPageText,
  formError,
  paymentTypes,
} from "utility/constants";
//** Third Party imports */
import { useTranslation } from "react-i18next";
//** Utility imports */
import {
  apiExecuter,
  fetchAccountDetails,
  validateEmail,
} from "utility/commonMethods";
//** Components imports */
import InviteModal from "Common/Components/InviteModal/InviteModal";
import Loader from "Common/Components/Loader/Loader";
import lodash from "lodash";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";

export default function Companies(props) {
  //** Destructing Hooks */
  const { t } = useTranslation();

  //** Destructing Constant Text */
  const { addSchool, enter, search, viewAs, admin, users, PaymentType } =
    MiscellaneousText;
  const {
    name,
    id,
    selectFilter,
    searchSchool,
    schoolName,
    lastUsed,
    inviteUser,
    emailText,
    inviteMember,
    schoolWebsite,
  } = formLabel;
  const { schoolAdd } = messageText;
  const { accountIdText } = tableHeader;
  const { adminScopeText } = PopConfirmText;
  const { adminDrawerSaveBtn, Add_School_Details_Title } =
    adminPanel?.schoolTab?.drawer;
  const { Cancel } = buttonText;
  const {
    Invalid_URL_Error,
    Empty_URL_Error,
    Empty_Company_Error,
    Invalid_Company_Error,
  } = setupPageText;
  const { isFieldEmpty } = formError;

  const { ACH, CC, FREE } = paymentTypes;

  const { isLoading } = useSelector((state) => state.loginReducer);

  /**
   * @useState declaration
   */
  //** All School Data */
  const [Companies, setCompanies] = useState([]);
  //** Table Loading */
  const [TableLoading, setTableLoading] = useState(false);
  //** All School Backup Data */
  const [companiesBackup, setCompaniesBackup] = useState([]);
  //** Form Search Param */
  const [SearchParams, setSearchParams] = useState({});
  //** Form Search Option */
  const [SearchOption, setSearchOption] = useState("Name");
  //** Modal Toggle State */
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  //** Set Current User Details */
  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  //** Drawer Toggle State */
  const [addCompanyDrawer, setAddCompanyDrawer] = useState(false);
  //** Add School Form */
  const [addSchoolForm] = Form.useForm();
  //** Search Table Form */
  const [searchForm] = Form.useForm();
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "success",
  });

  /**
   * Getting all school records
   * @param {*} reset
   */
  const getCompanyData = async (reset = false) => {
    let nextToken = null;
    setTableLoading(true);
    setCompanies([]);
    setCompaniesBackup([]);
    do {
      // eslint-disable-next-line no-loop-func
      await sdk.getAllUsers(nextToken).then((response) => {
        const result = response?.data?.listAccounts?.items;
        setCompanies((olddata) => olddata.concat(result));
        setCompaniesBackup((olddata) => olddata.concat(result));
        nextToken = response?.data?.listAccounts?.nextToken;
      });
    } while (nextToken);
    setTableLoading(false);
  };

  /**
   * Handle Search Value of Form
   * @function handleSearch
   * @param {*} value
   */
  const handleSearch = (value) => {
    setCompanies(companiesBackup);
    setSearchParams({
      Name: value["search_type"],
      Value: value["search_value"] ? value["search_value"] : "",
    });
  };

  /**
   * @function searchForm
   * @param {String} SearchParams
   */
  const searchCompany = async (SearchParams) => {
    setTableLoading(true);
    let tempNextToken = null;
    if (Object.keys(SearchParams).length > 0) {
      if (String(SearchParams.Value).length === 0) {
        setCompanies(companiesBackup);
        setTableLoading(false);
      } else {
        let filter = {};
        if (SearchParams.Name === "Name") {
          filter["company_loweredcased"] = {
            contains: String(SearchParams.Value).toLowerCase(),
          };
        } else {
          filter[SearchParams.Name] = { contains: SearchParams.Value };
        }

        setCompanies([]);
        do {
          // eslint-disable-next-line no-loop-func
          await sdk.getAllUsers(tempNextToken, filter).then((response) => {
            const companyList = response?.data?.listAccounts?.items;
            setCompanies((olddata) => olddata.concat(companyList));
            tempNextToken = response?.data?.listAccounts?.nextToken;
            if (!tempNextToken) {
              setTableLoading(false);
            }
          });
        } while (tempNextToken);
      }
    }
  };

  /**
   * Handle creating account
   * @function handleSubmit
   * @param {*} e
   */
  const handleSubmit = async (e) => {
    let data = addSchoolForm.getFieldsValue();
    setTableLoading(true);
    let result = await apiExecuter(
      async () => await sdk.createSchoolAccount(e.company, e.url, data.emails)
    );

    if (result.error) {
      //message.error(result.error)
      setApiResultModal(() => ({
        message: t(result?.error),
        isApiResultModelOpen: true,
        type: "failure",
      }));
    } else {
      // message.success(t(schoolAdd));
      setApiResultModal(() => ({
        message: schoolAdd,
        isApiResultModelOpen: true,
        type: "success",
      }));
      addSchoolForm.resetFields();
    }
    getCompanyData();
    setTableLoading(false);
    setAddCompanyDrawer(false);
  };

  /**
   * Fetches account base upon @AccountId
   * @function handleAccountDetails
   */
  const handleAccountDetails = () => fetchAccountDetails();

  /**
   * School Table Column Data
   * @var {array}
   */
  const columns = [
    {
      title: <strong>{t(accountIdText)}</strong>,
      width: "25%",
      dataIndex: "GroupName",
      key: "name",
      render: (name, record) => <span>{record?.id}</span>,
      sorter: (a, b) => String(a?.id).localeCompare(b?.id),
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: <strong>{t(schoolName)}</strong>,
      width: "15%",
      dataIndex: "Description",
      key: "Descriptione",
      render: (Description, record) => <span>{record?.company}</span>,

      sorter: (a, b) => String(a?.company).localeCompare(b?.company),
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: <strong>{t(viewAs)} </strong>,
      width: "8%",
      dataIndex: "",
      key: "viewas",
      render: (Description, record) => {
        return (
          <>
            <span
              title={t(adminScopeText)}
              className="company-name font-bold"
              onClick={() => props.selectCompany(record, "admin")}
            >
              {t(admin)}
            </span>
          </>
        );
      },
    },
    {
      title: <strong>{t(users)} </strong>,
      width: "8%",
      dataIndex: "users",
      key: "users",
      render: (recordData) => <span>{recordData?.length}</span>,
      sorter: (a, b) => {
        return String(a?.users?.length).localeCompare(b?.users?.length);
      },
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: <strong>{t(PaymentType)} </strong>,
      width: "10%",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (_, record) => (
        <span className="font-bold">
          {lodash.capitalize(record?.plan?.name) || "No Plan Selected"}
        </span>
      ),
      sorter: (a, b) =>
        String(JSON.parse(a?.metadata)?.paymentType).localeCompare(
          JSON.parse(b?.metadata)?.paymentType
        ),
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: <strong>{t(lastUsed)} </strong>,
      width: "15%",
      dataIndex: "updatedAt",
      key: "last_modified",
      render: (modifyDate) => <Moment date={modifyDate} format="lll" />,
      sorter: (a, b) => {
        return String(a?.updatedAt).localeCompare(b?.updatedAt);
      },
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "",
      width: "3%",
      dataIndex: "id",
      key: "actions",
      render: (_, record) => (
        <div className="d-flex justify-content-center cursor-pointer">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="invite">
                  <span
                    className="font-bold"
                    onClick={() => {
                      setIsInviteModalOpen(true);
                      setCurrentUserDetails(record);
                    }}
                  >
                    {t(inviteUser)}
                  </span>
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined className="more-outline-style" />
          </Dropdown>
        </div>
      ),
    },
  ];

  /** @useEffect for handling data */
  useEffect(() => {
    getCompanyData();
    handleAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    searchCompany(SearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams]);
  useEffect(() => {
    searchForm.setFieldsValue({ search_value: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchOption]);

  return (
    <>
      <div>
        <Row>
          <Col
            lg={{
              offset: 8,
              span: 10,
            }}
            md={{
              offset: 0,
              span: 24,
            }}
            sm={{
              offset: 0,
              span: 24,
            }}
            xs={{ offset: 0, span: 24 }}
          >
            <Form
              autoComplete="off"
              name="search_Companies"
              onFinish={handleSearch}
              initialValues={{ search_type: "Name" }}
              form={searchForm}
              layout="vertical"
              className="school-form"
            >
              <Form.Item
                name="search_type"
                label={t(selectFilter)}
                className="school-form-input"
              >
                <Select
                  className="school-form-dropdown"
                  size="large"
                  onSelect={(e) => setSearchOption(e)}
                >
                  <Select.Option key="Name">{t(name)} </Select.Option>
                  <Select.Option key="id">{t(id)} </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="search_value"
                label={
                  <div className="d-flex gap-3">
                    {t(searchSchool)} {TableLoading ? <Loader /> : null}
                  </div>
                }
                className="school-form-input"
              >
                <Input
                  placeholder={`${t(enter)} ${t(SearchOption)}`}
                  className="search-school-container"
                />
              </Form.Item>
              <Form.Item className="companysearchbtn">
                <Button
                  type="primary"
                  htmlType="submit"
                  // className="btn-primary btn-disable"
                  disabled={TableLoading}
                >
                  {t(search)}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col
            span={6}
            lg={6}
            md={24}
            sm={24}
            xs={24}
            className="company-add-account"
          >
            <Button onClick={() => setAddCompanyDrawer(true)} type="primary">
              {t(addSchool)}
            </Button>
          </Col>
        </Row>

        <div>
          <Table
            className="tablecell"
            bordered
            size="middle"
            dataSource={Companies}
            columns={columns}
            locale={{
              emptyText:
                isLoading || TableLoading ? <SkeletonTable /> : <Empty />,
            }}
            scroll={{
              x: 1000,
            }}
          />
        </div>

        <InviteModal
          isInviteModelOpen={isInviteModalOpen}
          setIsInviteModelOpen={setIsInviteModalOpen}
          currentUserDetails={currentUserDetails}
        />

        <Drawer
          className="editcompany"
          width={430}
          title={
            <span className="font-bold off-mine-shaft-color">
              {t(Add_School_Details_Title)}
            </span>
          }
          placement="right"
          onClose={() => {
            setAddCompanyDrawer(false);
          }}
          visible={addCompanyDrawer}
        >
          <Form
            labelCol={{ xs: 24 }}
            requiredMark={false}
            layout="vertical"
            name="new_account"
            className="login-data"
            onFinish={(e) => handleSubmit(e)}
            form={addSchoolForm}
          >
            <Form.Item
              name="company"
              className="mt-0 mb-12"
              label={
                <span className="admin-drawer-add-school-form">
                  {t(schoolName)}
                </span>
              }
              rules={[
                {
                  required: true,
                  message: t(Empty_Company_Error),
                },
                {
                  pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .]+)$/,
                  message: t(Invalid_Company_Error),
                },
              ]}
            >
              <Input
                size="middle"
                id="company"
                key="company"
                name="company"
                placeholder={t(schoolName)}
                className="login-module-input"
              />
            </Form.Item>

            <Form.Item
              name="url"
              label={
                <span className="admin-drawer-add-school-form">
                  {t(schoolWebsite)}
                </span>
              }
              rules={[
                {
                  required: true,
                  message: t(Empty_URL_Error),
                },
                {
                  pattern:
                    /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                  message: t(Invalid_URL_Error),
                },
              ]}
            >
              <Input
                size="middle"
                id="url"
                key="url"
                name="url"
                placeholder={t(schoolWebsite)}
                className="login-module-input"
              />
            </Form.Item>

            <Divider className="admin-drawer-divider" />
            <Typography.Title className="fs-18 color-black">
              {t(inviteMember)}
            </Typography.Title>
            <Form.Item
              label={
                <span className="admin-drawer-add-school-form">
                  {t(emailText)}
                </span>
              }
              name="emails"
              rules={[
                {
                  required: true,
                  message: t(isFieldEmpty),
                },
              ]}
            >
              <Select
                className="invitemebers login-module-input w-100"
                mode="tags"
                tokenSeparators={[","]}
                notFoundContent=""
                onChange={validateEmail}
                placeholder={
                  <span className="grey-color"> {t(emailText)} </span>
                }
              />
            </Form.Item>

            <div className="admin-drawer-footer">
              <Button
                className="secondary-btn w-50"
                onClick={() => setAddCompanyDrawer(false)}
              >
                {t(Cancel)}
              </Button>
              <Button htmlType="submit" type="primary" loading={isLoading}>
                {t(adminDrawerSaveBtn)}
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
}
