/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount {
    onCreateAccount {
      id
      company
      company_loweredcased
      company_description
      url
      disabled
      piiInformation
      isSchoolAdmin
      email
      users
      createdAt
      updatedAt
      data_setup {
        nextToken
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      metadata
      owners
      editors
      viewers
      datasource
      dataprovider
      advancedList {
        nextToken
        __typename
      }
      request_googlesheet {
        nextToken
        __typename
      }
      recurringSync {
        nextToken
        __typename
      }
      apihistory {
        nextToken
        __typename
      }
      dataSet {
        nextToken
        __typename
      }
      requests {
        nextToken
        __typename
      }
      plan {
        id
        name
        planDetails
        Targets
        bulletPoints
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount {
    onUpdateAccount {
      id
      company
      company_loweredcased
      company_description
      url
      disabled
      piiInformation
      isSchoolAdmin
      email
      users
      createdAt
      updatedAt
      data_setup {
        nextToken
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      metadata
      owners
      editors
      viewers
      datasource
      dataprovider
      advancedList {
        nextToken
        __typename
      }
      request_googlesheet {
        nextToken
        __typename
      }
      recurringSync {
        nextToken
        __typename
      }
      apihistory {
        nextToken
        __typename
      }
      dataSet {
        nextToken
        __typename
      }
      requests {
        nextToken
        __typename
      }
      plan {
        id
        name
        planDetails
        Targets
        bulletPoints
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount {
    onDeleteAccount {
      id
      company
      company_loweredcased
      company_description
      url
      disabled
      piiInformation
      isSchoolAdmin
      email
      users
      createdAt
      updatedAt
      data_setup {
        nextToken
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      metadata
      owners
      editors
      viewers
      datasource
      dataprovider
      advancedList {
        nextToken
        __typename
      }
      request_googlesheet {
        nextToken
        __typename
      }
      recurringSync {
        nextToken
        __typename
      }
      apihistory {
        nextToken
        __typename
      }
      dataSet {
        nextToken
        __typename
      }
      requests {
        nextToken
        __typename
      }
      plan {
        id
        name
        planDetails
        Targets
        bulletPoints
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateAdvancedListDetail = /* GraphQL */ `
  subscription OnCreateAdvancedListDetail {
    onCreateAdvancedListDetail {
      id
      school_id
      list_name
      list_id
      datasource
      dataset
      status
      preconfigure
      last_sync_date
      interval_type
      recurring_sync_status
      custom_interval
      custom_type
      custom_value
      is_initial_sync
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAdvancedListDetail = /* GraphQL */ `
  subscription OnUpdateAdvancedListDetail {
    onUpdateAdvancedListDetail {
      id
      school_id
      list_name
      list_id
      datasource
      dataset
      status
      preconfigure
      last_sync_date
      interval_type
      recurring_sync_status
      custom_interval
      custom_type
      custom_value
      is_initial_sync
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAdvancedListDetail = /* GraphQL */ `
  subscription OnDeleteAdvancedListDetail {
    onDeleteAdvancedListDetail {
      id
      school_id
      list_name
      list_id
      datasource
      dataset
      status
      preconfigure
      last_sync_date
      interval_type
      recurring_sync_status
      custom_interval
      custom_type
      custom_value
      is_initial_sync
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDataPackageDetail = /* GraphQL */ `
  subscription OnCreateDataPackageDetail {
    onCreateDataPackageDetail {
      id
      school_id
      package_name
      datasource
      dataset
      status
      last_sync_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDataPackageDetail = /* GraphQL */ `
  subscription OnUpdateDataPackageDetail {
    onUpdateDataPackageDetail {
      id
      school_id
      package_name
      datasource
      dataset
      status
      last_sync_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDataPackageDetail = /* GraphQL */ `
  subscription OnDeleteDataPackageDetail {
    onDeleteDataPackageDetail {
      id
      school_id
      package_name
      datasource
      dataset
      status
      last_sync_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGoogleSheetDetail = /* GraphQL */ `
  subscription OnCreateGoogleSheetDetail {
    onCreateGoogleSheetDetail {
      id
      school_id
      created_by
      googlesheet_link
      googlesheet_name
      googlesheet_id
      worksheet_name
      worksheet_id
      unique_field
      is_obtained
      interval_type
      last_success_sync_date
      last_failure_sync_date
      custom_interval
      is_cancelled
      custom_type
      custom_value
      table_name
      refresh_interval_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGoogleSheetDetail = /* GraphQL */ `
  subscription OnUpdateGoogleSheetDetail {
    onUpdateGoogleSheetDetail {
      id
      school_id
      created_by
      googlesheet_link
      googlesheet_name
      googlesheet_id
      worksheet_name
      worksheet_id
      unique_field
      is_obtained
      interval_type
      last_success_sync_date
      last_failure_sync_date
      custom_interval
      is_cancelled
      custom_type
      custom_value
      table_name
      refresh_interval_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGoogleSheetDetail = /* GraphQL */ `
  subscription OnDeleteGoogleSheetDetail {
    onDeleteGoogleSheetDetail {
      id
      school_id
      created_by
      googlesheet_link
      googlesheet_name
      googlesheet_id
      worksheet_name
      worksheet_id
      unique_field
      is_obtained
      interval_type
      last_success_sync_date
      last_failure_sync_date
      custom_interval
      is_cancelled
      custom_type
      custom_value
      table_name
      refresh_interval_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRecurringSync = /* GraphQL */ `
  subscription OnCreateRecurringSync {
    onCreateRecurringSync {
      id
      school_id
      success
      worksheet_id
      worksheet_name
      interval_type
      sync_for
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRecurringSync = /* GraphQL */ `
  subscription OnUpdateRecurringSync {
    onUpdateRecurringSync {
      id
      school_id
      success
      worksheet_id
      worksheet_name
      interval_type
      sync_for
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRecurringSync = /* GraphQL */ `
  subscription OnDeleteRecurringSync {
    onDeleteRecurringSync {
      id
      school_id
      success
      worksheet_id
      worksheet_name
      interval_type
      sync_for
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCountOfAPIHistory = /* GraphQL */ `
  subscription OnCreateCountOfAPIHistory {
    onCreateCountOfAPIHistory {
      id
      school_id
      api_key
      dataset_type
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCountOfAPIHistory = /* GraphQL */ `
  subscription OnUpdateCountOfAPIHistory {
    onUpdateCountOfAPIHistory {
      id
      school_id
      api_key
      dataset_type
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCountOfAPIHistory = /* GraphQL */ `
  subscription OnDeleteCountOfAPIHistory {
    onDeleteCountOfAPIHistory {
      id
      school_id
      api_key
      dataset_type
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDataSetDetail = /* GraphQL */ `
  subscription OnCreateDataSetDetail {
    onCreateDataSetDetail {
      id
      school_id
      data_set
      data_source
      purchased_date
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDataSetDetail = /* GraphQL */ `
  subscription OnUpdateDataSetDetail {
    onUpdateDataSetDetail {
      id
      school_id
      data_set
      data_source
      purchased_date
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDataSetDetail = /* GraphQL */ `
  subscription OnDeleteDataSetDetail {
    onDeleteDataSetDetail {
      id
      school_id
      data_set
      data_source
      purchased_date
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateVeracrossDetail = /* GraphQL */ `
  subscription OnCreateVeracrossDetail($id: String) {
    onCreateVeracrossDetail(id: $id) {
      id
      school_id
      veracross_client_id
      veracross_client_secret
      scope
      school_route
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateVeracrossDetail = /* GraphQL */ `
  subscription OnUpdateVeracrossDetail($id: String) {
    onUpdateVeracrossDetail(id: $id) {
      id
      school_id
      veracross_client_id
      veracross_client_secret
      scope
      school_route
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteVeracrossDetail = /* GraphQL */ `
  subscription OnDeleteVeracrossDetail($id: String) {
    onDeleteVeracrossDetail(id: $id) {
      id
      school_id
      veracross_client_id
      veracross_client_secret
      scope
      school_route
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateIntegrationSyncHistory = /* GraphQL */ `
  subscription OnCreateIntegrationSyncHistory {
    onCreateIntegrationSyncHistory {
      id
      account
      platform
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
      __typename
    }
  }
`;
export const onUpdateIntegrationSyncHistory = /* GraphQL */ `
  subscription OnUpdateIntegrationSyncHistory {
    onUpdateIntegrationSyncHistory {
      id
      account
      platform
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
      __typename
    }
  }
`;
export const onDeleteIntegrationSyncHistory = /* GraphQL */ `
  subscription OnDeleteIntegrationSyncHistory {
    onDeleteIntegrationSyncHistory {
      id
      account
      platform
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
      __typename
    }
  }
`;
export const onCreateApiKey = /* GraphQL */ `
  subscription OnCreateApiKey {
    onCreateApiKey {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateApiKey = /* GraphQL */ `
  subscription OnUpdateApiKey {
    onUpdateApiKey {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteApiKey = /* GraphQL */ `
  subscription OnDeleteApiKey {
    onDeleteApiKey {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFAQ = /* GraphQL */ `
  subscription OnCreateFAQ {
    onCreateFAQ {
      id
      question
      answer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFAQ = /* GraphQL */ `
  subscription OnUpdateFAQ {
    onUpdateFAQ {
      id
      question
      answer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFAQ = /* GraphQL */ `
  subscription OnDeleteFAQ {
    onDeleteFAQ {
      id
      question
      answer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFirebaseTokens = /* GraphQL */ `
  subscription OnCreateFirebaseTokens {
    onCreateFirebaseTokens {
      id
      token
      user
      userDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFirebaseTokens = /* GraphQL */ `
  subscription OnUpdateFirebaseTokens {
    onUpdateFirebaseTokens {
      id
      token
      user
      userDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFirebaseTokens = /* GraphQL */ `
  subscription OnDeleteFirebaseTokens {
    onDeleteFirebaseTokens {
      id
      token
      user
      userDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserNotification = /* GraphQL */ `
  subscription OnCreateUserNotification {
    onCreateUserNotification {
      id
      user
      userDetails
      userNotificationPreference
      title
      content
      message
      code
      metadata
      isRead
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserNotification = /* GraphQL */ `
  subscription OnUpdateUserNotification {
    onUpdateUserNotification {
      id
      user
      userDetails
      userNotificationPreference
      title
      content
      message
      code
      metadata
      isRead
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserNotification = /* GraphQL */ `
  subscription OnDeleteUserNotification {
    onDeleteUserNotification {
      id
      user
      userDetails
      userNotificationPreference
      title
      content
      message
      code
      metadata
      isRead
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInitialSyncDetails = /* GraphQL */ `
  subscription OnCreateInitialSyncDetails {
    onCreateInitialSyncDetails {
      id
      school_id
      data_provider
      data_source
      last_sync_date
      initial_sync_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInitialSyncDetails = /* GraphQL */ `
  subscription OnUpdateInitialSyncDetails {
    onUpdateInitialSyncDetails {
      id
      school_id
      data_provider
      data_source
      last_sync_date
      initial_sync_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInitialSyncDetails = /* GraphQL */ `
  subscription OnDeleteInitialSyncDetails {
    onDeleteInitialSyncDetails {
      id
      school_id
      data_provider
      data_source
      last_sync_date
      initial_sync_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCsvDetail = /* GraphQL */ `
  subscription OnCreateCsvDetail {
    onCreateCsvDetail {
      id
      school_id
      created_by
      csv_name
      unique_field
      is_obtained
      is_cancelled
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCsvDetail = /* GraphQL */ `
  subscription OnUpdateCsvDetail {
    onUpdateCsvDetail {
      id
      school_id
      created_by
      csv_name
      unique_field
      is_obtained
      is_cancelled
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCsvDetail = /* GraphQL */ `
  subscription OnDeleteCsvDetail {
    onDeleteCsvDetail {
      id
      school_id
      created_by
      csv_name
      unique_field
      is_obtained
      is_cancelled
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePreviewDetail = /* GraphQL */ `
  subscription OnCreatePreviewDetail {
    onCreatePreviewDetail {
      id
      school_id
      created_by
      data_set
      show_fields
      hidden_fields
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePreviewDetail = /* GraphQL */ `
  subscription OnUpdatePreviewDetail {
    onUpdatePreviewDetail {
      id
      school_id
      created_by
      data_set
      show_fields
      hidden_fields
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePreviewDetail = /* GraphQL */ `
  subscription OnDeletePreviewDetail {
    onDeletePreviewDetail {
      id
      school_id
      created_by
      data_set
      show_fields
      hidden_fields
      createdAt
      updatedAt
      __typename
    }
  }
`;
