//** React Imports */
import React, { useState, useEffect } from "react";
//** Ant Imports */
import { Row, Col, Input, Form, Button, message, Typography } from "antd";
//** React Router Imports */
import { useHistory } from "react-router-dom";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** React OTP Imports */
import OtpInput from "react-otp-input";
//** Components Imports */
import LoginModuleLogo from "Common/Components/LoginModuleLogo/LoginModuleLogo";
import Footer from "Common/Layouts/Footer";
import Background from "Common/Layouts/AuthColOne";
import { useTranslation } from "react-i18next";
import {
  buttonText,
  formError,
  formLabel,
  messageText,
  MiscellaneousText,
} from "utility/constants";
import Title from "Common/Components/CustomAntD/Title";

/**
 * @function base component
 * @param {*} props
 */

const ResetPassword = (props) => {
  //** Assigning Hooks  */
  const history = useHistory();
  //** Destructing Hooks */
  const { t } = useTranslation();

  //** Destructing Ant Components */
  const { Text } = Typography;

  //** Destructing Constants */
  const { New_Confirmation_Code_Message } = messageText;
  const { Confirm_Email_Text, Enter_Code_Text } = MiscellaneousText;
  const { isCodeEmpty } = formError;
  const { New_Password_Text, New_Password_Label } = formLabel;
  const { Confirm_Email_Btn, Resend_Code_Btn } = buttonText;

  //** States for handling hooks */
  const [otp, setOtp] = useState();
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const [email, setemail] = useState(
    props.location?.state ? props.location?.state?.userName : ""
  );

  //** Use Effect for checking if email exists or not */
  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/reset-password") &&
        window.location.href.includes("email=")
      ) {
        let Data = {};
        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        form.setFieldsValue({
          code: Data.code,
          password: "",
        });
        setemail(window.location.href.split("email=")[1]);
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handle reset code from AWS Cognito
   */
  const resendConfirmationCode = async () => {
    try {
      await Auth.forgotPassword(email).then(() => {
        message.success(t(New_Confirmation_Code_Message));
        setloading(false);
      });
    } catch (error) {
      console.log("error resend code :", error.message);
      setloading(false);
      message.error(error.message);
    }
  };

  //** Confirm email and otp using new password */
  const confirmReset = async (e) => {
    setloading(true);

    try {
      await Auth.forgotPasswordSubmit(email, e.code, e.password).then(() => {
        setloading(false);
        history.push({
          pathname: "/signin",
          state: { feedback: "Signin with new password.", userName: "" },
        });
      });
    } catch (error) {
      console.log("error resetting password:", error);
      message.error(error.message);
    } finally {
      setloading(false);
      setOtp(null);
      form.resetFields();
    }
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  return (
    <Row justify="center">
      <Background />
      <Col span={12} md={12} lg={12} xs={24}>
        <Row
          gutter={24}
          justify="center"
          align="middle"
          className="login-module-col-2"
        >
          <Col xs={24} sm={24} md={24} lg={18} xl={12} xxl={12}>
            <LoginModuleLogo />
            <Form
              layout="vertical"
              name="normal_signup"
              requiredMark={false}
              onFinish={(e) => confirmReset(e)}
              form={form}
            >
              <Row justify="center">
                <Title level={5}>{t(Confirm_Email_Text)}</Title>
                <Text className="confirm-email-paragraph mb-3">
                  {t(Enter_Code_Text)}
                </Text>
              </Row>

              <Form.Item
                className="otp"
                name="code"
                rules={[
                  {
                    required: true,
                    message: t(isCodeEmpty),
                  },
                ]}
              >
                <OtpInput
                  name="code"
                  id="code"
                  key="code"
                  shouldAutoFocus
                  className="otp-input"
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum={true}
                  //Dev Comment :- isInputSecure={true}
                />
              </Form.Item>

              <Form.Item
                label={t(New_Password_Text)}
                name="password"
                className="font-bold"
              >
                <Input.Password placeholder={t(New_Password_Label)} />
              </Form.Item>

              <Form.Item>
                <Row justify="space-between" align="middle" gutter={8}>
                  <Col span={12}>
                    <Button
                      size="large"
                      type="primary"
                      block
                      ghost
                      onClick={resendConfirmationCode}
                    >
                      {t(Resend_Code_Btn)}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      size="large"
                      disabled={loading}
                      htmlType="submit"
                      block
                      tabIndex={5}
                      onClick={() => history.push("/reset-password")}
                      type="primary"
                    >
                      {t(Confirm_Email_Btn)}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
          <Footer />
        </Row>
      </Col>
    </Row>
  );
};

export default ResetPassword;
