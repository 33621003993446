import i18next from "i18next";

const currentYear = new Date().getFullYear();

export const emailPattern = /^[a-z0-9+._-]+@[a-z0-9+.-]+\.[a-z]{2,4}$/;

export const LayoutText = {
  FooterText: `Version ${process.env.REACT_APP_VERSION} © ${currentYear} SchoolBI copyright all rights reserved.`,
  AuthColOneTitle: "Welcome to SchoolBI",
  AuthColOneText: "Mission control for your school’s data",
  CopyrightText: `SchoolBI © ${currentYear}`,
};

export const MiscellaneousText = {
  yesText: "Yes",
  viewAs: "View As",
  viewingAs: "Viewing as ",
  userView: "user view",
  admin: "Admin",
  adminView: "admin view",
  version: "Version",
  school: "School",
  search: "Search",
  addSchool: "Add School",
  enter: "Enter",
  cancel: "Cancel",
  schools: "Schools",
  users: "Users",
  PaymentType: "Plan",
  Hello: "Hello,",
  Logout_Text: "Logout",
  Requests_Text: "Requests",
  Do_not_Account_Text: "Don't have an Account?",
  Confirm_Email_Text: "Confirm your email id",
  Enter_Code_Text: "Enter code sent in your email",
  Pending_Approval_Text: "Pending approval for request!",
  Request_Send_Text:
    "Your request to join team has been sent to account administrator.Once it is approved, you will receive an email to login to your account.",
  Help_Connect_Text: "For any help connect via",
  Connect_Mail_Text: "connect@schoolbi.com",
  Data_Source_Text: "Data Source",
  Data_Source_Selected_Text: "data sources have been selected",
  Connectors_Text: "data sources",
  Popular_Text: "Popular",
  Copy_Text: "Copy",
  Inactive_Text: "Inactive",
  Active_Text: "Active",
  Inprogress_Text: "In progress",
};

export const PopConfirmText = {
  profileText: "Do you want to switch to your profile?",
  adminScopeText: "Click here to select school for admin view",
  deleteUserText: "Are you sure you want to delete user?",
  lastDeleteUserTextP1:
    "Please Note: The user you are trying to delete is the last one associated to the corresponding school.",
  lastDeleteUserTextP2: "Are you sure you want to continue?",
};

export const adminPanel = {
  userTab: {
    adminViewContentText: "Click here to select school for admin view",
  },
  schoolTab: {
    drawer: {
      adminDrawerSaveBtn: "Add School & Save",
      Add_School_Details_Title: "Add School details",
    },
  },
};

export const formLabel = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  Email_Label: "Email",
  search: "Search",
  name: "Name",
  id: "Id",
  onboardingStatus: "Onboarding Status",
  lastUsed: "Last used",
  searchUsers: "Search Users",
  searchSchool: "Search School",
  selectFilter: "Select Filter",
  schoolName: "School Name",
  schoolWebsite: "School Website",
  inviteMember: "Invite Member",
  inviteUser: "Invite User",
  emailText: "Enter email id",
  Password_Label: "Enter Password",
  New_Password_Label: "Enter New Password",
  Password_Text: "Password",
  New_Password_Text: "New Password",
  First_Name_Placeholder: "Your first name",
  Last_Name_Placeholder: "Your last name",
  Create_Password_Label: "Create Password",
  TAndC_Label: "I agree to the",
  TAndC_Label_P2: "Main Service Agreement",
  Privacy_Policy_P1:
    "By registering, you agree to SchoolBI processing your personal data as per our",
  Privacy_Policy_P2: "Privacy Policy",
};
export const formError = {
  isFieldEmpty: "This field can not be empty!",
  isLastNameEmpty: "Input Last Name!",
  isFirstNameEmpty: "Input First Name!",
  isEmailInvalid: "Please provide valid email!",
  isEmailEmpty: "Please input your email!",
  isPasswordEmpty: "Please input your password!",
  isPasswordMin: "Password must be min. 8 characters.",
  isCodeEmpty: "Please input your Confirmation Code!",
  isFieldInvalid: "Invalid characters",
  isTAndCInvalid: "Please agree with main service agreement!",
};
export const messageText = {
  schoolAdd: "School added successfully",
  inviteSend: "Invite sent successfully",
  profileUpdate: "Profile updated successfully",
  Failed_Approve_Text: "Failed to approve request",
  Approve_MText: "Approved successfully.",
  Denied_MText: "Denied successfully",
  Account_Created_Message: "Account created successfully",
  Account_Invalid_Message: "Account id is not valid",
  Request_Submitted_Message: "Your request has been submitted",
  New_Confirmation_Code_Message: "A new confirmation code has been sent.",
  Sign_In_Account_Message: "Sign in with your new account",
  Something_Went_Wrong_Message: "Something Went Wrong",
  Already_Verified_Message: "Already Verified",
  Fail_to_logout_Message: "Failed to log out",
  Failed_to_update_plan_Message: "Failed to update plan information",
  Plan_Update_Success_Message: "Plan Updated successfully",
  Plan_Purchased_Success_Message: "Plan Purchased successfully",
};

export const tableHeader = {
  accountIdText: "Account ID",
};

export const helpSection = {
  helpDrawerTitle: "FAQ Admin",
  faqContainer: {
    faqTitle: "Let's answer some questions",
    faqSubheading: "or choose a category to quickly find the help you need",
  },
  Delete_Confirm: "Do you want to delete the question?",
};

export const buttonText = {
  Add_New_Question: "Add New Question",
  Add_New: "Add New",
  Save: "Save",
  Cancel: "Cancel",
  Share_Invite: "Share Invite",
  Help_Text: "Help",
  Configure_Text: "Configure",
  Invite_Team_Title: "Invite team members",
  Manage_Plan: "Manage Plan",
  Yes_Sure_Text: " Yes, Sure",
  Delete_User_Text: "Delete user",
  Approve_Text: "Approve",
  Deny_Text: "Deny",
  Pay_Now_Text: "Pay Now",
  Get_Started_Text: "Get Started",
  Request_Access_Text: "Request Access",
  Continue_Text: "Continue",
  Login_Text: "Login",
  Forget_Password_Text: "Forgot password?",
  Sign_Up_Text: "Sign Up",
  Send_code_Text: "Send Code",
  Confirm_Email_Btn: "Confirm Email",
  Resend_Code_Btn: "Resend code",
  Thank_You_Btn: "Ok, Thank You!",
  Selected_Btn: "Selected",
  Disconnect_Btn: "Disconnect",
  Starting_Btn: "Starting",
  Deleting_Text: "Deleting",
  Connect_Btn: "Connect",
  Disconnected_Btn: "Disconnected",
  Connected_Btn: "Connected",
  Not_Connected_Btn: "Not Connected",
  Done_Btn: "Done",
  OK_Btn: "OK",
  Upload_Btn: "Upload",
  Refresh_Interval_Btn: "Set Refresh Interval",
  Select_Plan_Btn: "Select Plan",
  Selected_Plan_Btn: "Selected Plan",
  Upgrade_Plan_Btn: "Update Plan",
  Manage_Billing_Btn: "Manage Billing",
  Link_Account_Btn: "Link Account",
  Assign_Btn: "Assign",
  Enable_Btn: "Enable",
  Disable_Btn: "Disable",
  Unsuspend_School_Btn: "Un-Suspend School",
  Suspend_School_Btn: "Suspend School",
  Manage_File_Btn: "Manage Files",
  Archieve_Files_Btn: "Archive Files",
  Sync_now_Btn: "Sync Now",
  Try_Again: "Try Again",
  Delete: "Delete",
  Cancel_Uploading: "Cancel Uploading",
  Reset: "Reset",
  Apply: "Apply",
};

export const modalText = {
  Invite_Modal: {
    Invite_Text:
      "Share direct invitation to your team members so they can join SchoolBI with your organization account ID",
  },
  Profile_Modal: {
    Logout_Modal_Text_Part_1: "Are you sure you want",
    Logout_Modal_Text_Part_2: "to logout?",
  },
  Integration_Modal: {
    Keep_Selected_Text:
      "Are you sure you want to keep the selected data sources",
  },
  Data_Source_Modal:
    "Please complete enabling of the custom files by clicking on “Done” on the above of the page",
  No_Plan_Active: {
    Subscribe_Plan_Now_Text: "Subscribe Plan Now",
    No_Plan_Active_Title: "Need to subscribe plan",
    No_Plan_Active_Text:
      "I apologise for the inconvenience, but it seems that you haven't subscribed to a plan yet. In order to access the data you're requesting, you will need to subscribe to a plan first.",
  },
  Unauthorized_Modal: {
    Unauthorized_Text:
      "You are unauthorized to access stage. Please contact admininstrator.",
  },
};

export const homeText = {
  Work_Collab_Text:
    "Work collaboratively with your team members to bring efficient results",
  DS_Configuration_Text: "Data Source Configuration",
  Learn_3p_Text: "Learn how to use SchoolBI",
  Manage_Your_Plan: "Manage your plan",
  Welcome_Home_Text_P1:
    "Welcome Home! Managing your school’s data operations is",
  Welcome_Home_Text_P2: "easier than you think!",
};

export const profileText = {
  Basic_Information_Text: "Basic Information",
  User_Profile_Text: "User Profile",
  Basic_Details_Text: "Basic Details",
};

export const schoolSettingsText = {
  School_Settings_Text: "School Settings",
  userDetailsText: {
    Search_Team_Member_Text: "Search Team Member",
    List_Action_Text: {
      Assign_Role_Text: "Assign admin role",
    },
  },
};

export const billingPageText = {
  Choose_Plan_Text: "Choose Plan",
  Select_Payment_Method_Text: "Select payment method",
  Billing_Heading_Text: "SchoolBI Subscription Plans",
  Billing_Subheading_Text: "Flexible plans built for schools just like yours",
  Plan_Comes_With_Text: "plan comes with",
  Need_More_Connector_Text: "Need more data sources?",
  Extra_Charge_Text: "Extra charges will be applicable per source",
  Data_Source_Increase_Text: "Are you sure you want to add more data sources?",
  Charge_Applicable_Text: "Charges will be applicable.",
  Account_Link_Successful_Text:
    "Your account has been successfully linked with!",
  ACH_Text: "ACH",
  Targets_Text: "Targets",
  Pricing_Text: "Pricing",
  Needs_Attention_Text: "Needs Attention",
  Payment_Receieved_Text: "Payment has been already received",
  Plan_Details_Text: "Plan Details",
  Admin_Assigned_Part_1: "Admin has assigned you this plan. You can contact at",
  Admin_Assigned_Part_2: "for more info",
  Plan_Selected_Text: "Plan Selected Successfully",
  Go_To_Management_Text: " Go To Data Management",
  Subscription_Selected_Text:
    "Your SchoolBI subscription plan selected successfully. Now you can manage your Data Sources and configure your data in Data Management.",
};

export const schoolDetailsText = {
  School_Information_Text: "School Information",
  Personally_Identifiable_Text: "Personally Identifiable Information",
  Your_Secret_Text: "Your Secret Key",
  Generate_New_Secret_Key_Text: "Generate Key",
  Unsuspend_School_Text: "Are you sure you want to unsuspend the school?",
  Suspend_School_Text: "Are you sure you want to suspend the school?",
  Unique_Secret_Key_Text:
    "This is your unique secret key. <br /> If you lose this key, you will have to regenerate it.",
  No_key_Text: "No key has been generated",
  Enable_Disable_PII_Text:
    "Enable/Disable to show/hide the fields while sending the data",
  PII_Information_Text:
    "Hide personal information fields in user records from external user. Enabling this setting applies the personally Identifiable Information category to following fields.",
};

export const userDetailsText = {
  InviteTeamMemberText_P1: "Work collaboratively with your team members",
  InviteTeamMemberText_P2: "to bring efficient results",
};

export const setupPageText = {
  Join_Existing_Account_Text: "Join Existing Account",
  Request_Access_Part1_Text: "You can request access to an existing account",
  Request_Access_Part2_Text: " if you know the account identification number.",
  Customer_ID_Text: "Account Id from your school",
  Account_Enter_Error: "Please enter the Account ID of your school",
  Account_Invalid_Error: "Please enter valid Account ID",
  Com_Web_Placeholder: "School Website",
  Start_New_Account: "Start New Account",
  Free_Trial_Part1_Text: "Start your free trial now, if no one at your",
  Free_Trial_Part2_Text: "school has set up an account with us",
  Create_Company_Text: "Create School",
  Empty_Company_Error: "Please enter the name of your school",
  Invalid_Company_Error: "Please enter valid school name",
  Customer_Id_Label: "Account Id",
  Company_Name_Label: "School Name",
  Company_URL_Label: "School URL",
  Empty_URL_Error: "Please enter the URL of your school",
  Invalid_URL_Error: "Please enter valid URL",
};

export const AvatarLinks = {
  Profile_Link: "Profile",
  School_Settings_Text: "School Settings",
  Super_Admin_Text: "Super Admin",
  Log_Out_Text: " Log out",
};

export const SiderLinks = {
  Home_Link: "Home",
  Research_Link: "Research",
  Dashboard_Link: "Usage Analytics",
  Help_Link: "Help",
  Configure_Data: "Configure Data",
  Manage_Files_Link: "Manage Files",
  Manage_Datasets: "Manage Datasets",
};

export const ManageFiles_Constants = {
  ManageFiles_Title: "Manage Files",
  Upload_Tab: {
    Drag_And_Drop: "Drag & Drop or",
    Upload_Url_Text: "Or upload from a URL",
    Google_Sheet_Upload_Url: "Google Sheet Url",
    Refresh_Interval_Modal: {
      Repeat_Every_Txt: "Repeat every",
      Repeat_On_Txt: "Repeat on",
      Refresh_Interval_Title: "Refresh Interval Period",
      Ends_Txt: "Ends",
      Never_Txt: "Never",
      On_Txt: "On",
      After_Txt: "After",
    },
    Email_Share_Txt: "You need to share your Google Sheet at the",
    Email_Share_Txt_Part2: "mentioned email address",
    Email_Share_Part_One_Txt: "Copy Email",
  },
  Recent_Tab: {
    Refresh_Interval_Success_Modal_Text:
      "Refresh interval period has successfully set.",
    Edit_Refresh_Interval_Text: "Edit Refresh Interval",
    Archieve_Text: "Archive",
    Uploading_Files_Text: "Uploading Files",
    Uploaded_Files_Text: "Uploaded Files",
    Cancelled_Failed_Files_Text: "Cancelled/Failed Files",
    Files_Preview_Text: "Files Preview",
    Top_Record_Text: "Top 100 records of the file",
    Table_Title: {
      File_Name_Title: "File Name",
    },
  },
  UploadedMultiWorksheetText_Part1: "Your spreadsheet has multiple sheets.",
  UploadedMultiWorksheetText_Part2:
    "Please select the worksheet which you want to upload.",
  UploadedRefreshIntervalText:
    "A refresh interval keeps your maps in sync with the latest data",
  Skip_For_Now_Text_Part1: "Skip",
  Skip_For_Now_Text_Part2: "for now",
  Configure_Table_Text: "Configure Table",
  Set_Refresh_Interval: "Set Refresh Interval",
  Uploaded_Sheet_Text:
    "Uploaded Sheet is not a Google Sheet. Please follow the steps to move ahead",
  Save_as_google_Text: "Save as Google sheet",
  Google_Sheet_One_Text: " 1. On your computer, open a Google Sheet.",
  Google_Sheet_Two_Text: " 2. In the menu, click File. Save as Google Sheet.",
  Google_Sheet_Three_Text: " 3. Type a name and choose where to save it.",
  Google_Sheet_Four_Text: " 4. Click OK.",
};

export const ManageDataset_Constants = {
  Manage_Datasets_Title: "Manage Datasets",
  Process_Text: "This process will take up to 10 mins",
  Configure_Table_Preview_Text: "Configure Table Preview",
  Set_Col_Text: "Set Column as a unique ID",
};

export const themeColors = {
  PRIMARY_COLOR: "#ffc90a",
  BLACK_COLOR: "#000000",
};

export const paymentTypes = {
  ACH: "ACH",
  CC: "CREDIT CARD",
  FREE: "Free",
};

export const commonLinks = {
  Privacy_Policy_Link: "https://schoolbi.com/privacy-policy/",
  Terms_Conditions_Link: "https://schoolbi.com/mainserviceagreement/",
};

export const copyableMail =
  "schoolbi-custom-data@schoolbi-custom-data.iam.gserviceaccount.com";

export const contactMail = "contact@schoolbi.com";

export const notificationModule = {
  Notifications_Text: "Notifications",
  Set_Preferences_Text: "Set Preferences",
  Push_Text: "Push",
  In_App_Text: "In App",
  Notification_Title: "Set Notification Preferences",
  Notification_Subtitle:
    "Select the kinds of Notifications you get about your activities and recommendations.",
  Mark_all_as_read: "Mark All As Read",
};

export const dashboardModule = {
  Dashboard_Title: "Usage Analytics",
  Recurring_Title: "Recurring Refreshes",
  Dashboard_Status_Title: "Dashboard is Connected to Data Provider",
  Refresh_Recurring_Title: "Refresh Recurring",
  No_Data_Found: " No data found!",
  No_Worksheet_Text:
    "Please upload a custom file to analysis data and based on it will graph is visible.",
  No_Data_Source:
    "Please connect to data source and based on it will graph is visible.",
  No_Api_Calls:
    "Please create reports in data connectors and based on it will graph is visible",
  Quota_Title: "Quota Limit for API Key for",
  Quota_Text: "API calls and interval for Key1 and Key2",
};

export const advancedListText = {
  Manage_Advanced_List: "Manage Advanced Lists",
  View_Data_List: "View the data of the data sets as below",
  Advanced_List_Model_List:
    "The selected advanced List has multiple Files. Please Enter the File ID which you want to use into the platform.",
  File_Id_List: "Select a file",
  Enter_Here_List: "Enter Here",
  Upload_Failed_List: "Upload Failed",
  In_Progress_Text: "In Progress",
  Failed_Text: "Failed",
};
