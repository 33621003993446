//** Aws Amplify Imports */
import { API } from "aws-amplify";
//** Utility Imports */
import {
  apiExecuter,
  getEnv,
  GraphqlOperationExecutor,
} from "utility/commonMethods";
import axios from "axios";
import { createAdvancedListDetail, updateDataSetup } from "graphql/mutations";
import { listAdvancedListDetails, listDataSetups } from "graphql/queries";

const env = getEnv();

const baseUrl = {
  prod: "https://493c9anhug.execute-api.us-east-1.amazonaws.com/stage/",
  stage: "https://w6ybngm0ni.execute-api.us-east-1.amazonaws.com/stagging/",
  stagging: "https://w6ybngm0ni.execute-api.us-east-1.amazonaws.com/stagging/",
  production:
    "https://tum3lz5jzl.execute-api.us-east-1.amazonaws.com/production/",
};

const getAdvancedListByAccountId = async (accountId) =>
  apiExecuter(async () => {
    const url = `${baseUrl[env]}advancedlist/getlist?account=${accountId}`;
    const {
      data: { body },
    } = await axios.get(url);
    return body;
  });

const createAdvancedList = async (input) => {
  const queryData = {
    query: createAdvancedListDetail,
    body: {
      input,
    },
  };

  return await GraphqlOperationExecutor(queryData);
};
const getAllAdvancedList = async (accountId) => {
  const queryData = {
    query: listAdvancedListDetails,
    body: {
      filter: { school_id: { eq: accountId } },
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const addAdvancedList = async (body) => {
  const url = `${baseUrl[env]}advancedlist/addlist`;

  try {
    const data = await fetch(url, {
      body: JSON.stringify(body),
      method: "POST",
    });

    return data;
  } catch (e) {
    return false;
  }
};

const getAdvancedListFields = async ({ accountId, data_set }) => {
  const queryData = {
    query: listDataSetups,
    body: {
      limit: 999,
      filter: {
        // school_id: { eq: accountId },
        data_set: {
          eq: data_set,
        },
      },
    },
  };

  if (accountId) {
    queryData["body"]["filter"]["school_id"] = { eq: accountId };
  }

  return await GraphqlOperationExecutor(queryData);
};

const updateAdvancedListDetails = async (input) => {
  const queryData = {
    query: updateDataSetup,
    body: {
      input,
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const getPreviewData = async (body) => {
  return await API.post("schoolBIREST", "/dataset/getadvancedlistdata", {
    body: body,
  });
};

const deleteAdvandedList = async (body) => {
  // const url = `${baseUrl[env]}advancedlist/delete`;

  // try {
  //   const data = await fetch(url, {
  //     body: JSON.stringify(body),
  //     method: "POST",
  //   });

  //   return data;
  // } catch (e) {
  //   return false;
  // }
  return await API.post("schoolBIREST", "/advancedlist/delete", {
    body: body,
  });
};

const AdvacnedListSDK = {
  getAdvancedListByAccountId,
  createAdvancedList,
  getAllAdvancedList,
  addAdvancedList,
  getAdvancedListFields,
  updateAdvancedListDetails,
  getPreviewData,
  deleteAdvandedList,
};

export default AdvacnedListSDK;
