/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://jrpz7agd6raqrbyedk57cymfpy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "schoolBIREST",
            "endpoint": "https://eyce6rub36.execute-api.us-east-1.amazonaws.com/stagging",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:6a062bb8-144e-4562-9223-010b7bd8c7bd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ZgBzAWgES",
    "aws_user_pools_web_client_id": "1udn74hmklc17m08qriaha7gjb",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_content_delivery_bucket": "schoolb-20241004155220-hostingbucket-stagging",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dxcmwped4u84g.cloudfront.net",
    "aws_user_files_s3_bucket": "schoolbicontentf7d94-stagging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
