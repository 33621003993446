//** React Imports */
import React, { useState, useEffect } from "react";
//** Ant Design Imports */
import { Row, Col, Input, Form, Button, message, Typography } from "antd";
//** React Router Imports */
import { useHistory } from "react-router-dom";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** Layout Imports */
import Footer from "Common/Layouts/Footer";
import LoginModuleLogo from "Common/Components/LoginModuleLogo/LoginModuleLogo";
import Background from "Common/Layouts/AuthColOne";
//** React Otp Imports */
import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";
import {
  buttonText,
  formError,
  messageText,
  MiscellaneousText,
} from "utility/constants";
import Title from "Common/Components/CustomAntD/Title";

/**
 * Confirm Email Component
 */
function ConfirmSingupEmail(props) {
  //** Destructing Hooks */
  const history = useHistory();
  const { t } = useTranslation();

  //** Destructing Ant Components */
  const { Text } = Typography;

  //** Destructing Constants */
  const { Confirm_Email_Text, Enter_Code_Text } = MiscellaneousText;
  const { isEmailEmpty, isCodeEmpty } = formError;
  const { Confirm_Email_Btn, Resend_Code_Btn } = buttonText;

  //** Destructing Constants */
  const {
    New_Confirmation_Code_Message,
    Sign_In_Account_Message,
    Something_Went_Wrong_Message,
    Already_Verified_Message,
  } = messageText;

  //** Ant Design Form */
  const [form] = Form.useForm();
  //** Use States */
  const [loading, setloading] = useState(false);
  const [email] = useState(props.location.state?.userName);
  const [otp, setOtp] = useState();

  //** For handling if confirmation code presents in url or not */
  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/confirm_email")
      ) {
        let Data = {};
        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        Data.email = window.location.href.split("email=")[1];
        form.setFieldsValue({
          code: Data.code,
          email: Data.email,
        });
        handleConfirmSignUp(form.getFieldsValue(["email", "code"]));
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //** Resending authentication code */
  const handleResendCode = async () => {
    try {
      await Auth.resendSignUp(email);
      message.success(t(New_Confirmation_Code_Message));
      setloading(false);
    } catch (error) {
      console.log("error signing up:", error);
      setloading(false);
    }
  };

  //** Handles signup if code is valid */
  const handleConfirmSignUp = async (e) => {
    try {
      if (e.email && e.code) {
        setloading(true);
        await Auth.confirmSignUp(e.email, e.code);
        setloading(false);
        message.success(t(Sign_In_Account_Message));
        history.push("/signin");
      } else {
        message.error(t(Something_Went_Wrong_Message));
      }
    } catch (error) {
      if (
        error.message.includes(
          "User cannot be confirmed. Current status is CONFIRMED"
        )
      ) {
        history.push("/");
        // message.success(t(Already_Verified_Message));
      }
      console.log("error signing up:", error);
      message.error(error.message);
      setloading(false);
    }
  };

  /** Otp Changes  */
  const handleChange = (otp) => {
    setOtp(otp);
  };

  return (
    <Row justify="center">
      <Background />

      <Col span={12} md={12} lg={12} xs={22}>
        <Row justify="center" align="middle" className="login-module-col-2">
          <Col xs={24} sm={24} md={24} lg={18} xl={12} xxl={12}>
            <LoginModuleLogo />

            <Form
              form={form}
              layout="vertical"
              name="normal_signup"
              requiredMark={false}
              onFinish={(e) => handleConfirmSignUp(e)}
            >
              <Row justify="center">
                <Title level={5}>{t(Confirm_Email_Text)}</Title>
                <Text className="confirm-email-paragraph mb-3">
                  {t(Enter_Code_Text)}
                </Text>
              </Row>

              <Form.Item
                initialValue={email}
                label="Email"
                name="email"
                className="font-bold"
                rules={[{ required: true, message: t(isEmailEmpty) }]}
              >
                <Input
                  value={email}
                  id="email"
                  key="email"
                  name="email"
                  placeholder="Email"
                  disabled={localStorage.getItem("User")}
                />
              </Form.Item>
              <Form.Item
                className="otp"
                name="code"
                rules={[
                  {
                    required: true,
                    message: t(isCodeEmpty),
                  },
                ]}
              >
                <OtpInput
                  name="code"
                  id="code"
                  key="code"
                  shouldAutoFocus
                  className="otp-input"
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  separator={<span className="color-white"> ---</span>}
                />
              </Form.Item>

              <Form.Item>
                <Row justify="space-between" align="middle" gutter={24}>
                  <Col span={12}>
                    <Button
                      type="primary"
                      size="large"
                      block
                      ghost
                      onClick={handleResendCode}
                    >
                      {t(Resend_Code_Btn)}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      size="large"
                      htmlType="submit"
                      block
                      tabIndex={5}
                      type="primary"
                      loading={loading}
                      className="w-100"
                    >
                      {t(Confirm_Email_Btn)}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
          <Footer />
        </Row>
      </Col>
    </Row>
  );
}
export default ConfirmSingupEmail;
