import { API } from "aws-amplify";
import {
  apiExecuter,
  GraphqlOperationExecutor,
  sortDatabyDate,
} from "utility/commonMethods";
import {
  listApiKeys,
  listRecurringSyncs,
  listCountOfAPIHistorys,
} from "graphql/queries";

const getBlackbaudRecurringChartData = async (
  accountId,
  dateRange,
  syncFor = "BlackbaudData"
) => {
  const queryData = {
    query: listRecurringSyncs,
    body: {
      limit: 999,
      filter: {
        sync_for: { eq: syncFor },
        school_id: { eq: accountId },
        createdAt: { gt: dateRange[0] },
        and: {
          createdAt: { le: dateRange[1] },
        },
      },
    },
  };

  const data = await GraphqlOperationExecutor(queryData);

  const sortedData = sortDatabyDate(data);

  const Day = sortedData.map((item) => {
    return {
      legend: item.success ? "Success" : "Failure",
      value: 1,
      type: item.createdAt.split("T")[0],
    };
  });
  const SortByWeek = sortedData.map((item) => {
    const date = new Date(item.createdAt);
    const week = Math.floor(date.getDate() / 7) + 1;
    const month = date.toLocaleString("default", { month: "long" });
    const weekValue = `Week ${week} ${month}`;
    return {
      type: weekValue,
      legend: item.success ? "Success" : "Failure",
      value: 1,
    };
  });

  const sumSameLegend = (input) => {
    const counts = {};

    for (const item of input) {
      const { legend, value, type } = item;
      const key = `${legend}-${type}`;
      if (counts.hasOwnProperty(key)) {
        counts[key].value += value;
      } else {
        counts[key] = { legend, value, type };
      }
    }

    return Object.values(counts);
  };

  const Week = sumSameLegend(SortByWeek);

  const SortedByMonth = sortedData.map((item) => {
    return {
      type: new Date(item.createdAt).toLocaleString("default", {
        month: "long",
      }),
      legend: item.success ? "Success" : "Failure",
      value: 1,
    };
  });

  const Month = sumSameLegend(SortedByMonth);

  return { Day, Week, Month };
};

const getRecurringRefreshChartData = async (accountId) => {
  const queryData = {
    query: listRecurringSyncs,
    body: {
      limit: 999,
      filter: {
        sync_for: { eq: "CustomData" },
        school_id: { eq: accountId },
      },
    },
  };

  const data = await GraphqlOperationExecutor(queryData);

  const getFilterData = (key) => {
    return data
      .filter((item) => item.interval_type === key)
      .reduce((accumulator, item) => {
        const { worksheet_id, success, worksheet_name, createdAt } = item;
        const createdAtDate = new Date(createdAt);
        const currentDate = new Date();
        const daysDifference = Math.floor(
          (currentDate - createdAtDate) / (1000 * 60 * 60 * 24)
        ); // Calculate the difference in days

        const daysToCheck = [7, 30, 90]; // Define the days to check

        daysToCheck.forEach((days) => {
          if (daysDifference <= days) {
            const key = `days${days}`;
            if (!accumulator.hasOwnProperty(worksheet_id)) {
              accumulator[worksheet_id] = {
                [key]: { successCount: 0, failureCount: 0, worksheet_name },
              };
            } else if (!accumulator[worksheet_id].hasOwnProperty(key)) {
              accumulator[worksheet_id][key] = {
                successCount: 0,
                failureCount: 0,
                worksheet_name,
              };
            }

            if (success) {
              accumulator[worksheet_id][key].successCount++;
            } else {
              accumulator[worksheet_id][key].failureCount++;
            }
          }
        });
        return accumulator;
      }, {});
  };

  const Day = getFilterData("DAILY");
  const Week = getFilterData("WEEKLY");
  const Month = getFilterData("MONTHLY");

  return {
    Day,
    Week,
    Month,
  };
};

const getApiQuota = (accountId) =>
  apiExecuter(async () => {
    const queryData = {
      query: listApiKeys,
      body: {
        filter: { account: { eq: accountId } },
      },
    };

    const apiKeys = await GraphqlOperationExecutor(queryData);

    return await Promise.all(
      apiKeys.map(async (item) => {
        const { keyid } = item;
        const url = "/dashboard/apikey";
        const body = {
          keyId: keyid,
        };
        return await API.post("schoolBIREST", url, { body });
      })
    );
  });

const getApiCallsChartData = async (accountId, dateRange) => {
  const queryData = {
    query: listCountOfAPIHistorys,
    body: {
      limit: 999,
      filter: {
        school_id: { eq: accountId },
        createdAt: { gt: dateRange[0] },
        and: {
          createdAt: { le: dateRange[1] },
        },
      },
    },
  };

  const data = await GraphqlOperationExecutor(queryData);

  const sortedData = sortDatabyDate(data);

  const Day = sortedData.map((item) => {
    return {
      time: item.createdAt.split("T")[0],
      dataset: item.dataset_type,
      volume: item.count,
      apiKey: item.api_key.split("-")[0],
    };
  });

  const Week = sortedData.map((item) => {
    const date = new Date(item.createdAt);
    const week = Math.floor(date.getDate() / 7) + 1;
    const month = date.toLocaleString("default", { month: "long" });
    const weekValue = `Week ${week} ${month}`;
    return {
      time: weekValue,
      dataset: item.dataset_type,
      volume: item.count,
      apiKey: item.api_key.split("-")[0],
    };
  });

  const Month = sortedData.map((item) => {
    const date = new Date(item.createdAt);
    const month = date.toLocaleString("default", { month: "long" });

    return {
      time: month,
      dataset: item.dataset_type,
      volume: item.count,
      apiKey: item.api_key.split("-")[0],
    };
  });

  return {
    Day,
    Week,
    Month,
  };
};

const DashboardSDK = {
  getApiQuota,
  getBlackbaudRecurringChartData,
  getRecurringRefreshChartData,
  getApiCallsChartData,
};
export default DashboardSDK;
