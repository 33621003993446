import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import settingReducer from "./settingReducer";

const appReducer = combineReducers({
  loginReducer,
  settingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
