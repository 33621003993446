import React from "react";
import { Space, Skeleton } from "antd";

const SkeletonTable = () => {
  return (
    <>
      <Space className="w-100" direction="horizontal">
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={0} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={1} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={2} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={3} />
      </Space>
      <Space
        style={{ width: "100%", marginTop: "25px" }}
        direction="horizontal"
      >
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={0} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={1} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={2} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={3} />
      </Space>
      <Space
        style={{ width: "100%", marginTop: "25px" }}
        direction="horizontal"
      >
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={0} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={1} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={2} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={3} />
      </Space>
      <Space
        style={{ width: "100%", marginTop: "25px" }}
        direction="horizontal"
      >
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={0} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={1} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={2} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={3} />
      </Space>
      <Space
        style={{ width: "100%", marginTop: "25px" }}
        direction="horizontal"
      >
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={0} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={1} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={2} />
        <Skeleton.Input active style={{ width: "15vw" }} size="large" key={3} />
      </Space>
    </>
  );
};

export default SkeletonTable;
