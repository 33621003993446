import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Tabs, Typography } from "antd";
import { ManageDataset_Constants } from "utility/constants";
// import "./ManageDatasets.less";
import _ from "lodash";
import { Pages } from "Pages/Pages";

const ManageDatasetsAndRuleEngine = () => {
  const { Data_Management_Module, Rule_Engine_Module } = Pages;

  const { Data_Provider, Configure_Module } = Data_Management_Module;

  const { Advanced_List, DataSet } = Configure_Module;
  const { RuleEngine, AddNewRuleEngine } = Rule_Engine_Module;

  const { Title } = Typography;
  const { t } = useTranslation();

  const { Manage_Datasets_Title } = ManageDataset_Constants;

  const { TabPane } = Tabs;

  const tabPaneData = [
    {
      key: "data-source",
      tab: "Data Sources",
      component: <DataSet />,
    },
    {
      key: "advanced-list",
      tab: "Rules And Policies",
      component: <RuleEngine />,
    },
  ];

  return (
    <Row className="manage-files-container manage-dataset-container">
      <Col span={24}>
        <Title level={4} className="manage-files-heading">
          {/* {t(Manage_Datasets_Title)} */}
          Manage Datasets/Rules and Policies
        </Title>

        <Tabs
          defaultActiveKey={"upload-files"}
          tabPosition="top"
          className="school-details organization w-100 "
          // activeKey={currentActiveKey}
          // onChange={(e) => setCurrentActiveKey(e)}
        >
          {tabPaneData.map((tabPaneItem) => (
            <TabPane tab={tabPaneItem?.tab} key={tabPaneItem?.key}>
              {tabPaneItem?.component}
            </TabPane>
          ))}
        </Tabs>
      </Col>
    </Row>
  );
};

export default ManageDatasetsAndRuleEngine;
