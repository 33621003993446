//** React Imports */
import React, { useState } from "react";
//** Ant Design Imports */
import { Row, Col, Input, Form, Checkbox, Button, Alert } from "antd";
//** Constants Imports */
import {
  buttonText,
  commonLinks,
  emailPattern,
  formError,
  formLabel,
} from "utility/constants";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** Layout Imports */
import Background from "Common/Layouts/AuthColOne";
import Footer from "Common/Layouts/Footer";
import LoginModuleLogo from "Common/Components/LoginModuleLogo/LoginModuleLogo";
//** Third Party Imports */
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setFamilyName, setGivenName } from "store/actions/loginAction";
import { Typography } from "antd";

/**
 * Handles Sing Up of the user
 */
const CreateAccount = (props) => {
  const { t } = useTranslation();

  const {
    isFirstNameEmpty,
    isFieldInvalid,
    isFieldEmpty,
    isEmailEmpty,
    isEmailInvalid,
    isPasswordEmpty,
    isPasswordMin,
    isTAndCInvalid,
    isLastNameEmpty,
  } = formError;

  const {
    firstName,
    First_Name_Placeholder,
    lastName,
    Last_Name_Placeholder,
    Email_Label,
    emailText,
    Create_Password_Label,
    Password_Label,
    TAndC_Label,
    TAndC_Label_P2,
    Privacy_Policy_P1,
    Privacy_Policy_P2,
  } = formLabel;

  const { Sign_Up_Text, Login_Text } = buttonText;

  const { Terms_Conditions_Link, Privacy_Policy_Link } = commonLinks;

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    error: "",
    loading: false,
    Tnc: false,
  };

  const dispatch = useDispatch();

  const [state, setState] = useState(initialValues);

  const { loading, Tnc, error } = state;
  const { history } = props;

  const handleSignUp = async (e) => {
    if (e.firstName.length <= 1 || e.lastName.length <= 1) {
      setState((pS) => ({ ...pS, error: t(isFieldEmpty) }));
    } else {
      setState((pS) => ({ ...pS, loading: true, error: "" }));

      try {
        await Auth.signUp({
          username: e.email,
          password: e.password,
          attributes: {
            email: e.email,
            given_name: e.firstName,
            family_name: e.lastName,
            "custom:mfa": "false",
          },
          // autoSignIn: {
          //   enabled: true,
          // },
        });

        dispatch(setGivenName(e.firstName));
        dispatch(setFamilyName(e.lastName));

        window?.freshpaint?.identify(e?.email, {
          email: e?.email,
          name: `${e?.firstName} ${e?.lastName}`,
        });
        setState((pS) => ({ ...pS, loading: false }));

        history.push({
          pathname: `/confirm_email`,
          state: { feedback: "", userName: e.email },
        });
        localStorage.setItem("User", window.btoa(e.email));
      } catch (error) {
        console.log("error signing up:", error);

        if (error?.code === "UsernameExistsException") {
          return history.push({
            pathname: `/confirm_email`,
            state: { feedback: "", userName: e.email },
          });
        }

        setState((pS) => ({
          ...pS,
          error: error.message,
          loading: false,
        }));
      }
    }
  };

  return (
    <Row justify="center">
      <Background />
      <Col span={12} md={12} lg={12} xs={22}>
        <Row
          gutter={24}
          justify="center"
          align="middle"
          className="login-module-col-2"
        >
          <Col xs={24} sm={24} md={24} lg={18} xl={12} xxl={12}>
            <LoginModuleLogo />

            {error && (
              <Alert
                className="mb-10"
                type="error"
                showIcon={false}
                message={error}
                banner
              />
            )}
            <Form
              requiredMark={false}
              layout="vertical"
              name="normal_login"
              // className="login-form"
              onFinish={(e) => handleSignUp(e)}
            >
              <Row justify="space-between" gutter={[10]}>
                <Col span={12}>
                  <Form.Item
                    label={t(firstName)}
                    className="font-bold"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: t(isFirstNameEmpty),
                      },
                      {
                        pattern: /^[a-zA-Z]+$/,
                        message: t(isFieldInvalid),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(First_Name_Placeholder)}
                      disabled={loading}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t(lastName)}
                    name="lastName"
                    className="font-bold"
                    rules={[
                      {
                        required: true,
                        message: t(isLastNameEmpty),
                      },
                      {
                        pattern: /^[a-zA-Z]+$/,
                        message: t(isFieldInvalid),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(Last_Name_Placeholder)}
                      disabled={loading}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={t(Email_Label)}
                name="email"
                className="font-bold"
                rules={[
                  { required: true, message: t(isEmailEmpty) },
                  {
                    pattern: emailPattern,
                    message: t(isEmailInvalid),
                  },
                ]}
              >
                <Input placeholder={t(emailText)} />
              </Form.Item>
              <Form.Item
                label={t(Create_Password_Label)}
                className="font-bold"
                name="password"
                rules={[
                  {
                    min: 8,
                    message: t(isPasswordMin),
                  },
                  { required: true, message: t(isPasswordEmpty) },
                ]}
              >
                <Input.Password
                  placeholder={t(Password_Label)}
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item
                name="tAndC"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error(t(isTAndCInvalid))),
                  },
                ]}
                className="font-bold mb-0"
              >
                <Checkbox
                  onChange={(e) =>
                    setState((pS) => ({ ...pS, Tnc: e.target.checked }))
                  }
                  value={Tnc}
                  className="fs-14"
                >
                  {t(TAndC_Label)}
                  <Typography.Link
                    href={Terms_Conditions_Link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    &nbsp; {t(TAndC_Label_P2)}
                  </Typography.Link>
                </Checkbox>
              </Form.Item>
              <Form.Item className="mt-1 lh-16">
                <Typography.Text className="fs-14">
                  {t(Privacy_Policy_P1)}
                </Typography.Text>
                <Typography.Link
                  href={Privacy_Policy_Link}
                  target="_blank"
                  rel="noreferrer"
                  className="fs-14 font-bold"
                >
                  &nbsp; {t(Privacy_Policy_P2)}
                </Typography.Link>
              </Form.Item>
              <Form.Item className="mt-5">
                <Row justify="space-between" align="middle" gutter={32}>
                  <Col span={12}>
                    <Button
                      size="large"
                      type="primary"
                      block
                      ghost
                      onClick={() => history.push("/signin")}
                      disabled={loading}
                    >
                      {t(Login_Text)}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      size="large"
                      htmlType="submit"
                      block
                      tabIndex={5}
                      type="primary"
                      loading={loading}
                    >
                      {t(Sign_Up_Text)}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
          <Footer />
        </Row>
      </Col>
    </Row>
  );
};

export default CreateAccount;
