import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { buttonText, ManageFiles_Constants } from "utility/constants";
import FilePreviewTable from "./FilePreviewTable";

const FilePreviewContainer = ({
  setFilePreviewTable,
  filePreviewTableColData,
  filePreviewTableData,
}) => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;

  const { Done_Btn } = buttonText;

  const {
    Files_Preview_Text,
    Top_Record_Text,
  } = ManageFiles_Constants?.Recent_Tab;

  return (
    <Col span={24} className="flex-col gap-3">
      <Row justify="space-between" align="middle">
        <Text className="fs-18 font-bold ">{t(Files_Preview_Text)} </Text>
        <Button
          icon={<CloseCircleFilled />}
          onClick={() => setFilePreviewTable(false)}
          className="file-close-btn"
        />
      </Row>
      <Row className="d-flex gap-3 flex-col ">
        <Col span={24}>
          <Row className="d-flex gap-3 flex-col">
            <Col span={24}>
              <Row className="file-preview-table-container flex-col ">
                <Col span={24}>
                  <Row
                    justify="space-between"
                    className="file-preview-table-header-wrapper"
                  >
                    {/* Dev Comment :-
                            <div className="file-preview-table-header d-flex gap-3 ">
                            <FilterFilled className="fs-24 filter-icon " />
                            <Input
                              addonBefore={<SearchOutlined />}
                              placeholder="Search..."
                              className="filter-search-input"
                             />
                            </div> 
                    */}
                    <div>
                      <Title className="fs-18">{t(Top_Record_Text)}</Title>
                    </div>
                    {/* <div className="d-flex">
                               <Button
                        className="secondary-btn"
                        onClick={() => setConfigureTableModal(true)}
                      >
                        Configure Table
                      </Button> 
                      <Popover
                        trigger={"click"}
                        placement="bottomRight"
                        content={
                          <TablePopOverContent
                            setFilePopoverVisible={setFilePopoverVisible}
                            filePreviewTableColData={filePreviewTableColData}
                            setSelectedColumns={setSelectedColumns}
                          />
                        }
                        className="file-preview-popover"
                        visible={filePopoverVisible}
                      >
                        <Button
                          type="primary"
                          onClick={() => setFilePopoverVisible(true)}
                        >
                          Set Column As Unique Id
                        </Button>
                      </Popover> 
                    </div> */}
                  </Row>
                  <Row>
                    <Col span={24}>
                      <FilePreviewTable
                        filePreviewTableColData={filePreviewTableColData}
                        filePreviewTableData={filePreviewTableData}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="start" className="gap-3">
            {/* Dev Comment :-
                <Button type="primary" className="black-btn px-3">
                {t(Cancel)}
                </Button>
            */}
            <Button
              type="primary"
              onClick={() => {
                // if (isEqual(initialRepeatIntervalFormValue, repeatIntervalForm))
                //   setRefreshIntervalNotSetModal(true);
                setFilePreviewTable(false);
              }}
            >
              {t(Done_Btn)}
            </Button>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default FilePreviewContainer;
