import {
  Row,
  Radio,
  Form,
  InputNumber,
  Select,
  Checkbox,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ManageFiles_Constants } from "utility/constants";

const RefreshIntervalSupport = ({
  setRepeatIntervalForm,
  repeatIntervalForm,
  type,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { Refresh_Interval_Modal } = ManageFiles_Constants?.Upload_Tab;

  const { Repeat_Every_Txt, Repeat_On_Txt } = Refresh_Interval_Modal;

  const initalOptionCustomForm = [
    {
      label: "Daily",
      value: "DAILY",
    },
    {
      label: "Weekly",
      value: "WEEKLY",
    },
    {
      label: "Monthly",
      value: "MONTHLY",
    },
    {
      label: "Yearly",
      value: "YEARLY",
    },
    {
      label: "Custom",
      value: "CUSTOM",
    },
  ];

  const [optionsForCustomForm, setOptionsForCustomForm] = useState(
    initalOptionCustomForm
  );

  const dayOptions = [
    {
      label: "Day",
      value: "DAY",
    },
    {
      label: "Week",
      value: "WEEK",
    },
    {
      label: "Month",
      value: "MONTH",
    },
    {
      label: "Year",
      value: "YEAR",
    },
  ];

  const [renderPhase, setRenderPhase] = useState("phaseZero");

  const refreshIntervalRadioHandlers = (e) => {
    const { value } = e.target;
    if (value === "CUSTOM") {
      // setRenderPhase("phaseOne")
      setRepeatIntervalForm((pS) => ({
        ...pS,
        custom_interval: 1,
        custom_type: "DAY",
        custom_value: 0,
      }));
    }
    setRepeatIntervalForm((pS) => ({ ...pS, interval_type: value }));
  };

  const handleRefreshInterval = (e) => {
    const key = Object.keys(e)[0];

    switch (key) {
      case "repeatDayMonth":
        const date = new Date(e[key].format("YYYY-MM-DD")).getDate();
        setRepeatIntervalForm((pS) => ({ ...pS, custom_value: date }));
        break;
      case "repeatDayWeek":
        const week = e[key][0];
        setRepeatIntervalForm((pS) => ({ ...pS, custom_value: week }));
        break;
      default:
        setRepeatIntervalForm((pS) => ({ ...pS, ...e }));
    }
  };

  useEffect(() => {
    if (repeatIntervalForm?.interval_type === "CUSTOM") {
      setRenderPhase("phaseOne");
    } else {
      setRenderPhase("phaseZero");
    }
  }, [repeatIntervalForm?.interval_type]);

  useEffect(() => {
    if (type === "add") {
      setOptionsForCustomForm((pS) => [
        {
          label: "None",
          value: "NONE",
        },
        ...pS,
      ]);
    } else {
      setOptionsForCustomForm(initalOptionCustomForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      {renderPhase === "phaseZero" ? (
        <Row className={type === "edit" ? "w-100" : ""} justify="middle">
          <Radio.Group
            options={optionsForCustomForm}
            onChange={refreshIntervalRadioHandlers}
            value={repeatIntervalForm?.interval_type}
            optionType="button"
            buttonStyle="solid"
            className="refresh-interval-radio-btn"
          />
        </Row>
      ) : null}

      {renderPhase === "phaseOne" ? (
        <Form
          form={form}
          layout="vertical"
          name="refreshIntervalForm"
          className="signup-form flex-col gap-3"
          requiredMark={false}
          onValuesChange={(e) => handleRefreshInterval(e)}
          initialValues={{
            custom_interval: "1",
            custom_type: "day",
          }}
        >
          <Form.Item
            label={<span className="fs-16">{t(Repeat_Every_Txt)} </span>}
            className="font-bold d-flex flex-row gap-3 "
          >
            <Row className="gap-05">
              <Form.Item name="custom_interval">
                <InputNumber className="repeat-number-style" />
              </Form.Item>

              <Form.Item name="custom_type">
                <Select
                  options={dayOptions}
                  className="repeat-day-style w-100px"
                />
              </Form.Item>
            </Row>
          </Form.Item>

          {repeatIntervalForm?.custom_type === "week" ? (
            <Form.Item
              label={<span className="fs-16">{t(Repeat_On_Txt)} </span>}
              className="font-bold weekend-checkbox "
              name="repeatDayWeek"
            >
              <Checkbox.Group>
                <Checkbox value={"7"} className="sunday"></Checkbox>
                <Checkbox value={"1"} className="monday"></Checkbox>
                <Checkbox value={"2"} className="tuesday"></Checkbox>
                <Checkbox value={"3"} className="wednesday"></Checkbox>
                <Checkbox value={"4"} className="thursday"></Checkbox>
                <Checkbox value={"5"} className="friday"></Checkbox>
                <Checkbox value={"6"} className="saturday"></Checkbox>
              </Checkbox.Group>
            </Form.Item>
          ) : null}

          {repeatIntervalForm?.custom_type === "month" ? (
            <Form.Item
              className="font-bold d-flex flex-row gap-3"
              name="repeatDayMonth"
              label={
                <span className="fs-16 visibility-hidden ">
                  {t(Repeat_Every_Txt)}
                </span>
              }
            >
              <DatePicker className="repeat-date-picker" mode="date" />
            </Form.Item>
          ) : null}

          {/* Dev Comment :-
          // Required for ending refresh interval
          <Form.Item
            label={<span className="fs-16"> {t(Ends_Txt)} </span>}
            name="repeatEnd"
            className="font-bold d-flex gap-3 "
          >
            <Radio.Group className="flex-col gap-05 " defaultValue="never">
              <Radio value="never">
                <div className="d-flex justify-content-between ">
                  <Col span={12}>{t(Never_Txt)} </Col>
                  <Col span={12} className="visibility-hidden">
                    <Form.Item>
                      <DatePicker />
                    </Form.Item>
                  </Col>
                </div>
              </Radio>
              <Radio value="on">
                <div className="d-flex justify-content-between ">
                  <Row>
                    <Col span={8}> {t(On_Txt)} &nbsp;&nbsp;&nbsp; </Col>
                    <Col span={16}>
                      <Form.Item name="repeatEndOnDate">
                        <DatePicker
                          className="repeat-date-picker w-175"
                          disabled={repeatIntervalForm?.repeatEnd !== "on"}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Radio>
              <Radio value="after">
                <div className="d-flex justify-content-between ">
                  <Row>
                    <Col span={8}> {t(After_Txt)} </Col>
                    <Col span={16}>
                      <Form.Item name="repeatEndAfterOccurrences">
                        <InputNumber
                          initialValues={"30"}
                          addonAfter={"occurrences"}
                          disabled={repeatIntervalForm?.repeatEnd !== "after"}
                          className="repeat-occurrences w-175"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Radio>
            </Radio.Group>
          </Form.Item> */}
        </Form>
      ) : null}
    </>
  );
};

export default RefreshIntervalSupport;
