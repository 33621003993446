import { API } from "aws-amplify";
import { getUserCustom } from "graphql/customQueries";
import { listCsvDetails } from "graphql/queries";
import { apiExecuter, GraphqlOperationExecutor } from "utility/commonMethods";

const getUsersCustom = async (body) => {
  const queryData = {
    query: getUserCustom,
    body,
  };
  const data = await GraphqlOperationExecutor(queryData);
  console.log(data)
  return data?.users?.map((item) => JSON.parse(item))
};

const getCSVs = async (body) => {
  const queryData = {
    query: listCsvDetails,
    body,
  };
  return GraphqlOperationExecutor(queryData);
};

const deleteCSVs = async (body) => {
  return apiExecuter(async () => {
    const url = "/csv/deletefile";
    return await API.post("schoolBIREST", url, { body: body });
  });
};

const getCSVPreview = async (body) => {
  return apiExecuter(async () => {
    const url = "/csv/previewdata";
    return await API.post("schoolBIREST", url, { body: body });
  });
};

const CSVFilesSDK = {
  getUsersCustom,
  getCSVs,
  deleteCSVs,
  getCSVPreview
};

export default CSVFilesSDK;
