import React, { useEffect, useState } from "react";
import CustomModal from "../CustomAntD/Modal";
import { Col, Image, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import DisableIntgration from "assets/images/Modals/DisableIntegration.svg";
import { useDispatch } from "react-redux";
import { setCurrentConfigRenderKey } from "store/actions/settingAction";

const NotPurchasedModal = ({ isOpen,setIsOpen, text }) => {
  const dispatch = useDispatch();

  const handleRedirect = () => {
    dispatch(setCurrentConfigRenderKey("rule"));
    setIsOpen(false)
  };

 

  return (
    <>
      <CustomModal
        visible={isOpen}
        onCancel={() => handleRedirect()}
        centered
        width={600}
        footer={null}
        closable={true}
      >
        <Col span={24}>
          <Row justify="center" gutter={[24, 24]}>
            <Image
              src={DisableIntgration}
              height={150}
              width={200}
              preview={false}
            />
            <Typography.Title level={5} className="text-center">
              Please {text === "Advanced List" ? "select" : "enable"} "{text}"
              from &nbsp;<Link to={"/dataprovider"}>Integration</Link>
              &nbsp;to access this feature.
            </Typography.Title>
          </Row>
        </Col>
      </CustomModal>
    </>
  );
};

export default NotPurchasedModal;
