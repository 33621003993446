import {
  SET_INTEGRATIONS,
  CONNECTOR_COUNTER,
  SET_CURRENT_RENDER,
  SELECTED_CONNECTOR,
  SET_ALL_SUBSOURCES,
  SET_ISFROMSTRIPE,
  SET_CURRENT_RENDER_KEY,
  SET_CURRENT_CONFIGURE_RENDER_KEY,
  SET_MANAGE_FILES_VISIBILITY,
  SET_ADVANCED_LIST_VISIBILITY,
  SET_UNAUTHORIZED_VISIBILITY,
} from "./index";
import { SchoolBiApi } from "../../sdk/Api";
import _ from "lodash";

export const getIntegrations = () => async (dispatch, getState) => {
  const account = getState().loginReducer.groups;
  let list = [];
  let nextToken = null;
  do {
    try {
      let res = await SchoolBiApi.getIntegrationsApi(
        { account: { eq: account[0] } },
        nextToken
      );
      list.push(...res?.data?.listWebProfiless?.items);
      nextToken = res?.data?.listWebProfiless?.nextToken;
    } catch (err) {
      console.log(err);
    }
  } while (nextToken);

  let dataObj = {};
  // Dev Comment :-
  list.forEach((item) => {
    dataObj[_.capitalize(item?.platform)] = dataObj[
      _.capitalize(item?.platform)
    ]
      ? [...dataObj[_.capitalize(item?.platform)], item]
      : [item];
  });

  // Dev Comment :-
  // list.forEach((item) => {
  //   dataObj[item?.platform] = dataObj[item?.platform]
  //     ? [...dataObj[item?.platform], item]
  //     : [item];
  // });
  dispatch(setIntegrations(dataObj));
  return dataObj;
};

export const setIntegrations = (data) => {
  return {
    type: SET_INTEGRATIONS,
    payload: data,
  };
};

export const setConnectorCounterRedux = (data) => {
  return {
    type: CONNECTOR_COUNTER,
    payload: data,
  };
};

export const setCurrentRender = (data) => {
  return {
    type: SET_CURRENT_RENDER,
    payload: data,
  };
};

export const setSelectedConnector = (data) => {
  return {
    type: SELECTED_CONNECTOR,
    payload: data,
  };
};

export const setAllSubSources = (data) => {
  return {
    type: SET_ALL_SUBSOURCES,
    payload: data,
  };
};

export const setIsFromStripe = (data) => {
  return {
    type: SET_ISFROMSTRIPE,
    payload: data,
  };
};

export const setCurrentRenderKey = (data) => {
  return {
    type: SET_CURRENT_RENDER_KEY,
    payload: data,
  };
};

export const setCurrentConfigRenderKey = (data) => {
  return {
    type: SET_CURRENT_CONFIGURE_RENDER_KEY,
    payload: data,
  };
};

export const setManageFilesVisibility = (data) => {
  return {
    type: SET_MANAGE_FILES_VISIBILITY,
    payload: data,
  };
};

export const setAdvancedListVisibility = (data) => {
  return {
    type: SET_ADVANCED_LIST_VISIBILITY,
    payload: data,
  };
};

export const setUnauthorizedVisibility = (data) => {
  return {
    type: SET_UNAUTHORIZED_VISIBILITY,
    payload: data,
  };
};
