import { DeleteOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Row, Tag, Typography } from "antd";
import { Images } from "assets/images/Images";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  advancedListText,
  buttonText,
  ManageFiles_Constants,
} from "utility/constants";

const FileNameContainer = ({
  sheetName,
  workSheetName,
  date,
  type,
  tryAgainHandler,
  terminatefileHandler,
  deleteBtnHandler,
}) => {
  const { Title, Text } = Typography;
  const { ExcelSvg } = Images?.ManageFiles;

  const { t } = useTranslation();

  const { Upload_Failed_List } = advancedListText;
  const { Try_Again, Delete, Cancel_Uploading } = buttonText;
  const { Process_Text } = ManageFiles_Constants;

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        className="upload-file-container"
      >
        <Row>
          <Row>
            <img src={ExcelSvg} alt="ExcelSvg" />
          </Row>
          <Row className="flex-col" justify="center">
            <Title level={5} className="fs-18 mb-0 gap-05 d-flex">
              {sheetName}
              <Tag color="#0C1515" className="worksheet-container">
                {workSheetName}
              </Tag>
            </Title>
            <div className="d-flex flex-row ">
              <Text className="dove-color">
                {type && (
                  <span className="color-red">{t(Upload_Failed_List)} </span>
                )}
                {type && "|"} {date}
              </Text>
            </div>
          </Row>
        </Row>
        <Row className="gap-2">
          {/* <CheckCircleFilled className="primary-color fs-24 " />
          <CloseCircleOutlined className="fs-24 " /> */}
          {type === "failed" ? (
            <>
              <Button
                type="primary"
                icon={<UndoOutlined />}
                onClick={tryAgainHandler}
              >
                {t(Try_Again)}
              </Button>

              <Button
                type="primary"
                className="black-bg-btn"
                icon={<DeleteOutlined />}
                onClick={deleteBtnHandler}
              >
                {t(Delete)}
              </Button>
            </>
          ) : (
            <div className="flex-col align-items-end">
              <Title level={5} className="process-container mb-0">
                {t(Process_Text)}
              </Title>
              <Button
                type="link"
                onClick={terminatefileHandler}
                className="font-bold p-0 text-decoration-underline rounded-0"
              >
                {t(Cancel_Uploading)}
              </Button>
            </div>
          )}
        </Row>
      </Row>{" "}
    </>
  );
};

export default FileNameContainer;
