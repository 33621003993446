import { API } from "aws-amplify";
import { createVeracrossDetail } from "graphql/mutations";
import { listDatasources, listVeracrossDetails } from "graphql/queries";
import { apiExecuter, GraphqlOperationExecutor } from "utility/commonMethods";

const deleteUser = (body) =>
  apiExecuter(async () => {
    const url = "/admin/deleteuser";

    return await API.post("schoolBIREST", url, { body });
  });

const getDataSources = async (inputData) => {
  const queryData = {
    query: listDatasources,
    body: {
      input: inputData,
    },
  };

  const data = await GraphqlOperationExecutor(queryData);
  return data.sort((a, b) =>
    a?.data_source_name?.localeCompare(b?.data_source_name)
  );
};

const handlerDataProviderDisconnection = async (platform, account) =>
  await apiExecuter(async () => {
    const path = `/integrations/disconnect`;
    const body = {
      dataprovider: platform.toLowerCase(),
      account: account,
    };
    return await API.post("schoolBIREST", path, { body });
  });

const handlerDataProviderConnection = async (platform, accountId) =>
  await apiExecuter(async () => {
    const path = `/integrations/${platform.toLowerCase()}/auth?account=${accountId}`;
    await API.get("schoolBIREST", path);
  });

const addDataSources = async ({ datasources, account, userId }) =>
  await apiExecuter(async () => {
    const path = `/datasources/adddatasource`;
    await API.post("schoolBIREST", path, {
      body: {
        datasources,
        account,
        userId,
      },
    });
    await API.post("schoolBIREST", "/integrations/sync/details", {
      body: {
        datasources,
        account,
      },
    });
  });

const getVeracrossDetailsHandler = async (inputData) => {
  const { accountId } = inputData;
  const queryData = {
    query: listVeracrossDetails,
    body: {
      limit: 999,
      filter: {
        school_id: { eq: accountId },
      },
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const createVeracrossDetailsHandler = async (input) => {
  const queryData = {
    query: createVeracrossDetail,
    body: {
      input,
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const veracrossIntegrationHandler = async (body) =>
  await apiExecuter(async () => {
    const path = `/integrations/veracross/auth`;
    return await API.post("schoolBIREST", path, {
      body,
    });
  });

const ravennaIntegrationHandler = async ({ code, accountId }) =>
  await apiExecuter(async () => {
    const path = `/integrations/ravenna/auth?code=${code}&account=${accountId}`;
    return await API.get("schoolBIREST", path);
  });

export const IntegrationSDK = {
  deleteUser,
  getDataSources,
  addDataSources,
  handlerDataProviderDisconnection,
  handlerDataProviderConnection,
  getVeracrossDetailsHandler,
  createVeracrossDetailsHandler,
  veracrossIntegrationHandler,
  ravennaIntegrationHandler,
};
