import { CloseOutlined } from "@ant-design/icons";
import { Button, Row, Select, Table, Typography } from "antd";
import CustomModal from "Common/Components/CustomAntD/Modal";
import React from "react";
import { buttonText, ManageFiles_Constants } from "utility/constants";
import { useTranslation } from "react-i18next";

const ConfigureTableModal = ({
  isShowConfigureTable,
  setIsShowConfigureTable,
}) => {
  const { Title } = Typography;
  const { Save, Cancel } = buttonText;
  const { Configure_Table_Text } = ManageFiles_Constants;

  const { t } = useTranslation();

  const metricsAndDimensionsOptions = [
    {
      label: "Metrics",
      value: "metrics",
    },
    {
      label: "Dimensions",
      value: "dimensions",
    },
  ];

  const dataTypesOptions = [
    {
      label: "Boolean",
      value: "boolean",
    },
    {
      label: "Date",
      value: "date",
    },
    {
      label: "Number",
      value: "number",
    },
    {
      label: "Text",
      value: "text",
    },
  ];

  const configureTableData = [
    {
      title: "Columns Name",
      dataIndex: "colName",
      key: "colName",
      width: "30%",
      render: (item) => <span className="column-name-wrapper">{item}</span>,
    },
    {
      title: "Metrics/Dimension",
      dataIndex: "metricsAndDimension",
      key: "metricsAndDimension",
      width: "35%",
      render: () => (
        <Select
          options={metricsAndDimensionsOptions}
          defaultValue="metrics"
          // className="w-50 h-100 login-module-input"
        />
      ),
    },
    {
      title: "Data Types",
      dataIndex: "dataTypes",
      key: "dataTypes",
      width: "35%",
      render: () => (
        <Select
          options={dataTypesOptions}
          defaultValue="text"
          // className="w-50 h-100 login-module-input"
        />
      ),
    },
  ];

  const columns = [
    "srNo",
    "gender",
    "rAndN",
    "FA",
    "lsllAndlsi",
    "grade",
    "gradeTwo",
    "tuition",
    "facts",
    "studentId",
  ];

  const tableConfigureData = columns?.map((colItem) => {
    return {
      colName: colItem,
    };
  });
  return (
    <CustomModal
      visible={isShowConfigureTable}
      closable={true}
      centered
      onCancel={() => setIsShowConfigureTable(false)}
      footer={null}
      className="configure-table-modal "
      title={
        <Title className="configure-table-header">
          {" "}
          {t(Configure_Table_Text)}{" "}
        </Title>
      }
      width={"60%"}
      bodyStyle={{
        height: 480,
      }}
    >
      <Table
        columns={configureTableData}
        dataSource={tableConfigureData}
        pagination={false}
        className="configure-table-wrapper"
        scroll={{
          y: 300,
        }}
      />

      <Row className="mt-4">
        <Button
          className="secondary-btn"
          onClick={() => setIsShowConfigureTable(false)}
        >
          {t(Cancel)}
        </Button>
        <Button
          type="primary"
          className="px-3"
          onClick={() => setIsShowConfigureTable(false)}
        >
          {t(Save)}
        </Button>
      </Row>
    </CustomModal>
  );
};

export default ConfigureTableModal;
