//** React Imports */
import React, { useState, useEffect, useMemo } from "react";
//** Ant Imports */
import {
  Row,
  Col,
  Typography,
  Button,
  message,
  Table,
  Image,
  Radio,
  Slider,
  Checkbox,
} from "antd";
//** Ant Icons Imports */
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
//** Amplify Imports */
import { API, graphqlOperation } from "aws-amplify";
//** Action Imports */
import { getPlanList, setAccountDetails } from "store/actions/loginAction";
//** Stripe Imports */
import { loadStripe } from "@stripe/stripe-js";
//** SDK Imports */
import BillingSDK from "sdk/BillingSDK";
//** Custom GraphQL Imports */
import { getAccountCustom } from "graphql/customQueries";
import { onUpdateAccount } from "graphql/subscriptions";
//** Redux Action Imports */
import { setConnectorCounterRedux } from "store/actions/settingAction";
//** Component Imports */
import SchoolHeading from "./SchoolHeading";
import SuccessfulModal from "Common/Components/Modals/SuccessfulModal";
//** Assets Imports */
import { Images } from "assets/images/Images";
//** Utility Imports */
import { sortingOrder } from "utility/commonMethods";
import {
  billingPageText,
  buttonText,
  contactMail,
  messageText,
  MiscellaneousText,
  themeColors,
} from "utility/constants";
//** Hooks Imports */
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//** Css Imports */
import "./Billing.less";
import { capitalize, findKey } from "lodash";
import CustomModal from "Common/Components/CustomAntD/Modal";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";

/**
 * Handles rendering of Billing Design and Functionality
 * @returns {Billing Component}
 */
const Billing = () => {
  //** Destructing Hooks */
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //** Destructing Images */
  const {
    StarterPlanImg,
    ProPlanImg,
    PremiumPlanImg,
    PaymentCardImg,
    ManageBillingAdminAssign,
    PlanPurchasedImg,
  } = Images.Billing;

  //** Destructing Constants */
  const {
    Choose_Plan_Text,
    Select_Payment_Method_Text,
    Plan_Comes_With_Text,
    Data_Source_Increase_Text,
    Charge_Applicable_Text,
    Account_Link_Successful_Text,
    ACH_Text,
    Need_More_Connector_Text,
    Extra_Charge_Text,
    Needs_Attention_Text,
    Payment_Receieved_Text,
    Plan_Details_Text,
    Admin_Assigned_Part_1,
    Admin_Assigned_Part_2,
    Plan_Selected_Text,
    Subscription_Selected_Text,
    Go_To_Management_Text,
  } = billingPageText;
  const {
    Cancel,
    Yes_Sure_Text,
    Pay_Now_Text,
    Select_Plan_Btn,
    Upgrade_Plan_Btn,
    Manage_Billing_Btn,
    Link_Account_Btn,
    Assign_Btn,
  } = buttonText;
  const { Connectors_Text } = MiscellaneousText;

  const { Failed_to_update_plan_Message, Plan_Update_Success_Message } =
    messageText;

  //** Ant Component Destructing */
  const { Text, Title } = Typography;

  //** For table data */
  const initialColumns = [
    {
      title: "OVERVIEW",
      dataIndex: "col1",
      key: 1,
      width: "20%",
      render: (text, row, index) => {
        if (
          tableRowIndex.some((element) => element === text?.props?.children)
        ) {
          return {
            children: text,
            props: {
              // colSpan: 4,
            },
          };
        }
        return <span>{text}</span>;
      },
    },
  ];

  //** For Payment Method Option */
  const paymentMethodOptions = [
    {
      label: "Card",
      value: "cc",
    },
    {
      label: "ACH",
      value: "ach",
    },
  ];

  const { BLACK_COLOR } = themeColors;

  //** States for handling data */
  const [acct, setAcct] = useState(null); // Handles Account
  const [tableRowIndex, setTableRowIndex] = useState([]); // Handles Columns For Blue BG
  const [columnsData, setColumnsData] = useState(initialColumns); // Table Column Data
  const [billingTableData, setBillingTableData] = useState([]); // Whole Billing Data Table
  const [paymentType, setPaymentType] = useState("cc"); // Payment Type Modal
  const [linkToken, setLinkToken] = useState(""); // Plaid Link Token
  const [paymentData, setPaymentData] = useState({}); // Plaid Payment Data
  const [handlePricing, setHandlePricing] = useState([]); // Handles All Pricing
  const [currentPlanKey, setCurrentPlanKey] = useState("starter"); // Current Plan Name
  const [disableCounter, setDisableCounter] = useState(0); // Total Connector available in account
  const [plans, setPlans] = useState([]); // All Plans Data
  const [stripe, setStripe] = useState(null); // Loading Stripe
  const [loading, setLoading] = useState(true); // Loader
  const [sliderValue, setSliderValue] = useState(1); // Slider Value
  const [selectedPlanName, setSelectedPlanName] = useState("");

  //** For Handling Modal Visibility */
  const [paymentModeModal, setPaymentModeModal] = useState(false);
  const [extraCounterModal, setExtraCounterModal] = useState(false);
  const [achAccountLinkModal, setAchAccountLinkModal] = useState(false);
  const [paymentSuccessfulModal, setPaymentSuccessfulModal] = useState(false);
  const [payNowButtonVisible, setPayNowButtonVisible] = useState(false);
  const [cancelPlanModel, setCancelPlanModel] = useState(false);
  const [manageBillingAdminAssign, setManageBillingAdminAssign] =
    useState(false);
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "success",
  });

  const { groups } = useSelector((state) => state.loginReducer); // Getting group from login reducer
  const accountID = groups.length > 0 ? groups[0] : null; // Getting AccountID from Group

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK); // Loading Stripe

  // Variable for Plan Images
  const allPlanImages = useMemo(
    () => ({
      starter: StarterPlanImg,
      pro: ProPlanImg,
      premium: PremiumPlanImg,
    }),
    //eslint-disable-next-line
    []
  );

  const sliderMarkPoint = useMemo(
    () => ({
      0: "0",
      1: "3",
      3: "4",
      4: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
    }),
    []
  );

  // Fetching Plans
  const fetchPlans = async (force = false) => {
    if (plans?.length === 0 || force) {
      let response = dispatch(getPlanList());
      return response
        .then((data) =>
          data.forEach((item) => {
            const planDetails = JSON.parse(item?.planDetails);
            const bulletPointsArray = [];
            item.bulletPoints.forEach((item) => {
              bulletPointsArray?.push(JSON.parse(item));
            });

            setPlans((pS) => [
              ...pS,
              {
                ...item,
                planDetails: planDetails,
                bulletPoints: bulletPointsArray,
              },
            ]);
          })
        )
        .catch((err) => {
          console.log("Error : ", err);
        });
    }
  };

  // Fetching Custom Account
  const fetchAccountDetails = async () => {
    setLoading(true);
    if (accountID) {
      let account = await API.graphql(
        graphqlOperation(getAccountCustom, { id: accountID })
      );
      const accountData = account?.data?.getAccount;
      setAcct(accountData);
      setAccountDetails(accountData);
      const temp = JSON.parse(account?.data?.getAccount?.metadata);

      const totalConnectors = parseInt(temp?.totalConnectors);
      if (totalConnectors) {
        setDisableCounter(totalConnectors);
        dispatch(setConnectorCounterRedux(totalConnectors));
        setSliderValue(
          findKey(
            sliderMarkPoint,
            (item) => item === totalConnectors?.toString()
          )
        );

        setSelectedPlanName(getPlanKey(totalConnectors));
        setCurrentPlanKey(getPlanKey(totalConnectors));
      } else {
        setSelectedPlanName("");
        setCurrentPlanKey("starter");
        setSliderValue(1);
        setDisableCounter(0);
        dispatch(setConnectorCounterRedux(0));
      }
    }
    setLoading(false);
  };

  // Manage Billing Handling
  const manageBillingHandler = async () => {
    setLoading(true);
    return await BillingSDK.getPortalLink(accountID)
      .then((res) => {
        window.location.href = res?.url;
      })
      .catch(({ error }) => {
        console.log(error);
        if (error === "Failed to retrieve org account.") {
          setManageBillingAdminAssign(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Current Plan Selected Handling
  const selectPlan = async (planName) => {
    const selectedPlanKey = (planName || currentPlanKey)?.toLowerCase(); // Getting current plan name
    const currentPlanDetails = filterHandlePricing(selectedPlanKey)[0]; // Getting details for current plan

    if (accountID && selectedPlanKey) {
      setLoading(true);
      setAchAccountLinkModal(false);
      setPaymentModeModal(false);
      try {
        const {
          planStripeId,
          planId,
          planCounter,
          planConnector,
          topUpStripeId,
        } = currentPlanDetails[selectedPlanKey];

        const billingCheckoutParams = {
          planStripeId: planStripeId,
          topUpStripeId: topUpStripeId,
          planId: planId,
          accountId: accountID,
          paymentType: paymentType,
          paymentData: paymentData,
          topQuantity: planCounter - planConnector,
        };

        const sessionResult = await BillingSDK.getOrgCheckoutSession(
          billingCheckoutParams
        );

        const sessionType = sessionResult?.type;
        const sessionId = sessionResult?.sessionId;

        switch (sessionType) {
          case "checkout":
            stripe
              .redirectToCheckout({
                sessionId: sessionId,
              })
              .catch((e) => {
                console.log(e);
                // message.error(t(Failed_to_update_plan_Message));
                setApiResultModal(() => ({
                  message: t(Failed_to_update_plan_Message),
                  isApiResultModelOpen: true,
                  type: "failure",
                }));
              });

            break;
          case "updatePlan":
            setPaymentSuccessfulModal(true);
            // message.success(t(Plan_Update_Success_Message));
            // setApiResultModal(() => ({
            //   message: t(Plan_Update_Success_Message),
            //   isApiResultModelOpen: true,
            //   type: "success",
            // }));
            break;
          case "purchasedPlan":
            setPaymentSuccessfulModal(true);
            // message.success(t(Plan_Update_Success_Message));
            // setApiResultModal(() => ({
            //   message: t(Plan_Update_Success_Message),
            //   isApiResultModelOpen: true,
            //   type: "success",
            // }));
            break;

          default:
            // message.error(t(Failed_to_update_plan_Message));
            setApiResultModal(() => ({
              message: t(Failed_to_update_plan_Message),
              isApiResultModelOpen: true,
              type: "failure",
            }));
            break;
        }
      } catch (error) {
        console.log("error creating stripe session", error);
        // message.error(t(Failed_to_update_plan_Message));
        setApiResultModal(() => ({
          message: t(Failed_to_update_plan_Message),
          isApiResultModelOpen: true,
          type: "failure",
        }));
      } finally {
        setLoading(false);
        setPayNowButtonVisible(false);
      }
    }
  };

  // Fetching All API data
  const getAllApiData = async () => {
    setLoading(true);
    try {
      await fetchPlans();
      await getPlaidToken();
    } catch (error) {
      console.log("ERROR", error);
    }
    setLoading(false);
  };

  const getPlaidToken = async () =>
    await BillingSDK.getPlaidLinkToken(accountID).then((res) => {
      setLinkToken(res.data.linkToken);
    });

  const plaidFunction = async (token) => {
    const config = {
      token: token,
      onLoad: function () {},
      onSuccess: function (public_token, metadata) {
        // Dev Comment :-
        // console.log("Public Token: " + public_token);
        // setIsAchDisable(false);
        // setButtonConf({
        //   text: "Account Linked ✅",
        //   color: "blue",
        // });

        switch (metadata?.accounts?.length) {
          case 0:
            // Select Account is disabled: https://dashboard.plaid.com/link/account-select
            break;
          case 1:
            // Dev Comment :-
            // console.log(
            //   "Customer-selected account ID: " + metadata.accounts[0].id
            // );

            setPaymentData({
              plaidToken: public_token,
              plaidAccountId: metadata?.accounts?.[0]?.id,
            });
            setPaymentModeModal(false);
            setAchAccountLinkModal(true);
            break;
          default:
          // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
        }
      },
      onExit: async function (err, metadata) {
        // The user exited the Link flow.
        if (err != null) {
          // The user encountered a Plaid API error
          // prior to exiting.
        }
        // metadata contains information about the institution
        // that the user selected and the most recent
        // API request IDs.
        // Storing this information can be helpful for support.
      },
    };
    let linkHandler = global?.Plaid?.create(config);
    linkHandler.open();
  };

  const filterHandlePricing = (planName) => {
    return handlePricing?.filter((item) => {
      const key = Object?.keys(item)[0];
      const planComparer = planName?.toLowerCase() === key?.toLowerCase();
      return planComparer;
    });
  };

  const handleUpdateConnector = (planName, currentSliderValue) => {
    const filteredPricing = filterHandlePricing(planName)[0];
    const currentPlan = filteredPricing[planName?.toLowerCase()];
    currentPlan["planCounter"] = currentSliderValue;
    currentPlan["planPrice"] =
      currentPlan?.["basePrice"] +
      (currentPlan?.["planCounter"] - currentPlan?.["planConnector"]) *
        currentPlan?.["planAddOnPrice"];
  };

  const handleAssignPlan = (planId) => {
    setLoading(true);
    BillingSDK.assignPlan(accountID, planId, sliderMarkPoint[sliderValue])
      .then((res) => {
        // message.success(res.message);
        setApiResultModal(() => ({
          message: res?.message,
          isApiResultModelOpen: true,
          type: "success",
        }));
      })
      .finally(() => setLoading(false));
  };

  const handleCancelPlan = () => {
    setLoading(true);
    BillingSDK.cancelPlan(accountID, acct?.planID)
      .then((res) => {
        // message.success(res.message);
        setApiResultModal(() => ({
          message: res?.message,
          isApiResultModelOpen: true,
          type: "success",
        }));
      })
      .finally(() => setLoading(false));
  };

  const renderCurrentPlanData = (planName) => {
    // Getting Image for current plan
    const getCurrentPlanImage = (planName) =>
      allPlanImages[planName?.toLowerCase()];
    const getCurrentPlan = filterHandlePricing(planName)[0];
    const { planMetaDescription, planPrice, planId } =
      getCurrentPlan?.[planName] || {};

    return (
      <>
        <div className="row-one-container flex-col gap-05">
          <div className="flex-col gap-05">
            <div>
              <Image src={getCurrentPlanImage(planName)} preview={false} />
            </div>

            <Title level={4} className="plan-details-title">
              {t(planName)}
            </Title>
          </div>

          <div>
            <Title level={3} className="m-0 font-matter-bold ">
              ${planPrice?.toLocaleString() || 0}
            </Title>
            <div className="plan-desc">
              {t(planMetaDescription || "Yearly pricing summary")}
            </div>
          </div>

          <div className="flex-col">
            <Text className="need-more-container">
              {t(Need_More_Connector_Text)}
            </Text>
            <Text className="extra-charge-container">
              {t(Extra_Charge_Text)}
            </Text>
          </div>

          {groups && groups?.includes("admin") ? (
            <div className="mt-2 d-flex">
              <Button
                className="secondary-btn assign-btn"
                onClick={() => handleAssignPlan(planId)}
              >
                {t(Assign_Btn)}
              </Button>
              <Button
                className="secondary-btn"
                onClick={() => setCancelPlanModel(true)}
              >
                {t(Cancel)}
              </Button>
            </div>
          ) : acct?.planID ? (
            disableCounter?.toString() === sliderMarkPoint?.[sliderValue] ? (
              <Button
                type="primary"
                className="manage-billing-btn"
                onClick={() => manageBillingHandler()}
              >
                {t(Manage_Billing_Btn)}
              </Button>
            ) : (
              <Button
                type="primary"
                className="w-100"
                onClick={() => selectPlan(planName)}
              >
                {t(Upgrade_Plan_Btn)}
              </Button>
            )
          ) : (
            <Button
              type="primary"
              className="w-100"
              onClick={() => {
                setCurrentPlanKey(planName);
                setPaymentModeModal(true);
              }}
            >
              {t(Select_Plan_Btn)}
            </Button>
          )}
        </div>
      </>
    );
  };

  const renderCurrentPlanInfo = (currentPlanName) => {
    // console.log("Current Plan ::", currentPlanName);
    const planName = currentPlanName?.toLowerCase() || "";
    const getCurrentPlan = filterHandlePricing(planName)[0];

    const { targets, planConnector } = getCurrentPlan?.[planName] || {};

    return (
      <>
        <div
          className={
            "currentplaninfo-container " +
            (selectedPlanName?.toString()?.toLowerCase() ===
            currentPlanName?.toString()?.toLowerCase()
              ? "selected-plan-container"
              : " ")
          }
          onClick={() => {
            setCurrentPlanKey(planName);
            setSliderValue(
              planName === "starter" ? 1 : planName === "pro" ? 4 : 7
            );
          }}
        >
          <div>
            <span className="currentplan-name font-matter-bold ">
              {t(planName)}
            </span>
            <p className="currentplan-target">{t(targets)}</p>
          </div>
          <div className="flex-col gap-1">
            <span className="font-bold">
              {t(capitalize(planName))} {t(Plan_Comes_With_Text)}{" "}
            </span>
            <div className="plan-counter-background">
              {planConnector} {t(Connectors_Text)}
            </div>
          </div>
        </div>
      </>
    );
  };

  /**
   * @function renderRowItems
   * @param {JSON} item
   * @param {String} colKey
   * @returns {CheckMarkCircle}
   */
  const renderRowItems = (item, colKey) => {
    return item[colKey] === true ? (
      <div className="text-center">
        <CheckCircleFilled className="fs-24" />
      </div>
    ) : (
      item[colKey]
    );
  };

  /**
   * @function
   * @param {*} row
   * @returns {ClassName}
   */
  const dynamicRowClassName = (row) =>
    tableRowIndex?.some((element) => element === row?.key)
      ? "bg-light-blue"
      : "";

  const formatter = (value) => (
    <div className="billing-slider-formatter">
      {sliderMarkPoint[value]} Data Sources allowed
    </div>
  );

  const getPlanKey = (connectors) =>
    connectors < 5
      ? "starter"
      : connectors >= 5 && connectors < 7
      ? "pro"
      : "premium";

  const sliderValueChangeHandler = (selectedConnector) => {
    if (selectedConnector === 0) return;

    const value = sliderMarkPoint[selectedConnector];
    const currentPlan = getPlanKey(value);
    handleUpdateConnector(currentPlan, value);
    setCurrentPlanKey(currentPlan);
    setSliderValue(selectedConnector < 1 ? 1 : selectedConnector);
  };

  const paymentMethodChange = (e) => {
    setPaymentType(e?.target?.value);
  };

  useEffect(() => {
    getAllApiData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // setLoading(true);
    const resolveStripe = async () => {
      let s = await stripePromise;
      setStripe(s);
    };
    resolveStripe();
    // Dev Comment :-
    // console.log("Subscriber Executed Init");
    let Accountsubscriber = API.graphql(
      graphqlOperation(onUpdateAccount, { id: accountID })
    ).subscribe({
      next: (d) => {
        // Dev Comment :-
        // console.log("Subscriber Executed", d);
        fetchAccountDetails();
        // setLoading(false);
      },
      error: (e) => {
        console.log(e);
        // setLoading(false);
      },
    });
    fetchAccountDetails();
    return () => {
      Accountsubscriber.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("stripe_session")) {
      setPaymentSuccessfulModal(true);
    }

    for (var key of params.keys()) {
      params.delete(key);
    }
    history.replace({
      search: params.toString(),
    });
    if (params.has("error")) {
      history.replace({
        search: "",
      });
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  useEffect(() => {
    setHandlePricing([]);
    // Dev Comment :-
    // console.log("Use Effect EXE for plans");
    plans.map((currentPlan) => {
      return setHandlePricing((pS) => {
        const temp = {};

        const {
          planName,
          allowedDatasources,
          planPrice,
          addOnPrice,
          planPriceId,
          topUpPriceId,
        } = currentPlan?.planDetails;
        const key = planName?.toLowerCase();
        const currentAllowedDatasources = parseInt(allowedDatasources);

        temp[key] = {
          planId: currentPlan?.id,
          planStripeId: planPriceId,
          topUpStripeId: topUpPriceId,
          planPrice:
            currentPlan?.id === acct?.planID
              ? planPrice +
                addOnPrice * (disableCounter - currentAllowedDatasources)
              : planPrice,
          basePrice: planPrice,
          planConnector: currentAllowedDatasources,
          planAddOnPrice: addOnPrice,
          planCounter:
            currentPlan?.id === acct?.planID
              ? disableCounter
              : currentAllowedDatasources,
          targets: currentPlan?.Targets,
        };

        return [...pS, temp];
      });
    });
  }, [plans, acct, disableCounter]);

  useEffect(() => {
    setColumnsData(initialColumns);
    setBillingTableData([]);

    plans.sort(sortingOrder);

    const initialRowData = [];

    const renderContact = (value, row, index) => {
      const obj = {
        children: value,
        props: {},
      };
      if (tableRowIndex.some((element) => element === value?.props?.children))
        obj.props.colSpan = 0;

      return obj;
    };

    plans.map((currentPlan, index) => {
      setColumnsData((pS) => [
        ...pS,
        {
          title: currentPlan?.planDetails?.planName,
          dataIndex: `col${index + 2}`,
          key: index + 2,
          width: `${80 / plans?.length}%`,
          render: renderContact,
          className: "table-row-container",
        },
      ]);

      const bulletPoints = currentPlan["bulletPoints"];

      if (index === 0) {
        bulletPoints?.map((currentBulletPoint) => {
          const rowKey = Object.keys(currentBulletPoint?.[0])
            ?.toString()
            ?.toLocaleUpperCase();

          initialRowData.push({
            key: rowKey,
            col1: <span className="row-header">{rowKey}</span>,
          });
          setTableRowIndex((pS) => [...pS, rowKey]);

          return Object.keys(Object.values(currentBulletPoint?.[0])).map(
            (cData) => {
              const currentObject = Object.values(
                Object.values(currentBulletPoint)?.[0]
              )[cData];

              return currentObject.map((item) => {
                const colKey = Object.keys(item?.[0])?.[0];

                return initialRowData.push({
                  key: colKey,
                  col1: <span className="col-header">{colKey}</span>,
                  col2: renderRowItems(item?.[0], colKey),
                });
              });
            }
          );
        });
      } else {
        return bulletPoints?.map((currentBulletPoint) => {
          Object?.keys(Object?.values(currentBulletPoint?.[0]))?.map(
            (cData) => {
              const currentObject = Object?.values(
                Object?.values(currentBulletPoint)?.[0]
              )?.[cData];
              return currentObject?.map((item) => {
                const colKey = Object?.keys(item?.[0])?.[0];
                let a = initialRowData?.filter((item) => item.key === colKey);
                return (a[0][`col${index + 2}`] = renderRowItems(
                  item?.[0],
                  colKey
                ));
              });
            }
          );
          return true;
        });
      }
      return true;
    });

    setBillingTableData(initialRowData);
    //eslint-disable-next-line
  }, [plans, handlePricing, acct, payNowButtonVisible, disableCounter]);

  return (
    <>
      <div className={"billing-page-container "}>
        <SchoolHeading />
        <div className="billing-table-container min100vh">
          <div className="header-text">
            {/* <span>{t(Choose_Plan_Text)} </span> */}
            <span>{Choose_Plan_Text} </span>
          </div>
          {loading ? (
            <div className="loading"></div>
          ) : (
            <>
              <Row justify="center" className="px-4 pb-5 " gutter={[64, 64]}>
                <Col
                  span={18}
                  xxl={18}
                  xl={18}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="allplan-container"
                >
                  <div className="pb-2 flex-col gap-3">
                    <div className="allplan-container">
                      {renderCurrentPlanInfo("Starter")}
                      {renderCurrentPlanInfo("Pro")}
                      {renderCurrentPlanInfo("Premium")}
                    </div>
                    <div>
                      <div className="slider-wrapper">
                        {plans?.map(() => {
                          return (
                            <div className="divider-col">
                              <div className="divider-container"></div>
                            </div>
                          );
                        })}
                      </div>
                      <Slider
                        min={0}
                        max={10}
                        getTooltipPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        tipFormatter={formatter}
                        tooltipPlacement="bottom"
                        onChange={(e) => sliderValueChangeHandler(e)}
                        value={sliderValue}
                        handleStyle={{
                          backgroundColor: BLACK_COLOR,
                          borderColor: BLACK_COLOR,
                        }}
                        trackStyle={{
                          backgroundColor: BLACK_COLOR,
                        }}
                        step={null}
                        marks={sliderMarkPoint}
                        className="billing-slider"
                      />
                      {groups && groups.includes("admin") ? (
                        <div className="admin-assign-plan">
                          <Title level={4}> {t(Needs_Attention_Text)} </Title>
                          <Checkbox> {t(Payment_Receieved_Text)} </Checkbox>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Col>

                <Col span={6} xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                  {renderCurrentPlanData(currentPlanKey)}
                </Col>
              </Row>

              {selectedPlanName ? (
                <Row className="current-plan-info-container">
                  <Title level={4}>My Current Plan : {selectedPlanName} </Title>
                  <Image
                    preview={false}
                    src={allPlanImages[selectedPlanName]}
                  />
                </Row>
              ) : null}

              <Row>
                <Title level={3} className="plan-details-header">
                  {t(Plan_Details_Text)}
                </Title>
              </Row>
              <Table
                className="th-class billing-table-wrapper"
                columns={columnsData}
                dataSource={billingTableData}
                bordered
                pagination={false}
                rowClassName={(record) => dynamicRowClassName(record)}
                scroll={{
                  x: 1000,
                }}
              />
            </>
          )}
        </div>
      </div>

      <CustomModal
        visible={paymentModeModal}
        closable={true}
        centered
        onCancel={() => setPaymentModeModal(false)}
        footer={null}
        width={500}
      >
        <Row justify="center" className="w-100">
          <Col className="flex-col align-center w-80">
            <Image preview={false} src={PaymentCardImg} width={120} />
            <Typography.Title level={3}>
              {t(Select_Payment_Method_Text)}
            </Typography.Title>
            <Radio.Group
              options={paymentMethodOptions}
              onChange={(e) => paymentMethodChange(e)}
              value={paymentType}
              className="modal-radio-btn"
            />
            <div className="d-flex mt-16 w-100">
              <Button
                className="secondary-btn w-50"
                onClick={() => setPaymentModeModal(false)}
              >
                {t(Cancel)}
              </Button>

              {paymentType === "cc" ? (
                <Button
                  className="w-50"
                  type="primary"
                  onClick={() => selectPlan(currentPlanKey)}
                >
                  {t(Pay_Now_Text)}
                </Button>
              ) : (
                <Button
                  className="w-50"
                  onClick={() => plaidFunction(linkToken)}
                >
                  {t(Link_Account_Btn)}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </CustomModal>

      <CustomModal
        visible={extraCounterModal}
        closable={true}
        centered
        width={450}
        footer={null}
        onCancel={() => setExtraCounterModal(false)}
      >
        <Row justify="center" className="w-100">
          <Col className="flex-col align-center gap-3">
            <CheckCircleOutlined className="modal-check-mark-icon" />
            <div className="text-center">
              <Typography.Text className="fs-18 font-bold line-height-1">
                {t(Data_Source_Increase_Text)}
              </Typography.Text>
              <Typography.Text className="fs-18 font-bold line-height-1">
                {t(Charge_Applicable_Text)}
              </Typography.Text>
            </div>
            <div className="d-flex mt-8">
              <Button
                className="secondary-btn "
                onClick={() => setExtraCounterModal(false)}
              >
                {t(Cancel)}{" "}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setExtraCounterModal(false);
                  handleUpdateConnector(currentPlanKey, "addition");
                  if (acct?.accountStatus) {
                    setPayNowButtonVisible(true);
                  }
                }}
              >
                {t(Yes_Sure_Text)}{" "}
              </Button>
            </div>
          </Col>
        </Row>
      </CustomModal>

      <CustomModal
        visible={achAccountLinkModal}
        centered
        width={400}
        footer={null}
        onCancel={() => setAchAccountLinkModal(false)}
      >
        <Row justify="center" className="w-100">
          <Col className="flex-col align-center text-center gap-3">
            <Typography.Title level={3}>
              {t(Account_Link_Successful_Text)}
            </Typography.Title>

            <Typography.Title level={1} className="m-0 primary-color">
              {t(ACH_Text)}
            </Typography.Title>

            <div className="d-flex mt-8">
              <Button
                className="secondary-btn "
                onClick={() => setAchAccountLinkModal(false)}
              >
                {t(Cancel)}
              </Button>
              <Button className="btn-primary " onClick={() => selectPlan()}>
                {t(Pay_Now_Text)}{" "}
              </Button>
            </div>
          </Col>
        </Row>
      </CustomModal>

      <SuccessfulModal
        successImage={ManageBillingAdminAssign}
        isShowSuccessfulModal={manageBillingAdminAssign}
        setIsShowSuccessfulModal={setManageBillingAdminAssign}
        width={"40%"}
        modalTitle={"Manage Billing"}
        modalText={
          <>
            {t(Admin_Assigned_Part_1)}{" "}
            <Typography.Text className="text-decoration-underline">
              {t(contactMail)}
            </Typography.Text>{" "}
            {t(Admin_Assigned_Part_2)}
          </>
        }
      />
      {/* <SuccessfulModal
        isShowSuccessfulModal={paymentSuccessfulModal}
        setIsShowSuccessfulModal={setPaymentSuccessfulModal}
        modalText={"Payment Successful"}
      /> */}
      <SuccessfulModal
        isShowSuccessfulModal={cancelPlanModel}
        setIsShowSuccessfulModal={setCancelPlanModel}
        modalText={"Are you sure you want to cancel the plan subscription?"}
        okBtnClickHandler={() => handleCancelPlan()}
      />

      <CustomModal
        visible={paymentSuccessfulModal}
        closable={true}
        centered
        width={"45%"}
        footer={null}
        onCancel={() => setPaymentSuccessfulModal(false)}
      >
        <div className="flex-col justify-content-center align-items-center text-center gap-3 ">
          <img alt="..." src={PlanPurchasedImg} width={300} />
          <Typography.Title className="mb-0 fw-bold" level={2}>
            {t(Plan_Selected_Text)}{" "}
          </Typography.Title>
          <Typography.Title level={5} className="m-0">
            {t(Subscription_Selected_Text)}
          </Typography.Title>

          <Button
            type="primary"
            onClick={() => {
              history.push("/dataprovider");
            }}
          >
            {t(Go_To_Management_Text)}
          </Button>
        </div>
      </CustomModal>
      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
};

export default Billing;
