//** React imports */
import React from "react";
//** Ant Design imports */
import { Col, Typography } from "antd";
//** Constant imports*/
import { LayoutText } from "utility/constants";
//** Third Party imports */
import { useTranslation } from "react-i18next";
import Title from "Common/Components/CustomAntD/Title";

/**
 * Footer component
 * @function AuthColOne
 * @return {<Column One Content/>}
 */
const AuthColOne = () => {
  //** Destructing Footer Text  */
  const { AuthColOneTitle, AuthColOneText } = LayoutText;
  //** Destructing Translator Hook */
  const { t } = useTranslation();
  return (
    <Col span={12} xs={0} md={12} lg={12} className="login-bg common-bg">
      <Title level={1} className="fw-bolder mb-2">
        {t(AuthColOneTitle)}
      </Title>
      <Title level={4}>{t(AuthColOneText)}</Title>
    </Col>
  );
};

export default AuthColOne;
