//** React Imports */
import React, { useState, useEffect } from "react";
//** Ant Design Imports */
import { Col, Row } from "antd";
//** Logo Imports */
import Logo from "Common/Logo";
//** Footer Imports */
import { Footer } from "Common/Layouts/Layouts";
//** Redux Imports */
import { useDispatch, useSelector } from "react-redux";
//** Account Setup Imports */
import NewAccount from "./NewAccount";
import RequestPending from "./RequestPending";
import RequestAccess from "./RequestAccess";
//** Redux Actions Imports */
import {
  setLoginData,
  getCustomerAccountRequests,
} from "store/actions/loginAction";
//** Amplify Imports */
import { Auth } from "aws-amplify";
import UnauthorizedModal from "Common/Components/Modals/UnauthorizedModal";

const NEW = "newAccount";
const ACCESS = "requestAccess";

const Selection = () => {
  //** Destructing Hooks */
  const dispatch = useDispatch();

  /**
   * @useState for setting data selection requests
   */
  const [step, setStep] = useState("");
  const [requestAccessState, setRequestAccessState] = useState(false);
  const [accountid, setAccountId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  //** Redux Store Imports */
  const { accountRequests, groups } = useSelector(
    (state) => state.loginReducer
  );

  /**
   * Fetching users
   */
  async function fetchuser() {
    const getUser = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        await cognitoUser.refreshSession(
          currentSession.refreshToken,
          async (err, session) => {
            if (session) {
              const { accessToken } = session;
              if ("cognito:groups" in accessToken.payload) {
                let userAccountId = [];
                userAccountId = accessToken.payload["cognito:groups"];

                userAccountId.push(
                  userAccountId.splice(userAccountId.indexOf("admin"), 1)[0]
                );
                clearInterval();
                const response = Auth.currentUserInfo();

                response
                  .then((data) => {
                    dispatch(setLoginData(session, data?.attributes));
                    clearInterval();
                  })
                  .then((_) => {
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  });
              }
            }
          }
        );
      } catch (e) {
        console.log("Unable to refresh Token", e);
      }
    };

    if (
      window.location.host.includes("stag") &&
      groups?.length === 1 &&
      groups?.includes("stage_users")
    ) {
      await getUser();
    } else if (groups?.length === 0) {
      await getUser();
    }
  }

  /**
   * @useEffects for handling data
   */
  useEffect(() => {
    if (
      window.location.href.includes("requestid=") &&
      window.location.href.includes("/setup")
    ) {
      setAccountId(window.location.href.split("requestid=")[1]);
      setStep(ACCESS);
    }
  }, []);

  useEffect(() => {
    if (accountRequests.length >= 1) {
      setIsLoading(false);
      setRequestAccessState(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    return () => clearTimeout();
  }, [accountRequests]);

  const handleRequestAccessState = (value) => {
    setRequestAccessState(true);
  };

  useEffect(() => {
    if (requestAccessState) {
      setInterval(() => {
        fetchuser();
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestAccessState]);

  useEffect(() => {
    dispatch(getCustomerAccountRequests());
    return () => {
      clearInterval();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading && <div className="loading">Loading;</div>}
      <Row justify="center">
        <Col span={24}>
          {requestAccessState && !accountid ? (
            <RequestPending accountid={accountid} />
          ) : (
            <></>
          )}
          {!requestAccessState && accountRequests?.length === 0 && (
            <>
              <Row align="middle" justify="center" wrap>
                <Col span={24} className="selection-logo">
                  <Logo />
                </Col>

                <Row align="stretch" justify="center" className="gap-4">
                  <Col
                    span={10}
                    xs={22}
                    sm={22}
                    md={13}
                    lg={8}
                    xxl={6}
                    className="start-company-card"
                  >
                    <NewAccount
                      select={() => setStep(NEW)}
                      expand={step === NEW}
                      reset={() => {
                        console.log("New Account");
                        setStep(() => "Hello Wsorld");
                      }}
                      fetchuser={fetchuser}
                    />
                  </Col>
                  <Col
                    span={10}
                    xs={22}
                    sm={22}
                    md={13}
                    lg={8}
                    xxl={6}
                    className="request-access-card"
                  >
                    <RequestAccess
                      select={() => setStep(ACCESS)}
                      expand={step === ACCESS}
                      reset={() => setStep("")}
                      requestAccess={handleRequestAccessState}
                      accountid={accountid}
                    />
                  </Col>
                </Row>
              </Row>
            </>
          )}
        </Col>

        <Col span={24} className="selection-footer">
          <Footer />
        </Col>
      </Row>

      <UnauthorizedModal />
    </>
  );
};
export default Selection;
