//** React Imports */
import React, { useState, useEffect } from "react";
//** Ant Imports */
import { Button, Typography, Image, message, Modal } from "antd";
//** React Router Imports */
import { useHistory } from "react-router-dom";
//** Redux Imports */
import { logout } from "store/actions/loginAction";
import { useDispatch, useSelector } from "react-redux";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** Images Import */
import { Images } from "assets/images/Images";
import { useTranslation } from "react-i18next";
import { buttonText, messageText, modalText } from "utility/constants";
import UnauthorizedImg from "assets/images/Modals/Unauthorised.svg";
import CustomModal from "../CustomAntD/Modal";

const UnauthorizedModal = () => {
  //** Destructing Hooks */
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isUnauthorizedVisisible } = useSelector(
    (state) => state.settingReducer
  );

  //** Destructing constants */
  const { Fail_to_logout_Message } = messageText;
  const { Unauthorized_Text } = modalText.Unauthorized_Modal;
  const { OK_Btn } = buttonText;

  //** States for handling data */
  const [loading, setLoading] = useState();

  const logOut = async () => {
    setLoading(true);
    Auth.signOut({
      global: true,
    })
      .then((data) => {
        dispatch(logout());
        history.push("/signin");
      })
      .catch((err) => {
        console.log(err);
        message.error(t(Fail_to_logout_Message));
        setLoading(false);
      });
  };
  return (
    <CustomModal
      visible={isUnauthorizedVisisible}
      closable={false}
      centered
      width={450}
      footer={null}
      // className="suspend-company-footer br_20"
      // onCancel={() => handleCancel()}
    >
      <div className="flex-col gap-3 align-items-center">
        <Image src={UnauthorizedImg} alt="unauthorized" preview={false} />
        <Typography.Title level={5} className="text-center">
          {t(Unauthorized_Text)}
        </Typography.Title>
        <Button type="primary" loading={loading} onClick={logOut}>
          {t(OK_Btn)}
        </Button>
      </div>
    </CustomModal>
  );
};

export default UnauthorizedModal;
