import { API } from "aws-amplify";
import {
  listAccountRequests,
  listWebProfiless,
  listPlans,
} from "graphql/queries";

const getAccountRequests = () => {
  return API.graphql({ query: listAccountRequests });
};

const getIntegrationsApi = (inputData, nextToken) => {
  return API.graphql({
    query: listWebProfiless,
    variables: { filter: inputData, nextToken: nextToken },
  });
};
const getPlanListApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listPlans,
    variables: { filter: filter, nextToken: nextToken },
  });
};
export const SchoolBiApi = {
  getAccountRequests,
  getIntegrationsApi,
  getPlanListApi,
};
