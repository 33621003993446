import React from "react";
import { Space, Skeleton } from "antd";

const SkeletonBlock = () => (
  <Space style={{ width: "100%" }} direction="vertical">
    <Skeleton.Input active style={{ width: "60vw" }} size="large" />
    <Skeleton.Input active style={{ width: "60vw" }} size="large" />
    <Skeleton.Input active style={{ width: "60vw" }} size="large" />
    <Skeleton.Button
      active
      size="large"
      shape="round"
      style={{ width: 200, borderRadius: 7 }}
    />
  </Space>
);

export default SkeletonBlock;
