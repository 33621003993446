import React from "react";
import { Images } from "assets/images/Images";
import { Button, Tooltip } from "antd";
import Icon from "@ant-design/icons";
const InfoButton = ({ title, placement, classNames }) => {
  const InfoSvg = () => (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17.7433C14.9706 17.7433 19 13.9952 19 9.37165C19 4.74812 14.9706 1 10 1C5.02944 1 1 4.74812 1 9.37165C1 13.9952 5.02944 17.7433 10 17.7433Z"
        stroke="#0C1515"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0618 13.062L12.0611 13.0625L12.0618 13.062ZM12.0606 13.0611L12.0611 13.0612L12.0628 13.0615L12.0611 13.0612L12.0606 13.0611ZM10.7725 13.2423C10.8626 13.3054 10.974 13.3471 11.1079 13.3675C10.8744 13.4449 10.6025 13.5 10.3015 13.5C10.1382 13.5 9.97021 13.4825 9.80439 13.4493C9.38707 13.364 9.101 13.1378 8.95439 12.8359L8.95413 12.8354C8.77104 12.4596 8.77826 11.9141 9.10474 11.2628L9.10474 11.2628L9.72963 10.0162C9.72965 10.0161 9.72967 10.0161 9.72969 10.016C9.72971 10.016 9.72973 10.016 9.72974 10.0159C9.91028 9.65615 10.0028 9.33797 9.95684 9.05286C9.90252 8.71618 9.67869 8.55998 9.60293 8.5071L9.60087 8.50567L9.60053 8.50543C9.51232 8.44395 9.40259 8.40212 9.26925 8.38158C9.50186 8.30465 9.77263 8.25 10.0721 8.25H10.0722C10.2396 8.24999 10.4066 8.2669 10.5706 8.30047L10.5708 8.3005C10.9875 8.38565 11.2745 8.61208 11.4211 8.91406L11.4213 8.9144C11.6045 9.29085 11.5967 9.83643 11.2707 10.4873L10.6459 11.7332C10.6459 11.7333 10.6458 11.7333 10.6458 11.7333C10.6458 11.7333 10.6458 11.7333 10.6458 11.7334C10.4653 12.0931 10.3722 12.411 10.4176 12.6956C10.4713 13.0326 10.6954 13.1886 10.7708 13.2411L10.7725 13.2423ZM8.2516 8.51086C8.25406 8.51002 8.25653 8.50923 8.25901 8.50849C8.25645 8.50935 8.25399 8.51018 8.25164 8.51099L8.2516 8.51086Z"
        fill="#0C1515"
        stroke="#0C1515"
      />
      <path
        d="M12.5 5.5625C12.5 6.1493 12.0243 6.625 11.4375 6.625C10.8507 6.625 10.375 6.1493 10.375 5.5625C10.375 4.9757 10.8507 4.5 11.4375 4.5C12.0243 4.5 12.5 4.9757 12.5 5.5625Z"
        fill="#0C1515"
        stroke="#0C1515"
      />
    </svg>
  );

  return (
    <Tooltip title={title} placement={placement} color="#F5DB5D">
      <Icon
        component={InfoSvg}
        className={
          classNames ? `tooltip-container ${classNames}` : "tooltip-container"
        }
      />
    </Tooltip>
  );
};

export default InfoButton;
