//** React Imports */
import React from "react";
//** Ant Design Imports */
import { Modal } from "antd";
//** Ant Icons Imports */
import { CloseOutlined } from "@ant-design/icons";

const CustomModal = ({ children, closeClickHandler, closable, ...rest }) => {
  return (
    <Modal
      {...rest}
      maskStyle={{ backdropFilter: "blur(4px)" }}
      closable={closable || false}
      onCancel={() => {
        if (closeClickHandler) closeClickHandler();
        if (rest?.onCancel) rest?.onCancel();
      }}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
