import { Form, Input, Table } from "antd";
import React, { useMemo } from "react";
import "./ManageFiles.less";

const FilePreviewTable = ({
  filePreviewTableColData,
  filePreviewTableData,
  type,
  flag,
}) => {
  const columns = useMemo(
    () =>
      Object.keys(filePreviewTableColData).map((item, index) => ({
        title:
          type === "edit" ? (
            <Form.Item name={item}>
              <Input bordered={flag ? true : false} className="bb-form-input" />
            </Form.Item>
          ) : (
            <span className="fs-16">{item}</span>
          ),
        dataIndex: item,
        key: item,
        width: index === 0 ? 80 : 150,
        fixed: index === 0 ? "left" : "",
      })),
    [filePreviewTableColData, flag, type]
  );

  return (
    <Table
      columns={columns}
      dataSource={filePreviewTableData}
      size="middle"
      className={
        type === "edit" || type === "preview"
          ? "file-preview-table edit-preview-table"
          : "file-preview-table"
      }
      scroll={{
        x: 1000,
        y: 450,
      }}
    />
  );
};

export default FilePreviewTable;
