//** React Imports */
import React, { useState } from "react";
//** Ant Design Imports */
import { Row, Alert, Col, Input, Form, Button } from "antd";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** Layout Imports */
import Footer from "Common/Layouts/Footer";
import Background from "Common/Layouts/AuthColOne";
import LoginModuleLogo from "Common/Components/LoginModuleLogo/LoginModuleLogo";
//** Constant Imports */
import {
  buttonText,
  emailPattern,
  formError,
  formLabel,
} from "utility/constants";
//** Third Party Imports */
import { useTranslation } from "react-i18next";

/**
 * @function base component
 * @param {*} props
 */
const ForgotPassword = (props) => {
  //** Destructing Hooks */
  const { t } = useTranslation();

  //** Destructing Constants */
  const { isEmailInvalid, isEmailEmpty } = formError;
  const { Email_Label, emailText } = formLabel;
  const { Login_Text, Send_code_Text } = buttonText;

  //** Initial State for forget password */
  const initialValues = {
    ack: "",
    error: "",
    loading: false,
  };

  const [state, setState] = useState(initialValues);

  //** Destructing States and Props */
  const { error, loading } = state;
  const { history } = props;

  //** Handles reset password of users */
  const handleResetPassword = async (e) => {
    setState((pS) => ({ ...pS, loading: true, error: "" }));

    try {
      await Auth.forgotPassword(e.email).then(() => {
        setState((pS) => ({ ...pS, loading: false }));
        history.push({
          pathname: "/reset-password",
          state: { userName: e.email },
        });
      });
    } catch (error) {
      console.log("error resetting password:", error);
      setState((pS) => ({ ...pS, error: error.message, loading: false }));
    }
  };

  return (
    <Row justify="center">
      <Background />
      <Col span={12} md={12} lg={12} xs={24} sm={20}>
        <Row gutter={24} justify="center" className="login-module-col-2">
          <Col xs={24} sm={24} md={24} lg={18} xl={12} xxl={12}>
            <LoginModuleLogo />
            {error && (
              <Alert
                className="mb-10"
                type="error"
                showIcon={false}
                message={error}
                banner
              />
            )}
            <Form
              requiredMark={false}
              layout="vertical"
              name="normal_login"
              className="login-form"
              onFinish={(e) => handleResetPassword(e)}
            >
              <Form.Item
                label={t(Email_Label)}
                name="email"
                className="font-bold"
                rules={[
                  { required: true, message: t(isEmailEmpty) },
                  {
                    pattern: emailPattern,
                    message: t(isEmailInvalid),
                  },
                ]}
              >
                <Input
                  id="userName"
                  key="userName"
                  name="email"
                  disabled={loading}
                  placeholder={t(emailText)}
                  className="border-light-color login-module-input"
                />
              </Form.Item>
              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Col span={12}>
                    <Button
                      type="link"
                      onClick={() => history.push("/signin")}
                      disabled={loading}
                      className="login-btn fs-18 font-bold"
                    >
                      {t(Login_Text)}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      size="large"
                      htmlType="submit"
                      block
                      tabIndex={5}
                      loading={loading}
                      type="primary"
                      className="w-100"
                    >
                      {t(Send_code_Text)}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
          <Footer />
        </Row>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
