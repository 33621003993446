//** React Imports */
import React, { useEffect, useState } from "react";
//** Ant Design Imports */
import { Button, Input, Form, Row, Col, message, Typography } from "antd";
//** React Router Imports */
import { useHistory } from "react-router-dom";

//** Skeleton Imports */
import SkeletonBlock from "Common/Skeleton/SkeletonBlock";
//** Common Method Imports */
import { apiExecuter, get_attribute } from "utility/commonMethods";
//** Constant Imports */
import {
  buttonText,
  formError,
  formLabel,
  messageText,
  profileText,
} from "utility/constants";
//** Third Party Imports */
import { useTranslation } from "react-i18next";
//** Redux Imports */
import { useDispatch, useSelector } from "react-redux";
//** SDK Imports */
import sdk from "sdk/Accounts";
import {
  setFamilyName,
  setGivenName,
  setUserData,
} from "store/actions/loginAction";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import { Auth } from "aws-amplify";

export default function BasicDetails() {
  //** Destructing Hooks */

  const { t } = useTranslation();

  //** Destructing Ant Components */
  const { Title } = Typography;

  //** Destructing Constants  */
  const { Basic_Information_Text } = profileText;
  const { profileUpdate } = messageText;
  const { Save } = buttonText;
  const { Email_Label, lastName, firstName } = formLabel;
  const { isLastNameEmpty, isFirstNameEmpty } = formError;

  //** Ant Design Form */
  const [profileForm] = Form.useForm();
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  //** Getting Values From Redux */
  const {
    isLoading,
    selectedCompanyName,
    userData,
    scopedUserDetails,
    given_name,
    family_name,
  } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  /**
   * @function handleUpdateProfile
   * Updating current user profile
   */
  const handleUpdateProfile = async () => {
    await apiExecuter(async () => {
      const formValue = profileForm.getFieldValue();

      const data = {
        family_name: formValue.family_name,
        given_name: formValue.given_name,
      };

      let init = {
        body: {
          ...data,
        },
      };

      /** Handles Profile Update */
      await sdk
        .updateProfile(init)
        .then((_) => {
          // message.success(t(profileUpdate));
          setApiResultModal(() => ({
            message: t(profileUpdate),
            isApiResultModelOpen: true,
            type: "success",
          }));
          dispatch(setUserData(formValue));
          dispatch(setGivenName(formValue.given_name));
          dispatch(setFamilyName(formValue.family_name));
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  /**
   * @useEffect
   * Getting Current User Profile
   */
  useEffect(() => {
    if (scopedUserDetails?.email) {
      profileForm.setFieldsValue({
        family_name,
        given_name,
        email: scopedUserDetails?.email,
      });

      return;
    } else {
      const { family_name, given_name, email } = userData;

      profileForm.setFieldsValue({
        family_name,
        given_name,
        email,
      });
    }

    // eslint-disable-next-line
  }, [userData, scopedUserDetails?.email]);

  return (
    <>
      <div className="max-width basic-detail pt-3">
        <Title level={3} className="sub-header-font">
          {t(Basic_Information_Text)}{" "}
        </Title>

        <Form
          name="basic"
          layout="vertical"
          form={profileForm}
          onFinish={handleUpdateProfile}
          // className="profile-form"
          disabled={selectedCompanyName}
        >
          <Row gutter={[10, 10]}>
            <Col span={8} lg={8} md={8} sm={24} xs={24}>
              <Form.Item
                name="given_name"
                label={t(firstName)}
                className="font-bold"
                rules={[
                  {
                    required: true,
                    message: t(isFirstNameEmpty),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} lg={8} md={8} sm={24} xs={24}>
              <Form.Item
                name="family_name"
                className="font-bold"
                label={t(lastName)}
                rules={[{ required: true, message: t(isLastNameEmpty) }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} lg={8} md={8} sm={24} xs={24}>
              <Form.Item
                name="email"
                label={<> {t(Email_Label)} </>}
                className="font-bold"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8} lg={8} md={8} sm={24} xs={24}>
              <Form.Item>
                <Button
                  loading={isLoading}
                  htmlType="submit"
                  size="large"
                  type="primary"
                  className="px-4"
                >
                  {t(Save)}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {/* 
        {!email?.length > 0 ? (
          <SkeletonBlock />
        ) :
          (
         
        )} */}
      </div>
      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
}
