import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { getAccount, listAccounts } from "graphql/queries";
import {
  createAccountRequest,
  updateAccount,
  deleteAccountRequest,
  createMFASetupDetails,
} from "graphql/mutations";
import { apiExecuter, GraphqlOperationExecutor } from "utility/commonMethods";
import { listAccountsCustom } from "graphql/customQueries";

const createAccount = async (
  companyName,
  companyUrl,
  companyId,
  emailOfAdmin
) => {
  const path = `/createaccount/account`;
  try {
    var result = await API.post("schoolBIREST", path, {
      body: { companyName, companyUrl, companyId, emailOfAdmin },
    });

    if (result?.status !== 200) {
      throw new Error(result?.message);
    }

    return result;
  } catch (e) {
    console.log(e);
    return {
      error: e.response?.data?.error
        ? e.response?.data?.error
        : "Failed creating account",
    };
  }
};

const updateProfile = async ({ body }) => {
  const hubspotBody = {
    email: body.email,
    first_name: body.given_name,
    last_name: body.family_name,
  };
  await API.post("schoolBIREST", "/user/update", { body: body });
  await API.post("schoolBIREST", "/createaccount/update/userattributes", {
    body: hubspotBody,
  });
};

const requestAccess = async (accountID) => {
  try {
    let request = await API.graphql(
      graphqlOperation(createAccountRequest, {
        input: { id: uuidv4(), account: accountID },
      })
    );

    request = request.data.createAccountRequest;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const fetchAccount = async (accountID) => {
  try {
    let account = await API.graphql(
      graphqlOperation(getAccount, { id: accountID })
    );
    return account.data.getAccount;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving account"] };
  }
};

const companySuspension = async (companyId, suspend) => {
  try {
    let request = await API.graphql(
      graphqlOperation(updateAccount, {
        input: {
          id: companyId,
          disabled: suspend,
        },
      })
    );

    request = request.data.updateAccount;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const deleteRequest = async (requestID) => {
  try {
    let request = await API.graphql(
      graphqlOperation(deleteAccountRequest, {
        input: { id: requestID },
      })
    );

    request = request.data.deleteAccountRequest;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};
const approveOrDenyRequest = async (bodyData) => {
  const path = `/addapproverequest/request`;
  try {
    var result = await API.post("schoolBIREST", path, { body: bodyData });

    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed approving request" };
  }
};

const sendEmailInvitation = async (value) => {
  const path = `/invitemember/invite`;
  let body = {
    emailId: value.emails,
    inviteBy: value.inviteBy,
  };
  try {
    var result = await API.post("schoolBIREST", path, {
      body: body,
    });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed sending invitation" };
  }
};
const getAllUsers = async (nextToken, filter = {}) => {
  try {
    let companyList;
    if (Object.keys(filter).length > 0) {
      companyList = await API.graphql(
        graphqlOperation(listAccountsCustom, {
          nextToken: nextToken,
          filter: filter,
        })
      );
    } else {
      companyList = await API.graphql(
        graphqlOperation(listAccountsCustom, {
          limit: 15,
          nextToken: nextToken,
        })
      );
    }
    return companyList;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving Companies"] };
  }
};

const addSecretsKey = async (id) => {
  let path = `/apikey/create`;
  let apiName = "schoolBIREST";
  let data = {
    accountRequestId: id,
  };

  try {
    var result = await API.post(apiName, path, {
      body: data,
    });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed add the secrets key" };
  }
};

const deleteSecretsKey = async (id, deleteId) => {
  let path = `/apikey/delete?account=${id}`;
  let apiName = "schoolBIREST";
  let body = { id: deleteId };
  try {
    var result = await API.post(apiName, path, { body: body });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed in deleting the secrets key" };
  }
};

const disableSecretsKey = async (id, disableId, value) => {
  let path = `/apikey/disable?account=${id}`;
  let apiName = "schoolBIREST";
  let body = {
    id: disableId,
    disabled: value,
  };
  try {
    var result = await API.post(apiName, path, { body: body });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Disable Secrets Key failed" };
  }
};

const createSchoolAccount = async (companyName, companyUrl, emailIds) => {
  const path = `/createaccount/byadmin`;

  try {
    var result = await API.post("schoolBIREST", path, {
      body: { companyName, companyUrl, emailIds },
    });

    if (result.status !== 200) {
      throw new Error(result.message);
    }

    return result;
  } catch (e) {
    console.log(e);
    return {
      error: e.message ? e.message : "Failed creating account",
    };
  }
};

const updateUserRole = async (body) =>
  apiExecuter(async () => {
    const path = `/createaccount/updaterole`;
    return await API.post("schoolBIREST", path, { body: body });
  });

const updateMFA = async ({ emailId, enable }) => {
  if (enable) {
    apiExecuter(async () => {
      const path = `/createaccount/configure/mfa`;
      return await API.post("schoolBIREST", path, {
        body: { emailId, enable },
      });
    });
  } else {
    await apiExecuter(async () => {
      const path = `/createaccount/configure/mfa`;
      return await API.post("schoolBIREST", path, {
        body: { emailId, enable },
      });
    });
    await apiExecuter(async () => {
      const path = `/createaccount/mfa/toggle`;
      return await API.post("schoolBIREST", path, {
        body: { emailId, enable },
      });
    });
  }
};

const sdk = {
  createAccount,
  updateProfile,
  requestAccess,
  fetchAccount,
  companySuspension,
  deleteRequest,
  approveOrDenyRequest,
  sendEmailInvitation,
  getAllUsers,
  addSecretsKey,
  deleteSecretsKey,
  disableSecretsKey,
  createSchoolAccount,
  updateUserRole,
  updateMFA,
};

export default sdk;
