//** React Imports */
import React from "react";
//** Ant Imports */
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function Loader() {
  return <Spin indicator={<LoadingOutlined className="fs-24" />} />;
}

export default Loader;
