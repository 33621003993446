import { Col, Row, Tabs, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { ManageDataset_Constants } from "utility/constants";
import "./ManageDatasets.less";
import _ from "lodash";
import DataSource from "./DataSource";
import AdvancedList from "./AdvancedList";

const ManageDatasets = () => {
  const { Title } = Typography;
  const { t } = useTranslation();

  const { Manage_Datasets_Title } = ManageDataset_Constants;

  const { TabPane } = Tabs;

  const tabPaneData = [
    {
      key: "data-source",
      tab: "Data Sources",
      component: <DataSource />,
    },
    {
      key: "advanced-list",
      tab: "Advanced List",
      component: <AdvancedList />,
    },
  ];

  return (
    <>
      <Row className="manage-files-container manage-dataset-container">
        <Col span={24}>
          <Title level={4} className="manage-files-heading">
            {t(Manage_Datasets_Title)}
          </Title>

          <Tabs
            defaultActiveKey={"upload-files"}
            tabPosition="top"
            className="school-details organization w-100 "
            // activeKey={currentActiveKey}
            // onChange={(e) => setCurrentActiveKey(e)}
          >
            {tabPaneData.map((tabPaneItem) => (
              <TabPane tab={tabPaneItem?.tab} key={tabPaneItem?.key}>
                {tabPaneItem?.component}
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default ManageDatasets;
