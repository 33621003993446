//** React Imports */
import React, { useEffect, useState } from "react";
//** Ant Design Component Imports */
import { Button, Collapse, Drawer, Typography, Popconfirm } from "antd";
//** Ant Design Icon Imports */
import {
  RightOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
} from "@ant-design/icons";
//** Uuid Imports */
import { v4 as uuidv4 } from "uuid";
//** GraphQL Queries Imports */
import { listFAQs } from "graphql/queries";
//** GraphQL Mutations Imports */
import { createFAQ, deleteFAQ, updateFAQ } from "graphql/mutations";
//** Redux Imports */
import { useSelector } from "react-redux";
//** Skeleton Imports */
import { SkeletonHelp } from "Common/Skeleton/SkeletonHelp";
//** Css Imports */
import "./Help.less";
import "react-markdown-editor-lite/lib/index.css";
//** Third Party Imports */
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import { marked } from "marked";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
//** Constant Functions Imports */
import { GraphqlOperationExecutor } from "utility/commonMethods";
//** Constants Imports */
import { helpSection, buttonText } from "utility/constants";
import CaretUp from "Common/Components/CaretUp/CaretUp";

/**
 * @author PrithvirajsinhJadav
 * @function Help
 * Renders Help function with FAQ having admin access for editing Q&A
 * @return {ReactComponent}
 */
const Help = () => {
  //** Destructing Ant Components */
  const { Panel } = Collapse;
  //** Destructing Hooks */
  const { t } = useTranslation();
  //** Destructing Constants */
  const { helpDrawerTitle, faqContainer, Delete_Confirm } = helpSection;
  const { faqTitle, faqSubheading } = faqContainer;
  const { Save, Add_New_Question, Cancel } = buttonText;
  /**
   * @useState declaration
   */
  const [currentEditableId, setCurrentEditableId] = useState("");
  const [currentActiveKey, setCurrentActiveKey] = useState("");
  const [listFAQ, setListFAQ] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState({});

  //** Reducer Imports */
  const account = useSelector((state) => state.loginReducer.groups);
  const isAdmin = account.includes("admin");
  const { isLoading } = useSelector((state) => state.loginReducer);

  //** Making new markdown editor */
  const mdParser = new MarkdownIt();

  //** Getting List of FAQ from backend */
  const getFAQ = async () => {
    const queryData = {
      query: listFAQs,
    };
    const myFAQ = await GraphqlOperationExecutor(queryData);
    const temp = myFAQ?.sort(
      (a, b) =>
        new Date(b?.createdAt).getDate() - new Date(a?.createdAt).getDate()
    );
    setListFAQ(temp);
  };

  //** Adding new question to backend */
  const addNewQuestion = async () => {
    const temp = { id: uuidv4(), question: "", answer: "" };
    setListFAQ((pS) => [...pS, temp]);
    const queryData = {
      query: createFAQ,
      body: {
        input: temp,
      },
    };
    const result = await GraphqlOperationExecutor(queryData);
    getFAQ();
    return result;
  };

  //** Deleting question from backend */
  const deleteFAQHandler = async (e, id) => {
    e.stopPropagation();
    setListFAQ((pS) => pS.filter((p) => p.id !== id));
    const queryData = {
      query: deleteFAQ,
      body: {
        input: { id: id },
      },
    };

    const result = await GraphqlOperationExecutor(queryData);
    getFAQ();
    return result;
  };

  //** Updating question */
  const saveFAQHandler = async (e, flag = true) => {
    e?.stopPropagation();
    const updateQuestionData = {
      id: selectedFAQ.id,
      question: selectedFAQ.question,
      answer: selectedFAQ.answer,
    };

    const queryData = {
      query: updateFAQ,
      body: {
        input: updateQuestionData,
      },
    };

    const result = await GraphqlOperationExecutor(queryData);
    if (flag) {
      setSelectedFAQ({});
      setCurrentEditableId("");
      setOpen(false);
    }
    getFAQ();

    return result;
  };

  //** Handles drawer close and open */
  const onClose = () => setOpen(false);

  //** Generating Header Content */
  const genExtra = (header, id, index) => (
    <>
      <div
        key={index}
        className={"home-panel-extra-container"}
        onClick={() => {
          setCurrentActiveKey((pS) => (pS === index ? "" : index));
        }}
      >
        <span
          className={classnames("help-header-text", {
            isEditable: id === currentEditableId,
          })}
          contentEditable={id === currentEditableId}
          onKeyDown={(e) => {
            if (e?.key === "Enter") {
              const temp = listFAQ.filter((f) => f.id === currentEditableId)[0];
              temp["question"] = e.target.innerHTML;
              setSelectedFAQ(temp);
              saveFAQHandler(e);
            }
            e?.stopPropagation();
          }}
          onClick={(e) => {
            if (currentEditableId) e.stopPropagation();
          }}
          onBlur={(e) => {
            const temp = listFAQ.filter((f) => f.id === currentEditableId)[0];
            temp["question"] = e.target.innerHTML;
            setSelectedFAQ(temp);
            const eventType = e?.relatedTarget?.id === "answerEditIcon";
            saveFAQHandler(e, !eventType);

            if (eventType) {
              setCurrentEditableId(id);
              setOpen(true);
            }
          }}
        >
          {header}
        </span>
        {isAdmin ? (
          <>
            <div className="home-icons">
              {id === currentEditableId && (
                <CheckOutlined
                  className="save-btn"
                  onClick={(e) => saveFAQHandler(e)}
                />
              )}
              <EditOutlined
                className="editicon"
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentEditableId(id);
                  setCurrentActiveKey(index);
                }}
              />

              <Popconfirm
                placement="topLeft"
                title={
                  <Typography.Title level={5} className="delete-confirm">
                    {t(Delete_Confirm)}
                  </Typography.Title>
                }
                onConfirm={(e) => deleteFAQHandler(e, id)}
                okText="Yes"
                cancelText="No"
                icon={""}
                okType="primary"
                okButtonProps={{ size: "large" }}
                cancelButtonProps={{
                  size: "large",
                  className: "home-no-btn",
                }}
                onCancel={(e) => e.stopPropagation()}
              >
                <DeleteOutlined
                  className="help-deleteicon"
                  onClick={(e) => e.stopPropagation()}
                />
              </Popconfirm>
            </div>
          </>
        ) : null}
      </div>
    </>
  );

  //** Handles changes of the editor */
  const handleEditorChange = ({ text }) => {
    setSelectedFAQ((pS) => {
      return { ...pS, answer: text };
    });
  };
  /**
   * @useEffect
   * Getting data from backend
   */
  useEffect(() => {
    getFAQ();
  }, []);

  useEffect(() => {
    const temp = listFAQ.filter((f) => f.id === currentEditableId)[0];
    setSelectedFAQ(temp);
    //eslint-disable-next-line
  }, [currentEditableId]);

  return (
    <>
      {isLoading ? (
        <SkeletonHelp />
      ) : (
        <>
          <Drawer
            title={
              <span className="help-drawer-title">{t(helpDrawerTitle)} </span>
            }
            placement="right"
            onClose={onClose}
            visible={open}
            size="large"
            className="help-md-container"
          >
            <MdEditor
              value={selectedFAQ?.answer}
              className="height-600px"
              renderHTML={(text) => mdParser.render(text)}
              onChange={handleEditorChange}
            />
            <div className="drawer-btn-container">
              <div className="d-flex gap-2">
                <Button
                  className="secondary-btn w-50"
                  onClick={() => setOpen(false)}
                >
                  {t(Cancel)}
                </Button>
                <Button
                  onClick={() => saveFAQHandler()}
                  htmlType="submit"
                  type="primary"
                  className="btn-primary w-50"
                  loading={isLoading}
                >
                  {t(Save)}
                </Button>
              </div>
            </div>
          </Drawer>

          <div className="help-search-wrapper">
            <div className="help-search-container background-norepeat">
              <div className="help-title-wrapper">
                <h2 className="help-search-title">{t(faqTitle)}</h2>
                <h4 className="help-search-subtitle">{t(faqSubheading)}</h4>
              </div>
              {/* Dev comment :-
          <div className="help-input-wrapper">
            <Input
              prefix={<SearchOutlined fill="#64748B" />}
              placeholder="Search for help..."
            />
          </div> */}
            </div>

            <Collapse
              bordered={false}
              defaultActiveKey={"0"}
              expandIcon={({ isActive, panelKey }) => {
                return (
                  <span className="pe-3 m-0">
                    <CaretUp
                      className={isActive ? "" : "rotate-90"}
                      onClick={() =>
                        setCurrentActiveKey((pS) =>
                          pS === panelKey ? "" : panelKey
                        )
                      }
                    />
                  </span>
                );
              }}
              className="help-collapse"
              ghost
              activeKey={currentActiveKey}
              accordion
            >
              {listFAQ &&
                listFAQ.map((item, index) => {
                  return (
                    <>
                      <Panel
                        key={index}
                        className="help-panel"
                        extra={genExtra(item?.question, item?.id, index)}
                      >
                        <div className={"collapse-container flex-row "}>
                          <div
                            className={"help-collapse-text custom-html-style"}
                            dangerouslySetInnerHTML={{
                              __html: marked.parse(
                                item?.answer ? item?.answer : ""
                              ),
                            }}
                          ></div>
                          <div>
                            {isAdmin ? (
                              <>
                                <div className="home-icons">
                                  <EditOutlined
                                    className="editicon"
                                    id="answerEditIcon"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setCurrentEditableId(item?.id);
                                      setOpen(true);
                                    }}
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </Panel>
                    </>
                  );
                })}
            </Collapse>

            {account && account.includes("admin") && (
              <>
                <div className="editable-header-container flex-between align-center">
                  <Button
                    className="add-new-question btn-primary"
                    onClick={() => addNewQuestion()}
                  >
                    {t(Add_New_Question)}
                  </Button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Help;
