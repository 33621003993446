import {
  updateAccountPIICustom,
  updateAccountEmailCustom,
} from "graphql/customQueries";
import { GraphqlOperationExecutor } from "utility/commonMethods";

const piiInformationHandler = (id, flag) => {
  const queryData = {
    query: updateAccountPIICustom,
    body: {
      input: {
        id,
        piiInformation: flag,
      },
    },
  };

  return GraphqlOperationExecutor(queryData);
};

const updateSchoolMail = (input) => {
  const queryData = {
    query: updateAccountEmailCustom,
    body: {
      input: input,
    },
  };
  return GraphqlOperationExecutor(queryData);
};

const SchoolDetailsSDK = {
  piiInformationHandler,
  updateSchoolMail,
};

export default SchoolDetailsSDK;
